import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { downloadFile, trimmedSpace, getToday } from 'utils/utils';
import { renderIcon } from 'utils/liveUtils';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import EmptyButton from 'components/EmptyButton';
import ImportIcon from 'remixicon-react/Download2LineIcon';
import AddIcon from 'remixicon-react/AddFillIcon';
import CloseIcon from 'remixicon-react/CloseLineIcon';

const ProfileToolbar = ({ params, onReset, onOpenModal }) => {
  const { lng, platform } = params;
  const { t } = useTranslation(['common', 'glossary']);
  const [show, setShow] = useState(false);
  const { live_profiles, vod_profiles } = useSelector(({ user }) => user);

  let count = 0;
  if (platform === 'live') {
    live_profiles?.filter(({ checked }) => checked && count++);
  }
  if (platform === 'vod') {
    Object.keys(vod_profiles).map(type => {
      const checked = vod_profiles[type]?.filter(({ checked }) => checked);
      return (count += checked?.length);
    });
  }

  const emptyButtonStyles =
    'bg-transparent border-0 btn-action ms-4 transition d-inline-flex gap-2';

  const getCheckedProfiles = profiles =>
    profiles
      ?.filter(({ checked }) => checked)
      ?.map(({ checked, uuid, ...profile }) => ({ ...profile }));

  const handleExportAll = () => {
    let data, fileName;
    if (platform === 'live') {
      data = { live_profiles: getCheckedProfiles(live_profiles) };
      fileName =
        count === 1
          ? trimmedSpace(data['live_profiles'][0].name)
          : 'LiveProfiles';
    }
    if (platform === 'vod') {
      const checkedProfiles = {};
      Object.keys(vod_profiles).map(
        key => (checkedProfiles[key] = getCheckedProfiles(vod_profiles[key]))
      );
      data = { vod_profiles: checkedProfiles };
      fileName = count > 1 ? 'OnDemandProfiles' : 'OnDemandProfile';
    }
    return downloadFile({
      data: JSON.stringify(data),
      fileName: getToday() + '_' + fileName + '.json',
      fileType: 'text/json',
      callback: () => setTimeout(() => onReset(), 3000)
    });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <span
      className={`table-title d-flex justify-content-between align-items-center mb-3 bg-transition ${
        count ? 'px-4 bg-success rounded' : 'bg-transparent'
      }`}
    >
      <CreateVodModal params={params} show={show} onClose={handleClose} />
      {count ? (
        <>
          <span>
            <CloseIcon
              className='me-4 btn-action pe-pointer transition'
              onClick={onReset}
            />
            <h3 className='h5 m-0 d-inline'>
              <strong className='me-3'>{count}</strong>
              {t(count > 1 ? 'profile_selected_plural' : 'profile_selected')}
            </h3>
          </span>
          <span>
            <Button
              variant='light'
              className={emptyButtonStyles}
              onClick={handleExportAll}
            >
              {renderIcon('export')} {t('export')}
            </Button>
            <Button
              variant='light'
              className={emptyButtonStyles}
              onClick={() => onOpenModal('delete')}
            >
              {renderIcon('delete')} {t('delete')}
            </Button>
          </span>
        </>
      ) : (
        <>
          <h1 className='h3 color-high m-0'>
            {`${t('glossary:' + platform)} ${t('profiles')}`}
          </h1>
          <span>
            <Button
              variant='outline-primary'
              className='ms-4'
              onClick={() => onOpenModal('import')}
            >
              <ImportIcon className='me-2' /> {t('import')}
            </Button>
            {platform === 'live' ? (
              <Link to={`/${lng}/settings/${platform}/new`} className='ms-4'>
                <Button variant='primary' className='shadow-none'>
                  <AddIcon className='me-2' />
                  {t('create')}
                </Button>
              </Link>
            ) : (
              <Button
                variant='primary'
                className='shadow-none ms-4'
                onClick={handleShow}
              >
                <AddIcon className='me-2' />
                {t('create')}
              </Button>
            )}
          </span>
        </>
      )}
    </span>
  );
};

export default ProfileToolbar;

const CreateVodModal = ({ params, show, onClose }) => {
  const { t } = useTranslation(['common', 'glossary']);
  const { theme = 'light' } = useSelector(({ user }) => user.settings);
  const { lng, platform } = params;

  const vodProfileOptions = [
    {
      type: 'source',
      text: 'Input storage details (Account credentials, etc.)'
    },
    {
      type: 'encoder',
      text: 'Customer information (Customer ID, entrypoint, API auth key, etc.)'
    },
    {
      type: 'destination',
      text: 'Output storage details (Account credentials, etc.)'
    }
  ];
  return (
    <Modal
      className={theme}
      show={show}
      onHide={onClose}
      size='lg'
      aria-labelledby='vod-modal'
      centered
    >
      <Modal.Header className='p-4'>
        <Modal.Title id='vod-modal-title'>
          <h1 className='h4 fs-3 color-high'>{t('add_vod_profile')}</h1>
          <p className='h5 color-medium fw-normal'>
            {t('add_vod_profile_text')}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-4'>
        {vodProfileOptions.map(({ type, text }) => (
          <Link
            key={type}
            className='text-decoration-none'
            to={`/${lng}/settings/${platform}/${type}/new`}
          >
            <ListGroup className='my-2 dp-01'>
              <ListGroup.Item
                variant={theme === 'dark' && theme}
                className='p-4'
                action
              >
                <div className='color-disabled'>
                  <div className='h4 color-high'>{t(`glossary:${type}`)}</div>
                  {text}
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Link>
        ))}
      </Modal.Body>
      <Modal.Footer className='p-4'>
        <EmptyButton onClick={onClose}>{t('cancel')}</EmptyButton>
      </Modal.Footer>
    </Modal>
  );
};
