import { useRef, useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FieldArray, useFormikContext } from 'formik';

import { capitalizeString, flattenObject, checkIsTM } from 'utils/utils';
import { setFisheyeOptions } from 'utils/liveUtils';
import {
  getIngestKey,
  getIngestFolder,
  setSrcURLPrepend,
  renderNotificationTargetLable
} from 'utils/vodUtils';
import { FisheyeProjectionTypes } from 'constants/live';
import {
  PROJECTION_TYPES,
  PACKAGING_FORMATS,
  PACKAGING_FORMAT_DEFAULT
} from 'constants/vod';
import {
  VodStartFormConfigs as FormConfigs,
  VodTemplateFormConfigs as TemplateFormConfigs,
  VodInputResolution as InputResolutionConfigs
} from 'constants/vodForm';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { LocationSelect, ABRFields } from 'components/VodFields';
import MultiVideoTable from 'components/Table';
import Subtitle from 'components/VodSubtitle';
import ProjectionType from 'components/ProjectionType';
import CodeSection from 'components/CodeSection';
import CloseButton from 'react-bootstrap/CloseButton';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'components/ToggleButton';
import EmptyButton from 'components/EmptyButton';
import TextField from 'components/TextField';
import DeleteIcon from 'remixicon-react/CloseLineIcon';
import ErrorIcon from 'remixicon-react/ErrorWarningFillIcon';

const TemplateConfigModal = ({ name, show, onHide }) => {
  const {
    blueprints,
    settings: { theme }
  } = useSelector(({ user }) => user);

  const data = blueprints?.find(({ blueprint_name }) => blueprint_name === name);
  return (
    <Modal
      show={show}
      onHide={onHide}
      className={theme}
      contentClassName='modal-background dp-02'
      size='xl'
      centered
      scrollable
    >
      <Modal.Header className='position-relative'>
        <CloseButton
          className='position-absolute top-0 end-0 m-3'
          variant={theme === 'dark' ? 'white' : null}
          onClick={onHide}
        />
        <Modal.Title className='m-2'>
          <h2 className='h3 color-high'>Template configuration</h2>
          <h3 className='h5 color-primary m-0'>{name}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='py-0'>
        <CodeSection data={data} />
      </Modal.Body>
    </Modal>
  );
};

const VideoField = ({ path, index, className, ...rest }) => (
  <TextField name={`source.multi_video.${index}.${path}`} size='sm' {...rest} />
);

// start, restart: initialOption
export const FormSection = ({
  type,
  timestamp,
  onSelect,
  initialOption,
  onErrorCount
}) => {
  const {
    groups,
    blueprints,
    settings: { theme },
    vod_profiles: { sources = [] }
  } = useSelector(({ user }) => user);
  const { t } = useTranslation(['common', 'validation']);
  const sourceRef = useRef(null);
  const nameRef = useRef(null);

  const [isSourceFocus, setIsSourceFocus] = useState(false);
  const [isNameFocus, setIsNameFocus] = useState(false);
  const { values, touched, errors, setFieldValue } = useFormikContext();

  const encoding_v3 = values?.encoding_v3?.[0];
  const width = encoding_v3?.video?.bundles?.[0]?.scale_factor;
  const [showAdvanced, setShowAdvanced] = useState(Boolean(width));
  const [showModal, setShowModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showCalculateModal, setShowCalculateModal] = useState(false);

  const { initialABR, fields, typeErrors } = FormConfigs;
  const isTemplate = Boolean(values?.template_hls);
  const showContentID = ['by-name', 'customer-default'];

  const locationValidateProps = {
    validateOnMount: true,
    validation: true,
    disabled: true
  };

  const locationProps = {
    className: 'col-12 col-md-5 col-xl-3 col-xxl-2',
    label: t('location'),
    ...locationValidateProps
  };

  const setTargetProps = type => ({
    className: 'col col-xl-6 col-xxl-3',
    label: capitalizeString(getIngestFolder(type).replace('_', ' ')),
    ...locationValidateProps
  });

  const renderForm = type => {
    switch (type) {
      case 'template':
        const { blueprint_name } = { ...values };
        const handleHide = () => setShowTemplateModal(false);
        const handleShow = () => setShowTemplateModal(true);
        return (
          <>
            <TemplateConfigModal
              name={blueprint_name}
              show={showTemplateModal}
              onHide={handleHide}
            />
            <div className='row align-items-end mb-2'>
              <div className='col-12 col-lg-5'>
                <label className='form-label color-medium'>
                  {t('validation:temp_name')}
                </label>
                <select className='form-select border-line' onChange={onSelect}>
                  {blueprints.map(({ blueprint_name }) => (
                    <option key={blueprint_name} value={blueprint_name}>
                      {blueprint_name}
                    </option>
                  ))}
                </select>
              </div>
              <EmptyButton
                className='col-auto shadow-none'
                style={{ height: 38 }}
                onClick={handleShow}
              >
                {t('check_config')}
              </EmptyButton>
            </div>
          </>
        );
      case 'source':
        const ProjectionModalProps = {
          show: showModal,
          onClick: bool => setShowModal(bool)
        };

        if (isTemplate) {
          const { source, template_hls } = { ...values };
          const { ingest, multi_video } = { ...source };
          const { type } = { ...ingest };

          const videoCount = multi_video?.length;
          const videoDefault = TemplateFormConfigs.initialValues.source.multi_video[0];

          const requireVideoName = template_hls?.find(
            ({ video_template_source_mapping: video }) =>
              video?.mapping_type?.includes('by-name')
          );
          const isRequireVideoName = Boolean(requireVideoName);

          const getVideoColumns = (multi_video, remove) => {
            const columns = [
              {
                path: 'name',
                label: t('validation:name'),
                thClasses: 'col-1 text-sm',
                input: index => (
                  <VideoField
                    index={index}
                    path='name'
                    placeholder={`Video ${index + 1}`}
                    validation={isRequireVideoName}
                  />
                )
              },
              {
                path: 'src_file',
                label: type === 'aspera-faspex' ? 'Title' : t('validation:Source URL'),
                thClasses: 'col-6 text-sm ',
                input: index => {
                  const path = multi_video[index].src_file;
                  const prepend = setSrcURLPrepend(ingest);
                  if (path && prepend && ingest && path.startsWith(prepend)) {
                    multi_video[index].src_file = path.split(prepend)[1];
                  }
                  return (
                    <VideoField
                      index={index}
                      path='src_file'
                      prepend={prepend}
                      placeholder={type === 'http-pull' ? 'http(s)://' : ''}
                      autoFocus={index === 0}
                      validation
                    />
                  );
                }
              },
              {
                path: 'projection_type',
                label: t('validation:projection_type'),
                thClasses: 'col text-sm',
                input: index => (
                  <VideoField
                    index={index}
                    path='projection_type'
                    options={PROJECTION_TYPES}
                    validation
                  />
                )
              }
            ];

            const hasFisheye = multi_video?.find(({ projection_type }) =>
              FisheyeProjectionTypes.includes(projection_type)
            );

            if (hasFisheye) {
              columns.push({
                path: 'fish_eye_params.camera_lens_enum',
                label: t('validation:Fisheye Camera-lens'),
                thClasses: 'col text-sm',
                input: (index, { projection_type }) => {
                  if (!FisheyeProjectionTypes.includes(projection_type)) return null;
                  return (
                    <VideoField
                      index={index}
                      path='fish_eye_params.camera_lens_enum'
                      options={setFisheyeOptions(groups)}
                      validation
                    />
                  );
                }
              });
            }

            columns.push({
              key: 'btn-sm',
              input: index => (
                <EmptyButton
                  className='d-flex mx-auto px-0 shadow-none'
                  onClick={() => remove(index)}
                >
                  <DeleteIcon />
                </EmptyButton>
              )
            });

            return columns;
          };

          return (
            <FieldArray name='source.multi_video'>
              {({ remove, push }) => (
                <ProjectionType.Wrapper className='my-2' {...ProjectionModalProps}>
                  <Subtitle
                    text={t('glossary:source')}
                    showTooltip={!videoCount}
                    onClick={() => push(videoDefault)}
                    disabledButton={videoCount > 32}
                  />
                  <MultiVideoTable
                    className='video-table underline'
                    data={multi_video}
                    columns={getVideoColumns(multi_video, remove)}
                    hover={false}
                    size='sm'
                    noDataMsg={
                      <>
                        <ErrorIcon className='text-danger me-2' />
                        Video source is required
                      </>
                    }
                  />
                </ProjectionType.Wrapper>
              )}
            </FieldArray>
          );
        }

        const source_uuid = values?.source?.ingest?.uuid;
        const storage = sources.find(({ uuid }) => uuid === source_uuid);
        const path = values?.source?.video?.src_file;
        const prepend = setSrcURLPrepend(storage);
        const errorMsg = 'Does not match the selected Location';

        fields.source.label = storage?.type === 'aspera-faspex' ? 'Title' : 'Source URL';
        fields.source.prepend = prepend;
        fields.source.placeholder = source_uuid === 'http-pull' ? 'http(s)://' : '';

        if (path) {
          if (source_uuid === 'http-pull') {
            fields.source.error =
              path.startsWith('https://') || path.startsWith('http://') ? null : errorMsg;
          } else {
            if (prepend && storage) {
              if (path.startsWith(prepend)) {
                values.source.video.src_file = path?.split(prepend)[1];
                fields.source.error = null;
              } else {
                if (path.includes('://')) fields.source.error = errorMsg;
                else fields.source.error = null;
              }
            } else fields.source.error = null;
          }
        }

        const showFisheyeOption =
          FisheyeProjectionTypes.includes(values?.source?.video?.projection_type) ||
          values?.source?.video?.fish_eye_params?.camera_lens_enum;

        if (values?.encoding_v3) {
          fields.projection.options = fields.projection.options.filter(
            option => option !== 'rectilinear'
          );
        }
        return (
          <ProjectionType.Wrapper className='mb-3' {...ProjectionModalProps}>
            <div className='row'>
              <LocationSelect type={type} initialOption={initialOption?.[type]} />
            </div>
            <TextField focusref={sourceRef} {...fields.source} />
            <div className='row'>
              <TextField {...fields.projection} />
              {showFisheyeOption && (
                <TextField
                  className='col'
                  name='source.video.fish_eye_params.camera_lens_enum'
                  options={setFisheyeOptions(groups)}
                  label='Fisheye Camera-lens'
                  validation
                />
              )}
            </div>
          </ProjectionType.Wrapper>
        );
      case 'encoder':
        const showInputRes = checkIsTM(groups);

        const advancedClasses = initialOption ? 'col-md-4' : 'col-md-4 col-xl-3';
        const projectionProps = {
          className: `col-6 ${advancedClasses}`,
          type: 'number',
          unit: 'degree',
          validation: true
        };

        const bundlesValue = encoding_v3?.video?.bundles;
        const bundleName = 'encoding_v3[0].video.bundles';

        const resetAdvanced = () => {
          setFieldValue('encoding_v3[0].packaging_format', PACKAGING_FORMAT_DEFAULT);
          setFieldValue('drm.type', 'none');
          setFieldValue('drm.override_content_id', '');
          setFieldValue('drm.name', '');
          setFieldValue(`${bundleName}[0].down_conversion_factor`, 0);
          setFieldValue(`${bundleName}[0].target_fov_in_degrees.v_fov_in_deg`, 0);
          setFieldValue(`${bundleName}[0].target_fov_in_degrees.h_fov_in_deg`, 0);
          setFieldValue(`${bundleName}[0].scale_factor`, 0);
          setFieldValue('advanced.accept_input_failures', false);
          setFieldValue('encoding_v3[0].auto_add_stereo_downmix', false);
        };

        const hideCalculateModal = () => setShowCalculateModal(false);

        const handleSubmit = values => {
          const { width, target_width } = { ...values };
          const value = (target_width / width) * 100;
          setFieldValue(`${bundleName}[0].scale_factor`, value);
          hideCalculateModal();
        };
        return (
          <>
            <div className='row'>
              <TextField {...fields.sharpness} className={`col-6 ${advancedClasses}`} />
            </div>
            <FieldArray name={bundleName}>
              {({ remove, push }) => (
                <>
                  <Subtitle
                    text={t(`validation:ABR_level`)}
                    size='sm'
                    className='mt-3'
                    onClick={() => push(initialABR)}
                    showTooltip={!bundlesValue?.length}
                  />
                  <ABRFields
                    name={bundleName}
                    bundles={bundlesValue}
                    onHide={index => remove(index)}
                  />
                </>
              )}
            </FieldArray>
            <Accordion defaultActiveKey={showAdvanced ? 'advanced-settings' : null}>
              <div
                className={`d-flex align-item-center fade-in ${
                  showAdvanced ? 'underline' : ''
                }`}
              >
                <ToggleButton
                  eventkey='advanced-settings'
                  onClick={() => setShowAdvanced(prev => !prev)}
                >
                  <h5 className='h6 title-height color-high m-0'>
                    {t('validation:advanced_settings')}
                  </h5>
                </ToggleButton>
                {showAdvanced && (
                  <EmptyButton
                    className='ms-auto shadow-none fade-in'
                    onClick={resetAdvanced}
                    reversedStyles
                  >
                    {t('validation:reset_advanced')}
                  </EmptyButton>
                )}
              </div>
              <Accordion.Collapse eventKey='advanced-settings'>
                <section className='d-grid gap-3 py-3'>
                  <div className='row'>
                    <TextField
                      className={`col-12 col-md-8 ${initialOption ? '' : 'col-xl-6'}`}
                      name='encoding_v3[0].packaging_format'
                      label='SDK version compatibility'
                      options={PACKAGING_FORMATS}
                      helpContent={
                        <>
                          <span className='color-high me-2'>⚠️</span>
                          <span className='color-medium'>
                            Content encoded for a certain SDK version may NOT play on a
                            lower version
                          </span>
                        </>
                      }
                    />
                    <div className='col color-primary align-self-center'>
                      <Link
                        to={{ pathname: 'https://www.tiledmedia.com/account/releases' }}
                        target='_blank'
                      >
                        Tiledmedia SDK releases page
                      </Link>
                    </div>
                  </div>
                  <div className='row'>
                    <TextField
                      className={`col-12 ${advancedClasses}`}
                      name='drm.type'
                      label='DRM_type'
                      options={['none', 'customer-default', 'by-name']}
                    />
                    {showContentID.includes(values.drm?.type) && (
                      <TextField
                        className={`col-12 ${advancedClasses}`}
                        name='drm.override_content_id'
                        label='Content ID'
                      />
                    )}
                    {values.drm?.type === 'by-name' && (
                      <TextField
                        className={`col-12 ${advancedClasses}`}
                        name='drm.name'
                        label='DRM name'
                      />
                    )}
                  </div>
                  <div className='row'>
                    <TextField
                      name={`${bundleName}[0].down_conversion_factor`}
                      label='Framerate down conversion'
                      helpMessage='From 0 to 10'
                      className={`col-12 ${advancedClasses}`}
                      type='number'
                      validation
                    />
                    {showInputRes && (
                      <>
                        <TextField
                          className={`col ${advancedClasses}`}
                          name={`${bundleName}[0].scale_factor`}
                          label='Downscale input resolution'
                          helpMessage='From 0 (no scale) to 100'
                          type='number'
                          unit='%'
                          validation
                        />
                        <div
                          className='col color-primary link pe-pointer align-self-center'
                          onClick={() => setShowCalculateModal(true)}
                        >
                          Calculate downscale value
                        </div>
                        <Modal
                          show={showCalculateModal}
                          onHide={hideCalculateModal}
                          className={theme}
                          contentClassName='modal-background dp-02'
                          size='lg'
                          centered
                          scrollable
                        >
                          <Modal.Header className='position-relative'>
                            <Modal.Title className='m-2'>
                              <h2 className='h3 color-high'>Calculate downscale value</h2>
                              <h3 className='h5 color-primary m-0'>Input resolution</h3>
                            </Modal.Title>
                          </Modal.Header>
                          <Formik
                            {...InputResolutionConfigs}
                            onSubmit={handleSubmit}
                            enableReinitialize
                          >
                            {({ values }) => {
                              const { width, target_width } = values;
                              const scalePercentage = width
                                ? (target_width / width) * 100
                                : 0;
                              const resolutionProps = {
                                className: 'col-12 col-md',
                                type: 'number',
                                unit: 'pixels',
                                validation: true
                              };
                              return (
                                <Form className='p-2'>
                                  <Modal.Body className='row'>
                                    <TextField
                                      name='width'
                                      label='Input width'
                                      helpMessage='From 1024 to 16384'
                                      {...resolutionProps}
                                    />
                                    <TextField
                                      name='target_width'
                                      label='Target width'
                                      helpMessage='Smaller than input width'
                                      {...resolutionProps}
                                    />
                                    <div className='input-height col'>
                                      <label className='form-label color-medium'>
                                        Downscale input resolution
                                      </label>
                                      <div className='input-group'>
                                        <div className='form-control border-line'>
                                          {scalePercentage}
                                        </div>
                                        <span className='input-group-text unit-input'>
                                          %
                                        </span>
                                      </div>
                                      <div className='form-text w-100 d-block color-disabled'>
                                        From 0 to 100
                                      </div>
                                    </div>
                                  </Modal.Body>
                                  <Modal.Footer className='gap-3'>
                                    <EmptyButton onClick={hideCalculateModal}>
                                      {t('cancel')}
                                    </EmptyButton>
                                    <Button type='submit' variant='primary'>
                                      Apply
                                    </Button>
                                  </Modal.Footer>
                                </Form>
                              );
                            }}
                          </Formik>
                        </Modal>
                      </>
                    )}
                    <h6 className='color-high my-3'>FoV override</h6>
                    <TextField
                      name={`${bundleName}[0].target_fov_in_degrees.h_fov_in_deg`}
                      label='Horizontal'
                      helpMessage='From 0 to 180 or 360'
                      {...projectionProps}
                    />
                    <TextField
                      name={`${bundleName}[0].target_fov_in_degrees.v_fov_in_deg`}
                      label='Vertical'
                      helpMessage='From 0 to 180'
                      {...projectionProps}
                    />
                  </div>
                  {/* <TextField
                    type='checkbox'
                    name
                    label='Force monoscopic projection'
                  /> */}
                  <TextField
                    type='checkbox'
                    name='advanced.accept_input_failures'
                    label='Accept errors in source video (Not recommended)'
                  />
                  <TextField
                    type='checkbox'
                    name='encoding_v3[0].auto_add_stereo_downmix'
                    label='Add stereo downmix if not present'
                  />
                  {/* <TextField
                      type='checkbox'
                      name
                      label='Encode all audio channels'
                    /> */}
                </section>
              </Accordion.Collapse>
            </Accordion>
          </>
        );
      case 'destination':
        if (isTemplate) {
          const getEgressName = (type, index) => {
            return `egress.${index}.${getIngestKey(type)}.${getIngestFolder(type)}`;
          };
          return (
            <>
              <FieldArray name='egress'>
                <div className='row'>
                  {values?.egress?.map(({ type }, index) => (
                    <Fragment key={'egress' + index}>
                      <TextField name={`egress.${index}.type`} {...locationProps} />
                      {type !== 'akamai-multi-ns' && (
                        <TextField
                          name={getEgressName(type, index)}
                          {...setTargetProps(type)}
                        />
                      )}
                    </Fragment>
                  ))}
                </div>
              </FieldArray>
              <div className='row'>
                <TextField
                  className='col-12 col-xxl-5'
                  name='name'
                  label='encode_name'
                  validation
                />
                <TextField
                  className='col-12 col-xxl-7'
                  name='event_name'
                  label='publication_folder'
                  validateOnMount={false}
                  validation
                  copy
                />
                <TextField className='col-12' name='comment' label='comment' />
                <NotificationFields />
              </div>
            </>
          );
        }
        const { output } = { ...values };
        const showURL =
          output?.egress?.find(({ type }) => type === 'http-put') ||
          output?.type === 'http-put' ||
          output?.uuid === 'http-put';
        return (
          <>
            <div className='row'>
              <LocationSelect type={type} initialOption={initialOption?.[type]} />
            </div>
            <div className='row'>
              <TextField
                focusref={nameRef}
                className='col-12'
                name='name'
                label='encode_name'
                validation
              />
            </div>
            {values?.output?.uuid !== 'null' && (
              <TextField
                className='col'
                name='event_name'
                label='publication_folder'
                copy
              />
            )}
            {showURL && (
              <div className='row'>
                <TextField
                  className='col-12'
                  name='output.url'
                  label='URL'
                  placeholder='https://'
                  validation
                />
              </div>
            )}
            <div className='row'>
              <TextField className='col-12' name='comment' label='comment' />
              <NotificationFields />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (type !== 'destination') return;
    values.event_name = `${timestamp}_${values?.name}`;
  }, [values?.name]); // eslint-disable-line

  useEffect(() => {
    if (isSourceFocus) sourceRef.current?.focus();
  }, [isSourceFocus]);

  useEffect(() => {
    if (values?.source?.ingest?.uuid) setIsSourceFocus(true);
    const cancelFocus = setTimeout(() => setIsSourceFocus(false), 1000);
    return () => clearTimeout(cancelFocus);
  }, [values?.source?.ingest?.uuid]);

  useEffect(() => {
    if (isNameFocus) nameRef.current?.focus();
  }, [isNameFocus]);

  useEffect(() => {
    if (values?.output?.uuid) setIsNameFocus(true);
    const cancelFocus = setTimeout(() => setIsNameFocus(false), 1000);
    return () => clearTimeout(cancelFocus);
  }, [values?.output?.uuid]);

  useEffect(() => {
    if (!onErrorCount) return;
    const errorKeyMap = {};
    const errorKeys = Object.keys(flattenObject(errors));
    let count = errorKeys.length;
    ['source', 'destination', 'encoder'].map(type => {
      const errorCount = errorKeys.filter(errorKey =>
        typeErrors[type]?.includes(errorKey)
      )?.length;
      count = count - errorCount;
      return (errorKeyMap[type] = typeErrors[type] ? errorCount : count);
    });
    if (fields.source.error) {
      errorKeyMap['source'] = errorKeyMap['source'] + 1; // add 'src_file' validation error
    }
    onErrorCount(errorKeyMap);
  }, [errors, touched]); // eslint-disable-line

  return (
    <Container title={isTemplate && type === 'source' ? null : type}>
      {renderForm(type)}
    </Container>
  );
};

const NotificationFields = () => {
  const { t } = useTranslation('validation');

  const { values } = useFormikContext();
  const { notification } = { ...values };
  const { type } = { ...notification };
  return (
    <>
      <TextField
        className='col-3'
        name='notification.type'
        label={t('notification')}
        options={['none', 'mail', 'webhook']}
      />
      {type !== 'none' && (
        <TextField
          className='col'
          name='notification.target'
          label={t(renderNotificationTargetLable(type))}
          validation
        />
      )}
    </>
  );
};

export const Container = ({ title, children }) => {
  const { t } = useTranslation('glossary');
  return (
    <Card id={title} className='modal-background px-4 py-3 rounded dp-01'>
      {title && <h2 className='h5 color-high py-2 mb-4 underline'>{t(title)}</h2>}
      {children}
    </Card>
  );
};
