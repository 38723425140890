import { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'config/i18next';

import { navigationItems } from 'constants/navigations';
import { setPrimary } from 'utils/utils';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import MenuIcon from 'remixicon-react/MenuLineIcon';

const SideNavbar = () => {
  const { t } = useTranslation(['common', 'glossary']);
  const { pathname, state } = useLocation();
  const [foldSidebar, setFoldSidebar] = useState(window.innerWidth < 1600);

  const {
    isAuthenticated,
    settings: { theme = 'light' }
  } = useSelector(({ user }) => user);
  const isLoggedin = isAuthenticated && !pathname?.includes('start');

  const imageUrl = `${process.env.REACT_APP_COCKPIT_CDN}/pics`;
  const url_png = `${imageUrl}/loginImage.png`;
  const url_webp = `${imageUrl}/loginImage.webp`;
  const centerAlign = 'd-flex justify-content-center align-items-center';

  const handleClick = () => {
    setFoldSidebar(preState => !preState);
  };

  if (state === 'no_snapshot') return null;
  if (isLoggedin) {
    return (
      <Nav
        as='nav'
        className={`col-auto transition d-flex flex-column color-medium p-2 dp-01 ${
          foldSidebar ? 'sidebar-fold' : 'sidebar-unfold'
        }`}
      >
        <Button
          className='nav-icon-size bg-transparent border-0 shadow-none'
          onClick={handleClick}
          aria-label='toggle menu'
        >
          <MenuIcon className='fill-medium' />
        </Button>
        <ul className='w-100 mt-4 list-unstyled'>
          {navigationItems.map(({ id, icon, label, activeIcon, url }) => (
            <Nav.Item key={id} as='li' className='w-100 mb-3'>
              <Nav.Link
                as={NavLink}
                to={`/${i18n.language}${url}`}
                className={`d-flex align-items-center w-100 h-100 color-medium rounded p-0`}
                activeClassName={`nav-active text-${setPrimary(theme)}`}
                aria-label={label}
              >
                <span className={`nav-icon-size ${centerAlign}`}>
                  {pathname.split('/')[2] === url.split('/')[1] ? activeIcon : icon}
                </span>
                {!foldSidebar && (
                  <p className='fade-in h6 align-middle m-0 ms-1 me-5'>
                    {t('glossary:' + label)}
                  </p>
                )}
              </Nav.Link>
            </Nav.Item>
          ))}
        </ul>
        {!foldSidebar && (
          <small className='text-xs fade-in mt-auto ps-2'>
            &copy; {new Date().getFullYear()}
            <a
              className='text-decoration-none mx-1'
              href='https://www.tiledmedia.com/'
              target='_blank'
              rel='noreferrer'
            >
              Tiledmedia.com
            </a>
          </small>
        )}
      </Nav>
    );
  } else {
    return (
      <section
        className={`d-none d-md-flex col-md-4 flex-column ${centerAlign} position-relative`}
      >
        <div className='text-white w-75 z-1'>
          <h5 className='text-uppercase'>{t('welcome_to')}</h5>
          <h1 className='display-5 fw-bold'>Cockpit</h1>
        </div>
        <picture>
          <source srcSet={url_webp} type='image/webp' />
          <source srcSet={url_png} type='image/png' />
          <img
            className='main-image lazy-load w-100 h-100 position-absolute top-0 start-0'
            src={url_png}
            alt='Tiledmedia'
          />
        </picture>
      </section>
    );
  }
};

export default SideNavbar;
