import { useState, useEffect } from 'react';
import { getJwt } from 'services/authService';

function useToken() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      const jwt = await getJwt();
      setToken(jwt);
    };

    try {
      fetchToken();
    } catch (error) {
      console.error(error);
    }
    return () => setToken(null);
  }, []);

  return token;
}

export default useToken;
