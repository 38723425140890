import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { nanoid } from '@reduxjs/toolkit';
import { HISTORY_STATES } from 'constants/vod';
import { FisheyeProjectionTypes } from 'constants/live';
import { removeCustomBitrate, parseRateControl } from 'utils/utils';
import Badge from 'components/Badge';
import CopyButton from 'components/CopyButton';

dayjs.extend(duration);

const format = 'DD-MM-YYYY, HH:mm:ss';
const format_duration = 'D[d] H[h] m[m] s[s]';

export const isZero = time => time === '0001-01-01T00:00:00Z';

export const getDurationUntilNow = started =>
  dayjs.duration(dayjs().diff(dayjs(started))).format('HH:mm:ss');

export const getLocationKey = type => {
  if (!type) return;
  switch (type) {
    case 's3bucket':
    case 'kingsoft':
      return 's3';
    case 'aspera-faspex':
      return 'aspera';
    default:
      return type.replaceAll(/-/g, '_');
  }
};

export function humanize(string) {
  let i,
    frags = string.split('-');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

export const getLocationTypeOption = type => {
  if (!type || type === 'null') return;
  switch (type) {
    case 'akamai-multi-ns':
      return 'Akamai Multi-NetStorage';
    case 'akamai-ns':
      return 'Akamai NetStorage';
    case 's3':
    case 's3bucket':
      return 'S3 Bucket';
    case 'aws-mediastore':
      return 'AWS Mediastore';
    case 'gcp-storage':
      return 'GCP Storage';
    case 'http-pull':
      return 'HTTP pull';
    case 'http-put':
      return 'HTTP put';
    default:
      return humanize(type);
  }
};

export const setSrcURLPrepend = ingest => {
  const targetValue = getBucketValue(ingest);
  if (!ingest || !targetValue) return;

  switch (ingest?.type) {
    case 'gcp-storage':
      return `gs://${targetValue}/`;
    case 's3bucket':
      return `s3://${targetValue}/`;
    case 'azure-blob':
      return `${targetValue}/`;
    default:
      return '';
  }
};

export const getIngestKey = type => {
  if (!type || type === 'null') return 's3';
  switch (type) {
    case 'aspera-faspex':
      return 'aspera';
    case 's3':
    case 's3bucket':
      return 's3';
    default:
      return type.toString().replaceAll('-', '_');
  }
};

export const getIngestFolder = type => {
  if (!type) return 'bucket';
  const key = getIngestKey(type);
  switch (key) {
    case 's3':
    case 'gcp_storage':
    default:
      return 'bucket';
    case 'azure_blob':
      return 'container';
    case 'akamai_ns':
    case 'aspera':
    case 'wangsu':
      return 'host';
    case 'aws_mediastore':
      return 'container_endpoint';
    case 'http_put':
      return 'URL';
    case 'akamai_multi_ns':
      return null;
    case 'http_pull':
      return null;
  }
};

export const getBucketValue = ingest => {
  if (!ingest || !ingest?.type) return '';
  if (ingest.type === 'http-put') return ingest?.url;

  const key = getIngestKey(ingest.type);
  const folder = getIngestFolder(ingest.type);
  return ingest?.[key]?.[folder];
};

const INITIAL_VALUES_NOTIFICATION = { type: 'none', target: '' };

export const parseNotification = notification => {
  const { type } = { ...notification };
  if (type && type !== 'none') return notification;
  return INITIAL_VALUES_NOTIFICATION;
};

// RESTART encode with template
const INITIAL_VALUES_TEMPLATE = {
  name: '',
  event_name: '',
  comment: '',
  source: {
    multi_video: [
      {
        name: '',
        src_file: '',
        projection_type: 'rectilinear',
        fish_eye_params: { camera_lens_enum: '' }
      }
    ]
  }
};

export const populateTemplateValues = ({ encode_config, profile }) => {
  if (!encode_config) return;

  const { encoding_hls, ...rest } = { ...encode_config };
  const values = { ...INITIAL_VALUES_TEMPLATE, ...rest };

  const multi_video = values?.source?.multi_video?.length
    ? values.source.multi_video.map(video => ({
        ...INITIAL_VALUES_TEMPLATE.source.multi_video[0],
        ...video,
        src_file: video.src_file?.trim() || ''
      }))
    : INITIAL_VALUES_TEMPLATE.source.multi_video;

  values.source = {
    ingest: values?.source?.ingest,
    multi_video
  };

  // TODO: profile?.drm
  const notification = profile ? profile?.notification : values?.notification;
  values['notification'] = parseNotification(notification);

  return values;
};

// START encode with template
export const populateTemplateValuesWithBlueprintName = ({ blueprint, profile }) => {
  if (!blueprint) return;

  const { blueprint_name, encode_config } = { ...blueprint };
  const values = { ...populateTemplateValues({ encode_config, profile }) };
  return { ...values, blueprint_name };
};

export const retrieveblueprintNameFromComment = comment => {
  const TemplateTag = '[template]';
  if (!comment?.includes(TemplateTag)) return;
  return comment?.split(TemplateTag)[1].trim();
};

export const removeNoValueFromPayload = payload => {
  const { drm, notification, comment, egress } = { ...payload };
  if (drm?.type === 'none') delete payload.drm;
  if (notification?.type === 'none') delete payload.notification;
  if (!comment) delete payload.comment;
  if (egress?.[0]?.type === 'null') delete payload?.event_name;
};

export const renderTemplateLocation = (data, type) => {
  const bucket = getBucketValue(data);
  return (
    <>
      {bucket}
      <Badge.Fill type={type} className={bucket ? 'ms-2' : ''}>
        {getLocationTypeOption(data?.type)}
      </Badge.Fill>
    </>
  );
};

export const renderLocation = (data, type) => {
  const location_types = data?.egress || [{ type: data.type }];
  return (
    <>
      {data?.name}
      {data?.uuid !== 'null' &&
        location_types.map(({ type: location_type }, index) => (
          <Badge.Fill key={index} type={type} className={data?.name ? 'ms-2' : ''}>
            {getLocationTypeOption(location_type)}
          </Badge.Fill>
        ))}
    </>
  );
};

export const renderNotificationTargetLable = type => {
  switch (type) {
    case 'mail':
      return 'email';
    case 'webhook':
      return 'Target URL';
    default:
      return 'Target';
  }
};

export const mapToSourceTemplate = ({ ingest, multi_video }) => ({
  location: renderTemplateLocation(ingest, 'source'),
  Source: multi_video?.map(({ name, src_file, projection_type, fish_eye_params }) => {
    const video = {
      'Source name': name,
      'Source URL': src_file?.trim(),
      projection_type
    };

    const { camera_lens_enum: fisheyeLens } = { ...fish_eye_params };
    const isFisheye = FisheyeProjectionTypes.includes(projection_type);
    if (isFisheye && fisheyeLens) video['Fisheye Camera-lens'] = fisheyeLens;
    return video;
  })
});

export const mapToDestinationTemplate = encode => {
  const { egress, name, event_name, comment, notification } = {
    ...encode
  };
  const destinationOverview = {
    location: egress.map((e, i) => ({
      content: <span key={i}>{renderTemplateLocation(e, 'destination')}</span>
    })),
    encode_name: name,
    publication_folder: event_name
  };
  if (comment) destinationOverview['comment'] = comment;
  if (notification) {
    destinationOverview['notification'] = notification.target || 'none';
  }
  return destinationOverview;
};

export const mapToSourceOverview = ({ ingest, video }) => {
  const { src_file, projection_type, fish_eye_params } = { ...video };

  const sourceOverview = {
    location: renderLocation(ingest, 'source'),
    'Source URL': src_file,
    projection_type
  };

  const { camera_lens_enum: fisheyeLens } = { ...fish_eye_params };
  const isFisheye = FisheyeProjectionTypes.includes(projection_type);
  if (isFisheye && fisheyeLens) sourceOverview['Fisheye Camera-lens'] = fisheyeLens;
  return sourceOverview;
};

export const mapToEncoderOverview = encode => {
  const {
    drm,
    encoding_v3,
    advanced: { accept_input_failures }
  } = { ...encode };
  const {
    auto_add_stereo_downmix,
    video: { bundles, gaussian_sharpness_filter }
  } = { ...encoding_v3[0] };

  const encoderOverview = {
    Sharpness: gaussian_sharpness_filter,
    ABR_level: bundles
      ? bundles?.map(bundle => {
          const { down_conversion_factor, target_fov_in_degrees, ...parsedBundle } = {
            ...bundle
          };
          return removeCustomBitrate(parsedBundle);
        })
      : '-',
    DRM: drm?.type
  };

  if (bundles) {
    const downConversionFactor = bundles[0]?.['down_conversion_factor'];
    if (downConversionFactor)
      encoderOverview['Down conversion factor'] = downConversionFactor;

    const targetFov = bundles[0]?.['target_fov_in_degrees'];
    const { v_fov_in_deg, h_fov_in_deg } = { ...targetFov };
    if (h_fov_in_deg)
      encoderOverview['FoV override'] = `[Horizontal] ${h_fov_in_deg} degree`;
    if (v_fov_in_deg) {
      encoderOverview['FoV override'] = `${
        h_fov_in_deg ? encoderOverview['FoV override'] + ', ' : ''
      }[Vertical] ${v_fov_in_deg} degree`;
    }

    const scaleFactor = bundles[0]?.['scale_factor'];
    if (scaleFactor) encoderOverview['Downscale input resolution'] = `${scaleFactor} %`;
  }

  if (accept_input_failures)
    encoderOverview['accept input failure'] = accept_input_failures ? 'Yes' : 'No';

  if (auto_add_stereo_downmix)
    encoderOverview['add stereo downmix if not present'] = auto_add_stereo_downmix
      ? 'Yes'
      : 'No';

  return encoderOverview;
};

export const mapToDestinationOverview = encode => {
  const { name, event_name, comment, output, notification } = { ...encode };

  const destinationOverview = {
    location: renderLocation(output, 'destination'),
    URL: output?.url || '-',
    encode_name: name
  };

  if (output?.type !== 'http-put') delete destinationOverview.URL;
  if (output?.uuid !== 'null') {
    destinationOverview['publication_folder'] = event_name;
  }
  if (comment) destinationOverview['comment'] = comment;
  if (notification) {
    destinationOverview['notification'] = notification.target || 'none';
  }
  return destinationOverview;
};

export const setHttpAndNoOutputValues = output => {
  const { uuid, ...rest } = { ...output };
  const values = { uuid, type: uuid };
  switch (uuid) {
    case 'http-put':
      const { url } = { ...rest };
      return { ...values, url };
    case 'null':
      return { ...values, name: 'No output' };
    default:
      return null;
  }
};

export const mapToAllBundles = bundles => {
  // map first down_conversion_factor, target_fov_in_degrees value over each bundle
  const { down_conversion_factor, target_fov_in_degrees, scale_factor } = {
    ...bundles[0]
  };
  return bundles.map(bundle => {
    const { compressed_tiling_config, tiling_solution_description, ...mappedBundle } = {
      ...parseRateControl(bundle)
    };
    if (down_conversion_factor) {
      mappedBundle.down_conversion_factor = down_conversion_factor;
    } else delete mappedBundle.down_conversion_factor;
    if (Object.values(target_fov_in_degrees).find(fov => fov !== 0)) {
      mappedBundle.target_fov_in_degrees = target_fov_in_degrees;
    } else delete mappedBundle.target_fov_in_degrees;
    if (scale_factor) {
      mappedBundle.scale_factor = Math.pow(scale_factor / 100, 2);
    } else delete mappedBundle.scale_factor;
    return mappedBundle;
  });
};

const formatDuration = diff => dayjs.duration(diff).format(format_duration);

const getDuration = encode => {
  if (!encode) return;

  const { state } = { ...encode };
  const isHisEncode = HISTORY_STATES.includes(state);
  const started = dayjs(encode?.started);

  if (isHisEncode) {
    const finished = dayjs(encode?.finished);
    const diff = finished.diff(started);
    return {
      encoding_duration: state === 'finished' ? formatDuration(diff) : null
    };
  }

  const scheduled = dayjs(encode?.scheduled);
  const diff = dayjs().diff(state !== 'encoding' ? scheduled : started);
  return { duration_since_started: formatDuration(diff) };
};

const mapToContents = ({ encode, profile }) => {
  const { profile_name, encode_uuid, executor } = { ...encode };
  const { customer_id, name, group } = { ...profile };
  const contents = {
    encode_uuid,
    'Customer ID': `${customer_id} (${group || name})`
  };
  if (profile_name) contents['Profile'] = profile_name;
  if (executor) contents['Executor'] = executor;
  return contents;
};

const mapToStatus = encode => {
  const { progress_pct, scheduled, last_updated, comment, failed_cause } = {
    ...encode
  };
  const status = {
    progress: (progress_pct >= 100 ? 100 : Math.floor(progress_pct)) + ' %',
    ...getDuration(encode),
    scheduled: dayjs(scheduled).format(format),
    last_updated: dayjs(last_updated).format(format)
  };
  if (comment) status['Comment'] = comment;
  if (failed_cause) {
    status['Failed cause'] = <code className='text-danger'>{failed_cause}</code>;
  }
  return status;
};

export const renderListWithCopy = value => {
  if (!value) return;
  return (
    <li key={nanoid()} className='d-flex align-items-center'>
      {value}
      <CopyButton
        className={`border-0 shadow-none bg-transparent py-0 px-2 ${
          value ? 'opacity-100' : 'opacity-0'
        }`}
        size={18}
        value={value}
      />
    </li>
  );
};

const mapToSource = encode => {
  const { input_type, source_file, source_files } = { ...encode };
  const type = getLocationTypeOption(input_type);
  const source = {
    location: <Badge.Fill type='source'>{type}</Badge.Fill>
  };
  if (source_file) source['source_file'] = source_file;
  if (source_files) {
    source['source_files'] = (
      <ul className='list-unstyled'>
        {source_files.map(file => renderListWithCopy(file))}
      </ul>
    );
  }
  return source;
};

// TODO: test
const mapToDestination = encode => {
  const { output_type, event_name, access_url } = { ...encode };
  const type = getLocationTypeOption(output_type);
  const destination = {
    location: type ? <Badge.Fill type='destination'>{type}</Badge.Fill> : 'No output'
  };
  if (event_name) destination['publication_folder'] = event_name;
  if (access_url) destination['Access URL'] = access_url;
  return destination;
};

export const mapToEncodeModal = ({ encode, profile }) => {
  if (!encode) return;

  const { name, state, blueprint_name } = { ...encode };
  const data = { name, state };
  data['contents'] = mapToContents({ encode, profile });
  data['status'] = mapToStatus(encode);

  if (blueprint_name) data.template = { 'Template name': blueprint_name };
  data['source'] = mapToSource(encode);
  data['destination'] = mapToDestination(encode);
  return data;
};
