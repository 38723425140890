import BoxIcon from 'remixicon-react/CheckboxBlankLineIcon';
import CheckedIcon from 'remixicon-react/CheckboxFillIcon';

export const Text = ({ id, children, className = '', ...props }) => (
  <div className={`form-check ${className}`}>
    <input className='form-check-input' id={id} type='checkbox' {...props} />
    <label className='form-check-label color-medium' htmlFor={id}>
      {children}
    </label>
  </div>
);

export const Box = ({ checked, onClick, className = '' }) =>
  checked ? (
    <CheckedIcon className={`fill-primary ${className}`} onClick={onClick} />
  ) : (
    <BoxIcon className={className} onClick={onClick} />
  );

const Checkbox = {
  Box,
  Text
};

export default Checkbox;
