import { useTranslation } from 'react-i18next';
import CopyButton from 'components/CopyButton';

const LiveCopyItems = ({ contents }) => {
  const { t } = useTranslation('validation');
  return (
    <>
      {contents.map(content => {
        const key = Object.keys(content);
        const value = content[key];
        return (
          <div key={key} className='d-flex align-items-center color-high'>
            <h6 className='fw-normal m-0 text-truncate text-sm'>
              <strong className='me-3'>{t(key)}</strong>
              {value || 'NA'}
            </h6>
            <CopyButton
              className={`border-0 shadow-none bg-transparent ${
                value ? 'opacity-100' : 'opacity-0'
              }`}
              size={18}
              value={value}
            />
          </div>
        );
      })}
    </>
  );
};

export default LiveCopyItems;
