import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';
import SearchIcon from 'remixicon-react/SearchLineIcon';
import ClearIcon from 'remixicon-react/CloseLineIcon';

const SearchInput = ({ searchValue, onChange }) => {
  const { t } = useTranslation('validation');
  const theme = useSelector(({ user }) => user?.settings?.theme);
  return (
    <div className='search-input input-group rounded border-line transition'>
      <span className='input-group-text input-background color-disabled border-0 pe-0'>
        <SearchIcon />
      </span>
      <input
        type='text'
        className='form-control border-0 shadow-none ms-0'
        value={searchValue}
        onChange={event => onChange(event.target.value)}
        placeholder={t('search_inactive_transcoder')}
        aria-label='Search input'
        aria-describedby='search-input'
      />
      <Button
        variant={theme === 'dark' ? 'light' : 'dark'}
        className='input-group-text input-background color-medium border-0 ms-0 ps-0 shadow-none'
        onClick={() => onChange('')}
        aria-label='clear search text'
      >
        <ClearIcon />
      </Button>
    </div>
  );
};

export default SearchInput;
