import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BsModal from 'react-bootstrap/Modal';

const Modal = props => {
  const { theme } = useSelector(({ user }) => user.settings);
  const url = `${process.env.REACT_APP_COCKPIT_CDN}/pics`;

  const contents = [
    {
      title: 'Mono type',
      text: 'Consisting of only one set of screen (e.g. 1 or 6)',
      pics: [
        { '360 ERP': 'erp_mono' },
        { '180 ERP': '180Erp_mono' },
        { '3x2 Cubemap': 'cubemap_mono' },
        { '3x2 Mpeg Cubemap': 'cubemapMpeg_mono' },
        { 'Fish eye': 'fisheye_mono' }
      ]
    },
    {
      title: 'Stereo type',
      text: 'Consisting of both left- and right-eye screens (e.g. 2 or 12)',
      pics: [
        { '360 ERP': 'erp_stereo' },
        { '180 ERP': '180Erp_stereo' },
        { '3x2 Cubemap': 'cubemap_stereo' },
        { '3x2 Mpeg Cubemap': 'cubemapMpeg_stereo' },
        { 'Fish eye': 'fisheye_stereo' }
      ]
    }
  ];
  return (
    <BsModal
      className={theme}
      contentClassName='modal-background dp-02'
      size='xl'
      centered
      scrollable
      {...props}
    >
      <BsModal.Header
        closeVariant={theme === 'dark' ? 'white' : null}
        closeButton
      >
        <h1 className='h3 color-high'>Projection type</h1>
      </BsModal.Header>
      <BsModal.Body>
        {contents.map(({ title, text, pics }) => (
          <section key={title} className='row'>
            <h2 className='h4 color-primary'>{title}</h2>
            <p className='color-medium'>{text}</p>
            <div className='row'>
              {pics.map(pic => {
                const key = Object.keys(pic)[0];
                const value = pic[key];
                const src = `${url}/${value}`;
                const styles = `${
                  value === 'fisheye_stereo'
                    ? 'col-12 col-lg-8'
                    : 'col-6 col-lg-4'
                } mb-5`;
                return (
                  <article key={key + title} className={styles}>
                    <h3 className='h6 color-high'>{key}</h3>
                    <picture>
                      <source srcSet={`${src}.webp`} type='image/webp' />
                      <source srcSet={`${src}.jpeg`} type='image/png' />
                      <img
                        className='lazy-load w-100'
                        src={`${src}.jpeg`}
                        alt={value}
                      />
                    </picture>
                  </article>
                );
              })}
            </div>
          </section>
        ))}
      </BsModal.Body>
    </BsModal>
  );
};

const Wrapper = ({ children, show, onClick, className }) => {
  const { t } = useTranslation('validation');

  const handleShow = () => onClick(true);
  const handleHide = () => onClick(false);
  return (
    <>
      {children}
      <div
        className={`color-primary link pe-pointer align-self-start ${className}`}
        onClick={handleShow}
      >
        {t('how_to_identify_projection')}
      </div>
      <Modal show={show} onHide={handleHide} />
    </>
  );
};

const ProjectionType = {
  Modal,
  Wrapper
};

export default ProjectionType;
