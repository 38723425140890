import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik, Form, FieldArray } from 'formik';

import { LiveFormFields } from 'constants/forms';
import { actionTypes } from 'constants/live';
import {
  setAlertProps,
  renderButtonText,
  showEventName,
  checkRestart,
  setFisheyeOptions,
  isPreMeshbox
} from 'utils/liveUtils';
import { setRuntimeParams } from 'utils/utils';
import Modal from 'react-bootstrap/Modal';
import TextField from 'components/TextField';
import { AccessUrlFields, GroupFields } from 'components/LiveFields';
import { ABRFields } from 'components/VodFields';
import Subtitle from 'components/VodSubtitle';
import Button from 'react-bootstrap/Button';
import EmptyButton from 'components/EmptyButton';
import Alert from 'components/Alert';

const LiveConfigForm = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation(['common', 'validation']);

  const formRef = useRef();
  const focusref = useRef(null);
  const { groups } = useSelector(({ user }) => user);
  const { name: modalName, content: modalContent } = useSelector(({ modal }) => modal);

  const isUpdate = modalName === actionTypes.update;
  const helpMessage = isUpdate && 'hint_restart';

  const { schema, fields, touchedField } = LiveFormFields;
  const { name, static_config, type, ...props } = modalContent;
  const isHLS = type === 'hls';
  const output = static_config?.egress || [static_config?.output];

  const { dynamic_config } = props;
  const showSource = dynamic_config?.config_parameters?.source;
  const runtime_parameters = setRuntimeParams(
    dynamic_config,
    static_config,
    type
  );

  const showEvent = isUpdate
    ? Boolean(dynamic_config?.config_parameters?.event_name)
    : showEventName(output);

  const config_parameters = showEvent
    ? {
        ...dynamic_config?.config_parameters,
        event_name: dynamic_config?.config_parameters?.event_name || ''
      }
    : { ...dynamic_config?.config_parameters };

  const initialValues = {
    ...props,
    dynamic_config: { config_parameters, runtime_parameters }
  };

  useEffect(() => {
    focusref.current?.focus();
    if (!isUpdate) {
      formRef.current?.validateForm();
      formRef.current?.setTouched(touchedField);
    }
  }, []); // eslint-disable-line

  return (
    <Formik
      innerRef={formRef}
      validationSchema={showEvent ? schema.liveConfig_Event : schema.liveConfig}
      initialValues={initialValues}
      onSubmit={values => onSubmit(values)}
      enableReinitialize
    >
      {({ isValid, values }) => {
        const { dynamic_config } = values;
        const { event_name } = dynamic_config?.config_parameters;
        const { fish_eye_params, bundles } = dynamic_config?.runtime_parameters;
        const requiredRestart = isUpdate && checkRestart(modalContent, values);
        const bundleName = 'dynamic_config.runtime_parameters.bundles';
        return (
          <Form className='d-flex flex-column justify-content-between w-100'>
            <section className='form-scrollable px-1'>
              {showEvent && (
                <div className='row'>
                  <TextField
                    {...fields.event}
                    className='col-xxl-6 col-lg-8 col-md-12'
                    helpMessage={helpMessage}
                    focusref={focusref}
                  />
                </div>
              )}
              {showSource && !isHLS && (
                <div className='row'>
                  <TextField {...fields.source} helpMessage={helpMessage} />
                </div>
              )}
              {/* TODO: need to wait for backend */}
              {!(isUpdate && isHLS) && (
                <AccessUrlFields output={output} eventName={event_name} />
              )}
              <div className='d-flex flex-column flex-lg-row gap-4'>
                {!isHLS && (
                  <TextField
                    className='input-md'
                    {...fields.sharpness}
                    autoFocus={!showEvent}
                    helpMessage={fields.sharpness.helpMessage}
                  />
                )}
                {fish_eye_params && (
                  <TextField
                    className='col'
                    options={setFisheyeOptions(groups)}
                    {...fields.fisheye}
                    disabled={isHLS}
                  />
                )}
              </div>
              {isPreMeshbox(type) ? (
                <GroupFields bundles={fields.bundles} />
              ) : (
                <>
                  {bundles && (
                    <FieldArray name={bundleName}>
                      <>
                        <Subtitle
                          className='mt-4'
                          text={t(`validation:ABR_level`)}
                          size='sm'
                        />
                        <ABRFields name={bundleName} bundles={bundles} />
                      </>
                    </FieldArray>
                  )}
                </>
              )}
            </section>

            <Modal.Footer className='d-flex justify-content-between p-0 mt-5'>
              {requiredRestart && (
                <Alert
                  className='text-nowrap w-auto fade-in'
                  {...setAlertProps('restart')}
                />
              )}
              <span className='ms-auto'>
                <EmptyButton onClick={onCancel}>{t('cancel')}</EmptyButton>
                <Button
                  type='submit'
                  variant={requiredRestart ? 'warning' : 'primary'}
                  className='border-0 ms-4'
                  disabled={!isValid}
                >
                  {t(renderButtonText(modalName, requiredRestart))}
                </Button>
              </span>
            </Modal.Footer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LiveConfigForm;
