import { useSelector } from 'react-redux';
import { setPrimary } from 'utils/utils';
import Spinner from 'react-bootstrap/Spinner';

const Loader = ({ fullscreen, zIndex = 10, className = '', ...rest }) => {
  const theme = useSelector(({ user }) => user?.settings?.theme);
  const styles = fullscreen
    ? 'vw-100 vh-100 position-fixed top-0 start-0 overlay-background'
    : 'w-100 h-75';

  return (
    <div
      className={`d-flex justify-content-center align-items-center z-${zIndex} ${styles} ${className}`}
    >
      <Spinner animation='border' variant={setPrimary(theme)} {...rest} />
    </div>
  );
};

export default Loader;
