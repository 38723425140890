import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { streamStages, stateTypes } from 'constants/live';
import { capitalizeString, msToTime } from 'utils/utils';

import Card from 'react-bootstrap/Card';
import StateIndicator from 'components/StateIndicator';
import Badge from 'components/Badge';
import TimerIcon from 'remixicon-react/TimerLineIcon';

const setSelectedStyles = selected =>
  !selected ? 'card-background border-line' : 'hover-background border-primary';

const Active = ({ data, selected, onClick }) => {
  const { t } = useTranslation('glossary');

  const { name, state, state_since, stream_status_overview, dynamic_config } = data;
  const [duration, setDuration] = useState(null);
  const activations = dynamic_config?.runtime_parameters?.slate_settings?.activations;

  const hasActiveSlates = activations?.length > 0;

  const activeSlateType = activations => {
    const usingInputSlate = activations?.find(
      ({ input_filter }) => input_filter?.length > 0
    );
    const usingOutputSlate = activations?.find(
      ({ egress_filter }) => egress_filter?.length > 0
    );

    if (usingInputSlate && usingOutputSlate) return 'Input & Output';
    if (usingInputSlate) return 'Input';
    if (usingOutputSlate) return 'Output';
  };

  const chipText = capitalizeString(
    hasActiveSlates ? `${activeSlateType(activations)} slate` : t(state)
  );

  const cardStyles = setSelectedStyles(selected);

  useEffect(() => {
    setDuration(Math.abs(Date.now() - new Date(state_since)));
  }, [state_since]);

  useEffect(() => {
    const timer = setInterval(() => setDuration(preState => preState + 1000), 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Card className={`transition dp-01 p-2 pe-pointer ${cardStyles}`} onClick={onClick}>
      <Card.Body className='p-2 pt-0 d-grid gap-2'>
        <h6 className='text-md color-high lh-base m-0 py-1 me-5'>
          <span className='me-2'>{name}</span>
          <Badge.Fill
            className='base-font text-xs'
            type={hasActiveSlates ? stateTypes?.slate : state}
          >
            {chipText}
          </Badge.Fill>
        </h6>
        {stream_status_overview && (
          <Card.Subtitle className='d-flex flex-wrap gap-3'>
            {streamStages.map(props => (
              <StateIndicator data={stream_status_overview} {...props} />
            ))}
          </Card.Subtitle>
        )}
        <Card.Text className='color-medium text-sm'>
          <TimerIcon className='color-medium me-2' size={18} />
          {msToTime(duration)}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const Inactive = ({ name, type, selected, onOpen }) => (
  <Card
    className={`${setSelectedStyles(selected)} card-hover transition dp-01 pe-pointer`}
    onClick={onOpen}
  >
    <Card.Body className='d-flex justify-content-between align-items-center gap-2 p-25 ps-3'>
      <h6 className='lh-base color-high m-0 text-sm me-5'>
        {name}
        <Badge.Line className='ms-2'>{type}</Badge.Line>
      </h6>
    </Card.Body>
  </Card>
);

const TranscoderCard = {
  Active,
  Inactive
};

export default TranscoderCard;
