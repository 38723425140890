export const DISABLED_TYPES = ['Omnidirectional (mono)', 'Omnidirectional (stereo)', 'Rectilinear'];
export const PROJECTION_TYPES = [
  'Choose an input projection',
  DISABLED_TYPES[0],
  '360-erp-mono',
  '180-erp-mono',
  '3x2-cubemap-mono',
  '3x2-mpeg-cubemap-mono',
  'fish-eye-mono',
  DISABLED_TYPES[1],
  '360-erp-stereo-top-bottom',
  '180-erp-stereo-left-right',
  // '180-erp-stereo-top-bottom',
  '3x2-cubemap-stereo-top-bottom',
  '3x2-mpeg-cubemap-stereo-top-bottom',
  'fish-eye-stereo-left-right',
  DISABLED_TYPES[2],
  'planar',
  'agnostic',
  'rectilinear',
  'rectilinear-stereo-tb',
  'rectilinear-stereo-sbs'
];

export const TARGET_DECODERS = ['hevc.4.1', 'hevc.5.1', 'hevc.6.1', "hevc.inf"];
export const PACKAGING_FORMATS = ['tm31-manifest-v7', 'tm32-manifest-v7', 'tm40-manifest-v8', 'tm40-manifest-v8-sl'];
export const PACKAGING_FORMAT_DEFAULT = PACKAGING_FORMATS[0];

export const VOD_PLATFORMS = ['live', 'vod'];
export const VOD_TYPES = ['sources', 'encoders', 'destinations'];
export const VOD_URL_TYPES = ['source', 'encoder', 'destination'];
export const RATE_CONTROL = {
  constant: ['quality', 'bitrate', 'full-custom'],
  preset: ['low', 'medium', 'default', 'high', 'custom']
};

export const JOB_STATES = ['starting', 'ingesting', 'encoding', 'finishing', 'pending'];
export const HISTORY_STATES = ['finished', 'failed', 'cancelled'];
export const HISTORY_FILTER = ['all', ...HISTORY_STATES];

export const hasCopy = ['source_file', 'encode_uuid', 'Access URL', 'publication_folder'];

export const LocationTypes = {
  source: [
    's3bucket',
    'azure-blob',
    'gcp-storage',
    'aspera-faspex'
    // 'http-pull'
  ].sort(),
  destination: [
    'akamai-ns',
    'akamai-multi-ns',
    'wangsu',
    'kingsoft',
    'aws-mediastore',
    'http-put',
    's3bucket',
    'azure-blob'
  ].sort()
};

export const EventManifestFilenameClearVR = 'manifest.json';
export const EventManifestFilenameMosaic = 'event-mosaic.tme';
export const EventManifestFilenameLegacy = 'event.tme';
