import { nanoid } from '@reduxjs/toolkit';

import LiveLineIcon from 'remixicon-react/LiveLineIcon';
import LiveFillIcon from 'remixicon-react/LiveFillIcon';
import FilmLineIcon from 'remixicon-react/FilmLineIcon';
import FilmFillIcon from 'remixicon-react/FilmFillIcon';
// import UsageLineIcon from 'remixicon-react/BarChart2LineIcon';
// import UsageFillIcon from 'remixicon-react/BarChart2FillIcon';
import SettingsLineIcon from 'remixicon-react/Settings5LineIcon';
import SettingsFillIcon from 'remixicon-react/Settings5FillIcon';
// import FaqLineIcon from 'remixicon-react/QuestionLineIcon';
// import FaqFillIcon from 'remixicon-react/QuestionFillIcon';

const ICON_SIZE = 24;

export const navigationItems = [
  {
    id: nanoid(),
    label: 'live',
    url: '/live',
    icon: <LiveLineIcon size={ICON_SIZE} />,
    activeIcon: <LiveFillIcon size={ICON_SIZE} />
  },
  {
    id: nanoid(),
    label: 'vod',
    url: '/vod',
    icon: <FilmLineIcon size={ICON_SIZE} />,
    activeIcon: <FilmFillIcon size={ICON_SIZE} />
  },
  // {
  //   id: nanoid(),
  //   label: 'data_usage',
  //   url: '/usage',
  //   icon: <UsageLineIcon size={ICON_SIZE} />,
  //   activeIcon: <UsageFillIcon size={ICON_SIZE} />
  // },
  {
    id: nanoid(),
    label: 'settings',
    url: '/settings',
    icon: <SettingsLineIcon size={ICON_SIZE} />,
    activeIcon: <SettingsFillIcon size={ICON_SIZE} />
  }
  // {
  //   id: nanoid(),
  //   label: 'questions',
  //   url: '/faq',
  //   icon: <FaqLineIcon size={ICON_SIZE} />,
  //   activeIcon: <FaqFillIcon size={ICON_SIZE} />
  // }
];
