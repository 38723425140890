import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { renderAlertIcon } from 'utils/liveUtils';
import Toast from 'react-bootstrap/Toast';

const Alert = ({ type, text, onClose, className = '' }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const autohideType = type === 'info' || type === 'success';
  const isDanger = type === 'danger';

  const handleClose = event => {
    if (event) {
      event.stopPropagation();
      onClose();
    }
    setShow(false);
  };

  useEffect(() => {
    if (!isDanger) setShow(true);
    let timer = setTimeout(() => setShow(true), 10 * 1000);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []); // eslint-disable-line

  return (
    <Toast
      className={`w-100 bg-${type} shadow-none border-0 p-2 ${className}`}
      show={show}
      autohide={autohideType}
      delay={autohideType ? 6000 : 0}
      onClose={event => handleClose(event)}
    >
      <Toast.Header
        className='p-0 border-0 bg-transparent'
        closeVariant={isDanger ? 'white' : null}
        closeButton={false}
      >
        <div
          className={`w-100 fw-bolder text-${
            isDanger ? 'white' : 'black'
          } row align-items-center g-0 pe-2`}
        >
          <span className='col-auto'>{renderAlertIcon(type)}</span>
          <small className={`${isDanger ? 'link' : ''} col m-0 ms-2`}>
            {t(text)}
          </small>
        </div>
        {isDanger && (
          <div
            className='btn-action btn-transition h-100 px-2 py-1 rounded border border-1 border-light'
            onClick={handleClose}
          >
            <p className='text-white text-nowrap fw-bold my-auto'>
              {t('clear')}
            </p>
          </div>
        )}
      </Toast.Header>
    </Toast>
  );
};

export default Alert;
