import * as Yup from 'yup';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Formik, Form } from 'formik';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { useRef, useState, useEffect } from 'react';

import { clearHistoryRecords } from 'store/live2vod/live2vodSlice';
import { scheduleLive2VOD, listLive2VODRecords } from 'store/live2vod/live2vodThunk';
import { renderLive2VODStatusIcon } from 'utils/liveUtils';
import { event_name } from 'constants/forms';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { LocationSelect } from 'components/VodFields';
import EmptyButton from 'components/EmptyButton';
import Loader from 'components/Loader';
import Table from 'components/Table';
import TextField from 'components/TextField';

const FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const TYPES = ['local', 'unix'];

const Live2VodForm = ({ onCancel }) => {
  dayjs.extend(customParseFormat);

  const dispatch = useDispatch();
  const content = useSelector(({ modal }) => modal.content);
  const { active, history } = useSelector(({ live2vod }) => live2vod);
  const {
    username,
    vod_profiles: { destinations = [] }
  } = useSelector(({ user }) => user);

  const focusref = useRef(null);
  const [selectedType, setSelectedType] = useState(TYPES[0]);

  const initialValues = {
    name: content.name,
    stack_uuid: content?.last_stack_uuid,
    start_time: dayjs(content?.state_since).format(FORMAT),
    end_time: dayjs().format(FORMAT),
    enable_copy: false,
    copy_event_name: ''
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('required_field'),
    start_time:
      selectedType === 'local'
        ? Yup.string().test('is-invalid', 'Invalid time', value =>
            dayjs(value, true).isValid()
          )
        : Yup.number().typeError('must_be_number').integer(),
    end_time:
      selectedType === 'local'
        ? Yup.string()
            .test('is-invalid', 'Invalid time', value => dayjs(value, true).isValid())
            .test(
              'is-greater',
              'End time should be greater',
              (value, { parent: { start_time } }) =>
                dayjs(value).isAfter(dayjs(start_time))
            )
        : Yup.number()
            .typeError('must_be_number')
            .integer()
            .test(
              'is-greater',
              'End time should be greater',
              (value, { parent: { start_time } }) => value > start_time
            ),
    copy_event_name: Yup.string().when(['enable_copy'], {
      is: enable_copy => enable_copy,
      then: event_name
    }),
    output: Yup.object().when(['enable_copy'], {
      is: enable_copy => enable_copy,
      then: Yup.object().shape({
        uuid: Yup.string().required('required_field')
      })
    })
  });

  const historyColumns = [
    { path: 'status', content: record => renderLive2VODStatusIcon(record) },
    { path: 'name', label: 'Name' },
    { path: 'executor', label: 'Executor' },
    {
      path: 'hls_manifest_filename',
      label: 'File name',
      content: ({ hls_manifest_filename, clearvr_manifest_filename }) => {
        if (hls_manifest_filename && clearvr_manifest_filename) {
          return hls_manifest_filename + ', ' + clearvr_manifest_filename;
        }
        return hls_manifest_filename || clearvr_manifest_filename || '-';
      }
    },
    {
      path: 'access_urls',
      label: 'Access URL',
      content: ({ access_urls, error }) => renderURLCell({ urls: access_urls, error })
    },
    {
      path: 'copy_result_url',
      label: 'Copy access URL',
      content: ({ copy_result_url, copy_error }) =>
        renderURLCell({ urls: [copy_result_url], error: copy_error })
    }
  ];

  const renderURLCell = ({ urls, error }) => (
    <ul className='list-unstyled text-break mb-0'>
      {error ? (
        <li className='text-danger'>
          <strong className='me-1'>
            [{dayjs(error?.t).format('YYYY-MM-DD HH:mm:ss')}]
          </strong>
          {error?.m}
        </li>
      ) : (
        urls?.map(url => <li key={nanoid()}>{url}</li>)
      )}
    </ul>
  );

  const convertTime = time => {
    if (selectedType === 'local') {
      const unixTime = dayjs(time).unix();
      return isNaN(unixTime) ? 0 : unixTime;
    }
    return dayjs.unix(time).format(FORMAT);
  };

  const timeToString = time => {
    if (selectedType === 'local') return dayjs(time).toISOString();
    return dayjs.unix(time).toISOString();
  };

  const handleScheduleLive2Vod = async values => {
    const { start_time, end_time, output, copy_event_name, ...rest } = { ...values };

    const payload = {
      executor: username,
      start_time: timeToString(start_time),
      end_time: timeToString(end_time),
      ...rest
    };

    if (rest?.enable_copy) {
      const destination = destinations?.find(({ uuid }) => uuid === output?.uuid);
      const { uuid, name, group, force_single_part_byte_range, ...rest } = {
        ...destination
      };
      payload['copy_destination'] = { ...rest };
      payload['copy_event_name'] = copy_event_name;
    }

    const response = await dispatch(scheduleLive2VOD(payload));
    const { requestStatus: status } = { ...response.meta };
    if (status === 'fulfilled' || status === 'rejected') onCancel();
  };

  useEffect(() => {
    focusref.current.focus();
    const { last_stack_uuid: stack_uuid } = { ...content };
    dispatch(listLive2VODRecords({ stack_uuid }));
    return () => dispatch(clearHistoryRecords());
  }, []); // eslint-disable-line

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleScheduleLive2Vod}
    >
      {({ values, setFieldValue, setFieldTouched, validateField }) => {
        const timeProps = {
          className: 'col-12 col-xl-6',
          type: selectedType === 'local' ? 'datetime-local' : 'number',
          validation: true
        };
        if (selectedType === 'local') timeProps['step'] = '1';

        const handleClick = type => {
          setSelectedType(type);

          const start_value = convertTime(values.start_time);
          setFieldValue('start_time', start_value);
          setFieldTouched('start_time', false);

          const end_value = convertTime(values.end_time);
          setFieldValue('end_time', end_value);
          setFieldTouched('end_time', false);
        };

        const handleChange = ({ target: input }) => {
          if (!input?.value) return;
          const destination = destinations?.find(({ uuid }) => uuid === input?.value);
          const { uuid = '', folder = '' } = { ...destination };
          setFieldValue('output.uuid', uuid);
          setFieldValue('folder', folder);
        };

        const handleCheckCopy = ({ target: input }) => {
          if (input?.checked) validateField('output');
        };

        return (
          <Form className='d-flex flex-column justify-content-between w-100'>
            {active.loading ? (
              <Loader />
            ) : (
              <section className='px-1'>
                <div className='row'>
                  <TextField
                    className='col-12 col-xxl-6 me-xxl-5'
                    name='name'
                    label='Name'
                    focusref={focusref}
                    validation
                  />
                  <FormatField
                    className='col-12 col-lg-6 me-lg-5 mb-4'
                    selectedType={selectedType}
                    onClick={handleClick}
                  />
                  <TextField name='start_time' label='Start time' {...timeProps} />
                  <TextField name='end_time' label='End time' {...timeProps} />
                  <TextField
                    name='enable_copy'
                    label='Create a permanent copy to customized storage'
                    type='checkbox'
                    className='ms-3 mb-4 col-auto'
                    onClick={handleCheckCopy}
                  />
                </div>
                {values.enable_copy && (
                  <div className='row fade-in'>
                    <LocationSelect onChange={handleChange} />
                    <TextField
                      className='col-12 col-lg-4'
                      name='copy_event_name'
                      label='Publication folder'
                      validation
                    />
                  </div>
                )}
                <div className='row'>
                  <div className='col-12'>
                    <h5 className='text-md color-high m-0 py-3 underline'>
                      VoD Manifest History
                    </h5>
                    {history.loading ? (
                      <Loader className='h-auto my-4' />
                    ) : history.records?.length ? (
                      <Table
                        className='video-table live2vod-table'
                        data={history.records}
                        columns={historyColumns}
                        hover={false}
                        size='sm'
                        responsive
                      />
                    ) : (
                      <h6 className='fw-normal color-medium py-2'>No data</h6>
                    )}
                  </div>
                </div>
              </section>
            )}
            <Modal.Footer>
              <EmptyButton onClick={onCancel}>
                <Trans i18nKey='cancel'>'cancel'</Trans>
              </EmptyButton>
              <Button type='submit' variant='primary' className='border-0 ms-4'>
                <Trans i18nKey='confirm'>'confirm'</Trans>
              </Button>
            </Modal.Footer>
          </Form>
        );
      }}
    </Formik>
  );
};

const FormatField = ({ selectedType, onClick, className = 'col-auto' }) => (
  <div className={className}>
    <div className='form-label color-medium'>Time format</div>
    <ButtonGroup className='w-100' aria-label='time format'>
      {TYPES.map(type => (
        <Button
          key={type}
          variant={type === selectedType ? 'primary' : 'outline-primary'}
          className={`text-capitalize shadow-none ${
            type === selectedType ? 'pe-none' : ''
          }`}
          onClick={() => onClick(type)}
        >
          {type} time
        </Button>
      ))}
    </ButtonGroup>
  </div>
);

export default Live2VodForm;
