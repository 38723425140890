import 'cross-fetch/polyfill';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import Pool from 'config/cognito';
import { toast } from 'react-toastify';

let cognitoUser;

const getCognitoUser = ({ Username }) => new CognitoUser({ Username, Pool });

export const authenticateUser = async ({
  username: Username,
  password: Password
}) => {
  cognitoUser = getCognitoUser({ Username });
  const authDetails = new AuthenticationDetails({ Username, Password });
  return await new Promise((resolve, reject) =>
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: result => {
        const jwt = result.getIdToken().getJwtToken();
        resolve(jwt);
      },
      onFailure: error => {
        const message = error.message;
        reject(message || JSON.stringify(error));
        toast.error(message, { toastId: message, autoClose: 3000 });
      },
      newPasswordRequired: userAttributes => {
        resolve({
          name: Username,
          email: userAttributes.email,
          isFirstLogin: true
        });
      }
    })
  );
};

export const setNewPassword = async ({ username, newPassword }) =>
  await new Promise((resolve, reject) =>
    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      { name: username },
      {
        onSuccess: result => {
          const jwt = result.getIdToken().getJwtToken();
          resolve(jwt);
        },
        onFailure: error => {
          const message = error.message;
          reject(message || JSON.stringify(error));
          toast.error(message, { toastId: message, autoClose: 3000 });
        }
      }
    )
  );

export const forgotPassword = async ({ username }) => {
  cognitoUser = getCognitoUser({ Username: username });
  return await new Promise((resolve, reject) =>
    cognitoUser.forgotPassword({
      onSuccess: () => resolve(username),
      onFailure: error => reject(error.message || JSON.stringify(error))
      // inputVerificationCode: data => console.log(data)
    })
  );
};

export const resetPassword = async ({
  code,
  new_password,
  confirm_password
}) => {
  if (new_password !== confirm_password) return;
  return await new Promise((resolve, reject) =>
    cognitoUser.confirmPassword(code, new_password, {
      onSuccess: result => resolve(result),
      onFailure: error => reject(error.message || JSON.stringify(error))
    })
  );
};

export const getSession = async () =>
  await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    // const username = user.getUsername();

    if (user) {
      user.getSession((error, session) => {
        if (error) reject(error);
        else resolve(session);
      });
    } else {
      reject('No current user!');
    }
  });

export const getJwt = async () =>
  await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();

    if (user) {
      user.getSession((error, session) => {
        if (error) reject(error);
        else resolve(session.idToken.jwtToken);
      });
    } else {
      reject('👾 No valid token');
    }
  });

export const getCognitoGroups = async () =>
  await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession((error, session) => {
        if (error) reject(error);
        else {
          const groups = session.idToken.payload['cognito:groups'];
          // group added by cognito ui could be uppercase
          const groupsLowerCase = groups?.map(group => group.toLowerCase());
          const groupsWithoutDuplicate = groupsLowerCase?.filter(
            (group, index, self) => self.indexOf(group) === index
          );
          resolve(groupsWithoutDuplicate);
        }
      });
    } else {
      reject('👾 No valid token');
    }
  });

export const logout = () => {
  const user = Pool.getCurrentUser();

  if (user) {
    user.signOut();
    window.location = '/';
  }
};
