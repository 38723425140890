import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { addPanel, removePanel, setAllPanels } from 'store/live/liveSlice';
import { actionTypes, allGraphs, navLists } from 'constants/live';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import EmptyButton from 'components/EmptyButton';
import CheckBox from 'components/Checkbox';

const LiveChartModal = ({ onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useSelector(({ user }) => user.settings?.theme);
  const modalName = useSelector(({ modal }) => modal.name);
  const {
    charts: { panels }
  } = useSelector(({ live }) => live);

  const handleClick = panelData => {
    const index = panels.findIndex(({ id }) => id === panelData.id);
    if (index === -1) dispatch(addPanel(panelData));
    else dispatch(removePanel(index));
  };

  const handleClear = () => {
    dispatch(setAllPanels([]));
  };

  return (
    <Modal
      show={modalName === actionTypes.addChart}
      onHide={onCancel}
      className={theme}
      contentClassName='p-4'
      size='xl'
      centered
    >
      <Modal.Header>
        <Modal.Title className='text-center color-high w-100 fs-3'>
          {t('select_charts')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='row g-3'>
        {navLists.map(type => (
          <section key={type} className='col-xs-1 col-lg-3'>
            <Card className='rounded h-100 bg-transparent border-line overflow-hidden'>
              <Card.Header className='text-capitalize fw-bolder color-medium underline'>
                {type}
              </Card.Header>
              <Card.Body className='color-medium'>
                <div className='row g-3'>
                  {allGraphs[type].map(({ panelId, titleText, ...props }) => (
                    <span key={panelId} className='col-12'>
                      <CheckBox.Box
                        className='pe-pointer me-1'
                        checked={panels.find(({ id }) => id === panelId)}
                        onClick={() => handleClick({ id: panelId, ...props })}
                      />
                      <Card.Text className='d-inline-block align-middle'>
                        {titleText}
                      </Card.Text>
                    </span>
                  ))}
                </div>
              </Card.Body>
            </Card>
          </section>
        ))}
      </Modal.Body>
      <Modal.Footer className='mt-3 gap-4 justify-content-center'>
        <EmptyButton onClick={handleClear} disabled={!panels.length}>
          {t('clear_all')}
        </EmptyButton>
        <Button variant='primary' onClick={onCancel}>
          {t('confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LiveChartModal;
