import { useSelector } from 'react-redux';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
  materialLight,
  materialDark
} from 'react-syntax-highlighter/dist/esm/styles/prism';
import { formateLogs } from 'utils/liveUtils';

const LiveLogSection = ({ children }) => {
  const theme = useSelector(({ user }) => user.settings?.theme);
  const messages = useSelector(
    ({ live }) => live?.live_details?.status?.messages
  );
  const formattedMessages = messages?.length
    ? formateLogs(messages).join('\n')
    : 'no logs';
  return (
    <section>
      {children}
      <div className='overflow-auto rounded' style={{ maxHeight: '12vh' }}>
        <SyntaxHighlighter
          language='wiki'
          className='my-0 py-2 w-100'
          style={theme === 'dark' ? materialDark : materialLight}
          codeTagProps={{ className: 'small' }}
          wrapLines={true}
          wrapLongLines={true}
        >
          {formattedMessages}
        </SyntaxHighlighter>
      </div>
    </section>
  );
};

export default LiveLogSection;
