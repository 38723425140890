import { useTranslation } from 'react-i18next';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
  materialLight,
  materialDark
} from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useSelector } from 'react-redux';
import { timeDifference } from 'utils/utils';
import { formateLogs } from 'utils/liveUtils';
import { actionTypes } from 'constants/live';
import Modal from 'react-bootstrap/Modal';
import CloseButton from 'react-bootstrap/CloseButton';
import CopyButton from 'components/CopyButton';
import RefreshIcon from 'remixicon-react/RefreshLineIcon';

const LiveConfigDialog = ({ eventKey, onCancel }) => {
  const { checkConfig, checkRunningConfig, checkIngestDetails, checkRunningLogs } =
    actionTypes;
  const isConfig = eventKey === checkConfig || eventKey === checkRunningConfig;
  const isIngestDetails = eventKey === checkIngestDetails;

  const { t } = useTranslation(['common', 'glossary']);

  const theme = useSelector(({ user }) => user?.settings?.theme);
  const content = useSelector(({ modal }) => modal?.content);
  const {
    live_details,
    snapshot: {
      index: { current: currentIndex }
    }
  } = useSelector(({ live }) => live);

  const { status } = { ...live_details };
  const { config, ingest_status, multi_ingest_status, messages } = {
    ...status
  };

  const title = isIngestDetails
    ? `${t('full_ingest_details')} - Input ${currentIndex}`
    : isConfig
    ? t('modal_config_title')
    : t('log_messages');

  const getData = eventKey => {
    if (eventKey === checkRunningLogs) {
      return messages ? formateLogs(messages).join('\n') : 'No available logs';
    }

    let data;
    if (eventKey === checkIngestDetails) {
      data = ingest_status?.srt_stats || multi_ingest_status?.[currentIndex]?.srt_stats;
    }
    if (eventKey === checkConfig) data = content;
    if (eventKey === checkRunningConfig) data = config;
    return data ? JSON.stringify(data, null, 2) : 'no data';
  };

  const data = getData(eventKey);

  const lastestUpdate =
    content?.state_since && timeDifference(Date.now(), Date.parse(content?.state_since));

  const isJson = isConfig || isIngestDetails;

  const syntaxHighlighterOptions = {
    language: isJson ? 'json' : 'wiki',
    style: theme === 'dark' ? materialDark : materialLight,
    className: 'rounded w-100 m-0 ',
    codeTagProps: { className: 'small' },
    showLineNumbers: true
  };

  return (
    <section className='modal-background rounded dp-02 m-0 fade-in form-dialog d-flex flex-column position-absolute w-100 p-3 pb-0'>
      <Modal.Header className='position-relative d-block pb-0'>
        <CloseButton
          className='position-fixed top-0 end-0 m-3'
          variant={theme === 'dark' ? 'white' : null}
          onClick={onCancel}
        />
        <Modal.Title>
          <h2 className='h4 color-high'>{title}</h2>
          {!content?.state && (
            <h3 className='h6 color-primary m-0'>{config?.name || content?.name}</h3>
          )}
        </Modal.Title>
        {content?.state && (
          <small className='ms-auto color-medium d-flex align-items-center'>
            <RefreshIcon className='me-2' size={16} />
            <strong className='text-capitalize me-2'>
              {t('glossary:' + content?.state)}
            </strong>
            {`since ${lastestUpdate}`}
          </small>
        )}
      </Modal.Header>
      <Modal.Body className='form-scrollable d-flex h-100 px-0'>
        <CopyButton className='position-absolute end-0 m-2' value={data} />
        <SyntaxHighlighter {...syntaxHighlighterOptions}>{data}</SyntaxHighlighter>
      </Modal.Body>
    </section>
  );
};

export default LiveConfigDialog;
