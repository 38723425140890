import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setLiveProfiles, setVodProfiles } from 'store/user/userSlice';
import { sortData } from 'utils/utils';
import { getLocationTypeOption } from 'utils/vodUtils';
import { actionTypes } from 'constants/live';
import { VOD_TYPES } from 'constants/vod';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';
import CodeSection from 'components/CodeSection';
import Table from 'components/Table';
import CheckBox from 'components/Checkbox';
import ActionDropdown from 'components/ActionDropdown';
import Badge from 'components/Badge';

const ProfileModal = ({ data, show, onHide }) => {
  const {
    default_live_uuid,
    default_vod_uuid,
    settings: { theme }
  } = useSelector(({ user }) => user);
  const { uuid, checked, ...profile } = { ...data };
  const isDefault = uuid === default_live_uuid || uuid === default_vod_uuid;
  return (
    <Modal className={theme} size='lg' show={show} onHide={onHide} scrollable centered>
      <Modal.Header className='position-relative'>
        <CloseButton
          className='position-absolute top-0 end-0 m-3'
          variant={theme === 'dark' ? 'white' : null}
          onClick={onHide}
        />
        <Modal.Title className='color-high d-flex align-items-start gap-2 me-5'>
          {profile?.name}
          {isDefault && (
            <h6 className='my-auto'>
              <Badge.Line isDefault />
            </h6>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-sm pt-0'>
        <CodeSection data={profile} />
      </Modal.Body>
    </Modal>
  );
};

const SettingsTable = ({ eventKey, ...props }) => {
  const [profileConfig, setProfileConfig] = useState(null);

  const onClickName = profile => setProfileConfig(profile);

  const configs = { ...props, onClickName };
  return (
    <>
      {profileConfig && (
        <ProfileModal
          data={profileConfig}
          show={Boolean(profileConfig)}
          onHide={() => setProfileConfig(null)}
        />
      )}
      {eventKey === 'live' && <Live {...configs} />}
      {eventKey === 'vod' && <Vod {...configs} />}
    </>
  );
};

const Live = ({ onSelect, onClickName }) => {
  const { t } = useTranslation(['common', 'validation']);

  const dispatch = useDispatch();
  const { live_profiles, default_live_uuid } = useSelector(({ user }) => user);
  const [sortColumn, setSortColumn] = useState({ path: 'group', order: 'asc' });

  const handleSort = sortColumn => setSortColumn(sortColumn);

  const editableProfiles = live_profiles?.filter(({ group }) => !group);
  const editableCount = editableProfiles?.length;
  const checked = editableProfiles?.filter(({ checked }) => checked);
  const checkedCount = checked?.length;

  const checkboxColumn = live_profiles?.length
    ? {
        key: 'checkbox',
        checked: checkedCount && checkedCount === editableCount,
        content: profile => {
          const isCheckable =
            profile.key === 'checkbox' ? editableCount : !profile?.group;
          return (
            <CheckBox.Box
              className={isCheckable ? 'pe-pointer' : 'opacity-38'}
              checked={profile.checked}
              onClick={() => isCheckable && handleCheck(profile)}
            />
          );
        }
      }
    : { key: 'checkbox' };

  const columns = [
    checkboxColumn,
    { path: 'group', label: 'Group' },
    {
      path: 'name',
      label: `${t('profile')} (${live_profiles?.length || 0})`,
      content: profile => (
        <Button
          variant='link'
          className='link color-high text-start shadow-none ps-0'
          onClick={() => onClickName(profile)}
        >
          {profile.name}
          {default_live_uuid === profile?.uuid && (
            <Badge.Line className='ms-lg-2' isDefault />
          )}
        </Button>
      )
    },
    { path: 'customer_entrypoint', label: t('validation:customer_entrypoint') },
    { path: 'set_id', label: t('validation:Set ID') },
    {
      key: 'action',
      content: profile => (
        <ActionDropdown
          eventKeys={getEventKeys(profile, default_live_uuid)}
          onSelect={event => onSelect(event, profile)}
          disabled={profile.checked}
        />
      )
    }
  ];

  const handleCheck = profile => {
    let profiles = [...live_profiles];
    if (profile.key === 'checkbox') {
      profiles = profiles.map(item => ({
        ...item,
        checked: !item?.group && !profile.checked
      }));
    } else {
      const index = profiles?.findIndex(({ uuid }) => uuid === profile.uuid);
      profiles[index] = { ...profiles[index] };
      profiles[index].checked = !profiles[index].checked;
    }
    dispatch(setLiveProfiles(profiles));
  };

  return (
    <Table
      className='dp-01'
      data={sortData(live_profiles, sortColumn)}
      columns={columns}
      sortColumn={sortColumn}
      onSort={handleSort}
    />
  );
};

const Vod = ({ onSelect, onClickName }) => {
  const { t } = useTranslation(['glossary', 'validation']);

  const dispatch = useDispatch();
  const { hash } = useLocation();
  const { default_vod_uuid, vod_profiles } = useSelector(({ user }) => user);
  const [sortEncoderColumn, setSortEncoderColumn] = useState({
    path: 'group',
    order: 'asc'
  });
  const [sortSourceColumn, setSortSourceColumn] = useState({
    path: 'group',
    order: 'asc'
  });
  const [sortDestinationColumn, setSortDestinationColumn] = useState({
    path: 'group',
    order: 'asc'
  });
  const handleSortEncoders = sortColumn => setSortEncoderColumn(sortColumn);
  const handleSortSources = sortColumn => setSortSourceColumn(sortColumn);
  const handleSortDestinations = sortColumn => setSortDestinationColumn(sortColumn);

  const vodTableConfigs = {
    encoders: { sortColumn: sortEncoderColumn, onSort: handleSortEncoders },
    sources: { sortColumn: sortSourceColumn, onSort: handleSortSources },
    destinations: {
      sortColumn: sortDestinationColumn,
      onSort: handleSortDestinations
    }
  };

  const getNameColumn = key => ({
    path: 'name',
    label: `${t(`glossary:${key}`)} (${vod_profiles[key]?.length || 0})`,
    content: profile => (
      <Button
        variant='link'
        className='link color-high text-start shadow-none ps-0'
        onClick={() => onClickName(profile)}
      >
        {profile.name}
        {default_vod_uuid === profile?.uuid && (
          <Badge.Line className='ms-lg-2' isDefault />
        )}
      </Button>
    )
  });

  const getCheckboxColumn = (key, count) => {
    const checkedProfiles = vod_profiles[key]?.filter(({ checked }) => checked);
    const isTotal = count && checkedProfiles?.length === count;
    return {
      key: 'checkbox',
      checked: isTotal,
      content: profile => {
        const isCheckable = profile.key === 'checkbox' ? count : !profile?.group;
        return (
          <CheckBox.Box
            className={isCheckable ? 'pe-pointer' : 'opacity-38'}
            checked={profile.checked}
            onClick={() => isCheckable && handleCheck(key, profile)}
          />
        );
      }
    };
  };

  const populateVodColumns = (key, columns) => {
    const newCoulmns = [...columns];
    const editableCount = vod_profiles[key]?.filter(({ group }) => !group)?.length;

    newCoulmns?.unshift(
      getCheckboxColumn(key, editableCount),
      { path: 'group', label: 'Group' },
      getNameColumn(key, editableCount)
    );

    newCoulmns?.push({
      key: 'action',
      content: profile => (
        <ActionDropdown
          eventKeys={getEventKeys(profile, default_vod_uuid, key.slice(0, -1))}
          onSelect={event => onSelect(event, profile, key)}
          disabled={profile.checked}
        />
      )
    });
    return newCoulmns;
  };

  const getColumns = key => {
    let columns;
    if (key === 'encoders') {
      columns = [
        {
          path: 'customer_entrypoint',
          label: t('validation:customer_entrypoint')
        }
        // { path: ['notification', 'type'], label: t('validation:notification') }
      ];
    } else {
      columns = [
        {
          path: 'type',
          label: t('validation:type'),
          content: profile => (
            <Badge.Fill type={key}>{getLocationTypeOption(profile.type)}</Badge.Fill>
          )
        }
      ];
    }
    if (key === 'destinations') {
      columns.push({
        label: t('validation:Access base URL'),
        content: ({ access_url }) => (
          <div className='text-truncate' style={{ width: 200 }}>
            {access_url}
          </div>
        )
      });
    }
    return populateVodColumns(key, columns);
  };

  const handleCheck = (key, profile) => {
    const profiles = { ...vod_profiles };
    profiles[key] = [...profiles[key]];
    if (profile.key === 'checkbox') {
      profiles[key] = profiles[key].map(item => ({
        ...item,
        checked: !item?.group && !profile.checked
      }));
    } else {
      const index = profiles[key]?.findIndex(({ uuid }) => uuid === profile.uuid);
      profiles[key][index] = { ...profiles[key][index] };
      profiles[key][index].checked = !profiles[key][index].checked;
    }
    dispatch(setVodProfiles(profiles));
  };

  return (
    <section>
      {VOD_TYPES.map(key => {
        if (hash && key !== hash.slice(1)) return null;

        const configs = vodTableConfigs[key];
        const profiles = vod_profiles[key];
        const props = {
          key,
          data: profiles?.length ? sortData(profiles, configs.sortColumn) : [],
          columns: getColumns(key),
          ...configs
        };
        return <Table className='dp-01' {...props} responsive />;
      })}
    </section>
  );
};

const getEventKeys = (profile, defaultUUID, key) => {
  const eventKeys = profile?.group
    ? [{ eventKey: actionTypes.duplicate }]
    : [
        { eventKey: actionTypes.edit },
        { eventKey: actionTypes.duplicate },
        { eventKey: actionTypes.export },
        { eventKey: actionTypes.delete, danger: true }
      ];

  if (!key || key === 'encoder') {
    const isDisabled = defaultUUID === profile?.uuid;
    eventKeys.unshift({ eventKey: 'set_default', disabled: isDisabled });
  }

  return eventKeys;
};

export default SettingsTable;
