import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { getLiveConfigs, updateLiveConfig } from 'store/live/liveThunk';

import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import EmptyButton from 'components/EmptyButton';
import Badge from 'components/Badge';

const LiveSlateModal = ({ data, ...props }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { theme } = useSelector(({ user }) => user.settings);
  const { profile_config } = useSelector(({ live }) => live);
  const { stack_uuid, static_config, dynamic_config } = { ...data };

  const activations = dynamic_config?.runtime_parameters?.slate_settings?.activations;
  const name = static_config?.name || '-';
  // TODO: Output Slates are NOT yet supported for Mosaic
  const isMosaic = static_config?.encoding_hls?.length > 1;
  const slateTypes = isMosaic ? ['input'] : ['input', 'output'];

  const handleDeactivateAll = () => {
    const runtime_parameters = { ...dynamic_config.runtime_parameters };
    runtime_parameters['slate_settings'] = { activations: null };
    updateSlate(runtime_parameters);
  };

  const updateSlate = async runtime_parameters => {
    const response = await dispatch(updateLiveConfig({ runtime_parameters, stack_uuid }));

    if (response?.['meta']?.['requestStatus'] === 'fulfilled') {
      dispatch(getLiveConfigs(profile_config));
    }
  };

  return (
    <Modal
      className={theme}
      contentClassName='modal-background dp-02 py-4'
      size='lg'
      centered
      {...props}
    >
      <Modal.Header>
        <Modal.Title className='w-100 text-center'>
          <h1 className='h3 color-high'>{t('activate_slate')}</h1>
          <h2 className='h5 color-primary m-0 text-center'>{name}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='container px-5'>
        {slateTypes.map(type => {
          const props = { type, data, activations, updateSlate };
          return <SlateControl key={type} {...props} />;
        })}
      </Modal.Body>
      <Modal.Footer className='py-3 gap-4 mx-auto'>
        <EmptyButton onClick={props.onHide}>{t('cancel')}</EmptyButton>
        <Button
          variant={activations ? 'danger text-white' : 'primary'}
          onClick={handleDeactivateAll}
          disabled={!activations}
        >
          Deactivate all
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default LiveSlateModal;

const SlateControl = ({ type, data, activations, updateSlate }) => {
  const { t } = useTranslation();
  const { static_config, dynamic_config } = { ...data };

  const definitions = static_config?.advanced?.slate_settings?.definitions || [];
  const AllDefinitions = [{ name: 'None' }, ...definitions];

  const sources =
    type === 'input'
      ? static_config?.source?.multi_video || [0]
      : static_config?.egress || [static_config?.output];

  const key = type === 'input' ? 'input_filter' : 'egress_filter';

  const firstActivation = activations?.[0];
  const initialActive = {
    name: firstActivation?.name || definitions[0]?.['name'] || 'None',
    input_filter: firstActivation?.['input_filter']
      ? [firstActivation?.['input_filter']?.[0]]
      : [0],
    egress_filter: firstActivation?.['egress_filter']
      ? [firstActivation?.['egress_filter']?.[0]]
      : [0]
  };
  const [active, setActive] = useState(initialActive);

  const selectingActiveIndex = activations?.findIndex(activation =>
    activation?.[key]?.includes(active?.[key]?.[0])
  );
  const selectingActiveName = activations?.[selectingActiveIndex]?.name;
  const isSelectingActive = selectingActiveIndex !== -1;

  const isDeactivate = active['name'] === 'None';

  const handleSelect = ({ name, index }) => {
    const activation = { ...active };
    if (name) activation['name'] = name;
    else activation[key] = [index];
    setActive(activation);
  };

  const handleSubmit = active => {
    const runtime_parameters = { ...dynamic_config.runtime_parameters };
    const activation = { ...active };

    if (activation.name === 'None') {
      if (!activations) console.log(`${activation.name} is not using slate`);
      // const index = activations?.findIndex(({ egress_filter }) =>
      //   egress_filter?.includes(activation.egress_filter[0])
      // );
      if (isSelectingActive) {
        const updatedActivation = { ...activations?.[selectingActiveIndex] };
        updatedActivation[key] = updatedActivation?.[key]?.filter(
          index => index !== activation?.[key]?.[0]
        );
        // let activations = [];
        const updatedActivations = activations ? [...activations] : [];
        if (
          !updatedActivation?.['input_filter']?.length &&
          !updatedActivation?.['egress_filter']?.length
        ) {
          updatedActivations.splice(selectingActiveIndex, 1);
        } else updatedActivations[selectingActiveIndex] = updatedActivation;

        runtime_parameters['slate_settings'] = {
          activations: updatedActivations
        };
      } else console.log(`${activation.name} is not using slate`);
    } else {
      if (!activations) {
        if (type === 'input') delete activation['egress_filter'];
        else delete activation['input_filter'];
        runtime_parameters['slate_settings'] = { activations: [activation] };
      } else {
        const updatedActivations = [...activations];

        const slateIndex = activations?.findIndex(({ name }) => name === activation.name);

        if (slateIndex === -1) {
          updatedActivations.push(activation);
        } else {
          const updatedActivation = { ...activations?.[slateIndex] };
          const currentIndexes = updatedActivation?.[key] || [];
          const selectingIndex = activation?.[key]?.[0];

          updatedActivations[slateIndex] = {
            ...updatedActivation,
            [key]: [...currentIndexes, selectingIndex]
          };
        }
        runtime_parameters['slate_settings'] = {
          activations: updatedActivations
        };
      }
    }
    updateSlate(runtime_parameters);
  };

  return (
    <div className='row'>
      <div className='col-12 col-lg py-3'>
        <label
          htmlFor='dropdown-outputs'
          className='form-label color-medium text-capitalize'
        >
          {type}
        </label>
        <Dropdown>
          <Dropdown.Toggle id='dropdown-outputs' variant='light'>
            <span className='text-capitalize'>
              {`${type} ${active?.[key]?.[0]}`}
              {isSelectingActive && <SlateChip>{selectingActiveName}</SlateChip>}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className='w-100'>
            {sources?.map((_, index) => {
              const activeSource = activations?.find(activation =>
                activation?.[key]?.includes(index)
              );
              return (
                <Dropdown.Item
                  key={nanoid()}
                  className='color-high bg-hover'
                  onClick={() => handleSelect({ index })}
                >
                  <div className='d-flex align-items-center text-capitalize'>
                    {`${type} ${index}`}
                    {activeSource && <SlateChip>{activeSource?.name}</SlateChip>}
                  </div>
                  {/* <small className='color-medium d-block'>
                    {output?.access_url}
                  </small> */}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className='col-12 col-lg py-3'>
        <label htmlFor='dropdown-slates' className='form-label color-medium'>
          Slate name
        </label>
        <Dropdown>
          <Dropdown.Toggle id='dropdown-slates' variant='light'>
            {active?.name || ''}
          </Dropdown.Toggle>
          <Dropdown.Menu className='w-100'>
            {AllDefinitions?.map(({ name }) => {
              const isDisabled =
                selectingActiveName && isSelectingActive
                  ? name === selectingActiveName
                  : name === 'None';
              return (
                <Dropdown.Item
                  key={nanoid()}
                  className={`color-high bg-hover ${isDisabled ? 'opacity-38' : ''}`}
                  onClick={() => handleSelect({ name })}
                  disabled={isDisabled}
                >
                  {name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className='col-12 col-lg-auto h-auto align-self-lg-end py-3'>
        <Button
          className='w-100'
          style={{ minWidth: 112 }}
          variant={isDeactivate ? 'danger text-white' : 'primary'}
          onClick={() => handleSubmit(active)}
          disabled={
            isSelectingActive
              ? active?.name === selectingActiveName
              : active?.name === 'None'
          }
        >
          {isDeactivate ? t('deactivate') : t('activate')}
        </Button>
      </div>
    </div>
  );
};

const SlateChip = ({ children }) => (
  <Badge.Fill className='base-font text-xs ms-2' type='slate'>
    {children}
  </Badge.Fill>
);
