import {
  combineReducers,
  configureStore,
  getDefaultMiddleware
} from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userSlice from 'store/user/userSlice';
import liveSlice from 'store/live/liveSlice';
import live2vodSlice from 'store/live2vod/live2vodSlice';
import vodSlice from 'store/vod/vodSlice';
import modalSlice from 'store/modal/modalSlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['user', 'live', 'live2vod', 'vod', 'modal']
};

const userPersistConfig = {
  key: 'user',
  storage,
  whitelist: ['isAuthenticated', 'settings']
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userSlice),
  live: liveSlice,
  live2vod: live2vodSlice,
  vod: vodSlice,
  modal: modalSlice
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
});

export let persistor = persistStore(store);

export default store;
