import { useContext } from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Button from 'react-bootstrap/Button';
import ArrowIcon from 'remixicon-react/ArrowUpSLineIcon';

const ToggleButton = ({
  eventkey,
  onClick,
  hide,
  children,
  className = ''
}) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventkey, () => {
    onClick && onClick(eventkey);
  });

  const isCurrentEventKey = activeEventKey === eventkey;

  return (
    <Button
      variant='outline-primary'
      className={`${className} ${
        hide ? 'pe-none' : ''
      } d-flex align-items-center d-grid gap-2 bg-transparent border-0 p-0 pe-2 shadow-none`}
      onClick={decoratedOnClick}
    >
      <ArrowIcon
        className={`${
          hide ? 'opacity-0' : ''
        } color-medium sort-transition my-2 ${
          isCurrentEventKey ? 'desc-button' : 'asc-button'
        }`}
      />
      {children}
    </Button>
  );
};

export default ToggleButton;
