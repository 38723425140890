import { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { timeRanges, overviewGraphs, stateTypes } from 'constants/live';

import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Panel from 'components/Panel';
import LiveSubTitle from 'components/LiveSubTitle';
import LiveDetailsBlock from 'components/LiveDetailsBlock';
import LiveChartBlock from 'components/LiveChartBlock';
import LiveCarousel from 'components/LiveCarousel';
import StatusIndicator from 'components/StatusIndicator';

const LiveDetailsSection = ({
  token,
  stack_uuid,
  streamStatus,
  state_since,
  deployed_at,
  contents: { overviewContents, statusContents },
  onClick
}) => {
  const { t } = useTranslation(['common', 'glossary']);
  const [selectedRange, setSelectedRange] = useState(timeRanges[0]);
  const [isSnapshotAvailable, setIsSnapshotAvailable] = useState(false);
  const {
    live_details,
    snapshot: {
      index: { current: currentIndex }
    }
  } = useSelector(({ live }) => live);

  const getStartAt = selectedRange => {
    switch (selectedRange) {
      case 'reset':
        return Date.parse(state_since);
      case 'launch':
        return Date.parse(deployed_at);
      default:
        return selectedRange;
    }
  };

  const handleSelectedRange = timeRange => {
    setSelectedRange(timeRange);
  };

  const timeSelector = {
    timeRanges,
    selectedRange,
    onSelectedRange: handleSelectedRange
  };

  const renderPanel = ({ titleText, ...props }) => (
    <Panel
      key={props.title || nanoid()}
      stack_uuid={stack_uuid}
      startAt={getStartAt(selectedRange)}
      token={token}
      {...props}
    />
  );

  const renderTitleType = eventKey => {
    if (eventKey === 'snapshot') return t('common:snapshot');
    return t('common:live_status');
  };

  const renderCurrentIndex = (eventKey, index) => {
    switch (eventKey) {
      case 'ingest':
      case 'snapshot':
        const paddingZeroIndex = ('0' + index).slice(-2);
        return `${t('common:input')} ${paddingZeroIndex}`;
      default:
        return null;
    }
  };

  const renderTitle = (eventKey, index) => {
    const currentIndex = renderCurrentIndex(eventKey, index);
    const type = renderTitleType(eventKey);
    return (
      <h4 className='position-relative d-inline-block h6 color-medium m-0 lh-base'>
        {currentIndex ? `${type} - ` : type}
        {currentIndex && <strong className='color-high'>{currentIndex}</strong>}
      </h4>
    );
  };

  useEffect(() => {
    setIsSnapshotAvailable(live_details?.state === stateTypes.running);
  }, [live_details?.state]);

  return (
    <Tab.Content>
      <Tab.Pane eventKey='overview'>
        <div className='row d-flex gap-2'>
          <section className='col-12'>
            <LiveChartBlock {...timeSelector}>
              {overviewGraphs?.map(props =>
                renderPanel({ className: 'col-12 col-lg-6', ...props })
              )}
            </LiveChartBlock>
          </section>
          <section className='col-12 d-flex gap-2'>
            {overviewContents?.map(({ eventKey, data, charts }) => {
              const info = streamStatus && streamStatus[eventKey];
              return (
                <Fragment key={eventKey}>
                  {charts ? (
                    <LiveChartBlock
                      titleText={t('glossary:' + eventKey)}
                      {...timeSelector}
                    >
                      {charts?.map(chart => renderPanel(chart))}
                    </LiveChartBlock>
                  ) : (
                    <Card className='rounded bg-transparent border-line flex-grow-1 col-12 col-lg-6'>
                      <Card.Header className='underline'>
                        <h4 className='position-relative d-inline-block pe-3 h6 color-medium m-0 lh-base'>
                          {t('glossary:' + eventKey)}
                          <StatusIndicator status={info?.status} />
                        </h4>
                      </Card.Header>
                      {data ? (
                        data?.map(prop => (
                          <LiveDetailsBlock key={prop.title} {...prop} isOverview />
                        ))
                      ) : (
                        <Card.Body className='color-high'>No available data</Card.Body>
                      )}
                    </Card>
                  )}
                </Fragment>
              );
            })}
          </section>
        </div>
      </Tab.Pane>

      {statusContents?.map(({ eventKey, data, carousel, charts }) => {
        const { ingest_status, multi_ingest_status } = {
          ...live_details?.status
        };
        const ingest = ingest_status || multi_ingest_status?.[currentIndex];
        const srtDetails = ingest?.srt_stats;
        const carouselProps = {
          stack_uuid,
          timeSelector,
          renderPanel,
          renderTitle,
          isSnapshotAvailable
        };
        return (
          <Tab.Pane key={eventKey} eventKey={eventKey}>
            {carousel && <LiveCarousel {...carouselProps} />}
            {charts && (
              <LiveChartBlock
                className='d-flex flex-wrap'
                title={renderTitle(eventKey, currentIndex)}
                {...timeSelector}
              >
                {charts.map(props =>
                  renderPanel({ className: 'col col-lg-6', ...props })
                )}
              </LiveChartBlock>
            )}
            {data && (
              <section className='row mt-3'>
                {eventKey === 'ingest' && srtDetails && (
                  <LiveSubTitle
                    header={renderTitle(eventKey, currentIndex)}
                    button='see_ingest_details'
                    onClick={onClick}
                    disabled={!srtDetails?.srt_dynamic_stats}
                  />
                )}
                {data?.map(prop => (
                  <LiveDetailsBlock
                    key={prop.title + currentIndex}
                    className='col col-md-6 col-lg-4 flex-grow-0 fade-in'
                    {...prop}
                  />
                ))}
              </section>
            )}
          </Tab.Pane>
        );
      })}
    </Tab.Content>
  );
};

export default LiveDetailsSection;
