import { useDispatch, useSelector } from 'react-redux';
import { updateSettings } from 'store/user/userThunk';

import Button from 'react-bootstrap/Button';
import SunIcon from 'remixicon-react/SunLineIcon';
import MoonIcon from 'remixicon-react/MoonLineIcon';

const ThemeButton = ({ className = '' }) => {
  const dispatch = useDispatch();
  const theme = useSelector(({ user }) => user?.settings?.theme);
  const reversedTheme = theme === 'dark' ? 'light' : 'dark';

  return (
    <Button
      className={`${className} thumbnail-container border-0 p-0 d-flex justify-content-center align-items-center`}
      variant={reversedTheme}
      onClick={() => dispatch(updateSettings({ theme: reversedTheme }))}
      aria-label='change theme'
    >
      {theme === 'dark' ? (
        <SunIcon style={{ fill: 'black' }} />
      ) : (
        <MoonIcon style={{ fill: 'white' }} />
      )}
    </Button>
  );
};

export default ThemeButton;
