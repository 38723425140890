import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Trans, useTranslation } from 'react-i18next';
import { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { CancelToken } from 'services/httpService';
import {
  cancelVodEncode,
  getHistoryList,
  getJobList,
  getRunningJob
} from 'store/vod/vodThunk';
import { resetProfile, checkIsTM } from 'utils/utils';
import {
  mapToEncodeModal,
  renderListWithCopy,
  getDurationUntilNow,
  isZero
} from 'utils/vodUtils';

import { HISTORY_STATES, hasCopy } from 'constants/vod';
import { setVodProfile } from 'store/vod/vodSlice';
import { getVodEncode } from 'store/vod/vodThunk';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';

import EmptyButton from 'components/EmptyButton';
import ToggleButton from 'components/ToggleButton';
import Badge from 'components/Badge';
import CodeSection from 'components/CodeSection';
import LiveAccordion from 'components/LiveAccordion';
import VodTable from 'components/VodTable';
import CancelModal from 'components/CancelModal';
import Loader from 'components/Loader';

import TimerIcon from 'remixicon-react/TimerLineIcon';
import ActionIcon from 'remixicon-react/MoreFillIcon';
import StopIcon from 'remixicon-react/StopCircleLineIcon';
import FinishedIcon from 'remixicon-react/CheckLineIcon';
import AddIcon from 'remixicon-react/AddFillIcon';

const VodPage = () => {
  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  const { pathname } = useLocation();
  const { push } = useHistory();

  const dispatch = useDispatch();
  const { profile, job, loading_job, history } = useSelector(({ vod }) => vod);
  const { default_vod_uuid, vod_profiles, blueprints, groups, loading } = useSelector(
    ({ user }) => user
  );
  const [modal, setModal] = useState(null);
  const [showNewEncodeModal, setShowNewEncodeModal] = useState(false);
  const [currentEncode, setCurrentEncode] = useState(null);
  const [currentState, setCurrentState] = useState(HISTORY_STATES);
  const initialPage = {
    previous_page_token: '',
    next_page_token: '',
    page_size: 10,
    page_count: 1
  };
  const [historyPage, setHistoryPage] = useState(initialPage);

  const handleModal = data => setModal(data);
  const handleHide = () => setModal();
  const handleCancel = encode_uuid => {
    dispatch(cancelVodEncode(encode_uuid));
    setModal();
  };

  const parsePageAction = action => {
    const pageCount = historyPage['page_count'];
    const { previous_page_token, next_page_token } = { ...history };
    switch (action) {
      default:
      case 'back':
        return {
          previous_page_token: '',
          next_page_token: '',
          page_count: 1
        };
      case 'previous':
        const token = pageCount === 2 ? '' : previous_page_token;
        return {
          next_page_token: '',
          previous_page_token: token,
          page_count: pageCount - 1
        };
      case 'next':
        return {
          next_page_token,
          previous_page_token: '',
          page_count: pageCount + 1
        };
    }
  };

  const handlePageChange = props => {
    let pageState;
    if (typeof props === 'number') pageState = { page_size: props };
    else pageState = parsePageAction(props);
    setHistoryPage(prev => ({ ...prev, ...pageState }));
  };

  const handleStates = state => {
    const isAll = state === 'all';
    setCurrentState(isAll ? HISTORY_STATES : [state]);
  };

  const handleCurrentEncode = encode => setCurrentEncode(encode);

  const handleNewEncode = () => {
    setShowNewEncodeModal(true);
  };

  useEffect(() => {
    const initialProfile = profile
      ? vod_profiles?.encoders?.find(({ uuid }) => uuid === profile.uuid)
      : resetProfile({
          profiles: vod_profiles?.encoders,
          defaultProfile: default_vod_uuid
        });
    dispatch(setVodProfile(initialProfile));
  }, [vod_profiles?.encoders, profile?.uuid, default_vod_uuid]); // eslint-disable-line

  useEffect(() => {
    setHistoryPage(initialPage);
    setCurrentState(HISTORY_STATES);
  }, [profile?.uuid]); // eslint-disable-line

  useEffect(() => {
    if (profile) dispatch(getJobList(profile));
    if (loading) return;

    let source;
    const fetchRunningJobs = setInterval(() => {
      source = CancelToken.source();
      dispatch(getRunningJob(source));
    }, 5000);
    return () => {
      source?.cancel();
      clearInterval(fetchRunningJobs);
    };
  }, [profile?.uuid, loading]); // eslint-disable-line

  useEffect(() => {
    if (!profile || loading_job) return;

    const payload = { encode_states: currentState, ...historyPage };
    delete payload.page_count;

    const source = CancelToken.source();
    dispatch(getHistoryList({ ...payload, source }));
    return () => source?.cancel();
  }, [loading_job, job?.encodes?.length, currentState, historyPage]); // eslint-disable-line

  return (
    <main className='main-container live-container-scroll pt-4 pb-5'>
      {loading ? (
        <Loader fullscreen />
      ) : (
        <>
          <section className='position-relative'>
            <NewEncodeModal
              show={showNewEncodeModal}
              onHide={() => setShowNewEncodeModal(false)}
            />
            <CancelModal
              name='cancel_encode'
              data={modal}
              show={Boolean(modal)}
              onHide={handleHide}
              onConfirm={handleCancel}
            />
            <NewEncodeButton onClick={handleNewEncode} />
            <LiveAccordion
              eventkey='job'
              showBadge={job?.encodes?.length > 0}
              dataCount={job?.encodes?.length}
              className='position-relative'
            >
              <div className='row g-2 overflow-auto' style={{ maxHeight: 260 }}>
                {loading_job ? (
                  <Loader className='input-height' />
                ) : job?.encodes?.length ? (
                  job?.encodes.map(encode => (
                    <div
                      key={encode?.encode_uuid}
                      className='col-12 col-lg-6 col-xl-4 my-1 position-relative'
                    >
                      <VodCard
                        encode={encode}
                        onClick={() => handleCurrentEncode(encode)}
                      />
                      <DropdownButton
                        className='action-dropdown position-absolute top-0 end-0 m-2'
                        title={<ActionIcon className='color-medium' />}
                        renderMenuOnMount
                      >
                        <Dropdown.Item
                          className='danger text-danger'
                          onClick={() => handleModal(encode)}
                        >
                          <div className='icon-size'>
                            <StopIcon />
                          </div>
                          <h6 className='lh-base user-select-none my-auto mx-3'>
                            <Trans i18nKey='cancel_encode' />
                          </h6>
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  ))
                ) : (
                  <p className='color-medium'>
                    <Trans i18nKey='no_encoding_job' />
                  </p>
                )}
              </div>
            </LiveAccordion>
          </section>
          <LiveAccordion eventkey='history' className='my-3'>
            <EncodeModal
              encode={mapToEncodeModal({ encode: currentEncode, profile })}
              show={Boolean(currentEncode?.encode_uuid)}
              onHide={() => handleCurrentEncode(null)}
            />
            <VodTable
              onSelectEncode={handleCurrentEncode}
              currentState={currentState}
              onChangeState={handleStates}
              page={historyPage}
              onPageChange={handlePageChange}
            />
          </LiveAccordion>
        </>
      )}
    </main>
  );
};

export default VodPage;

const NewEncodeButton = ({ onClick }) => {
  const { profile } = useSelector(({ vod }) => vod);
  return (
    <Button
      variant='primary'
      className={`position-absolute top-0 end-0 z-1 shadow-none ${
        profile ? '' : 'pe-none'
      }`}
      onClick={onClick}
      disabled={!profile}
    >
      <span className='me-2'>
        <AddIcon />
      </span>
      <Trans i18nKey='new_encode' />
    </Button>
  );
};

const VodCard = ({ encode, onClick }) => {
  const { t } = useTranslation('glossary');
  const { state, name, started, estimated_finished, analysis_finished, progress_pct } = {
    ...encode
  };
  const timeStartedTimer = isZero(analysis_finished) ? started : analysis_finished;
  const [showTimer, setShowTimer] = useState(false);
  const [timer, setTimer] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);

  const [displayProgress, setDisplayProgress] = useState(0);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    setShowTimer(state === 'encoding' || state === 'finishing');
  }, [state]);

  useEffect(() => {
    if (progress_pct < 1) return;
    setIsComplete(progress_pct >= 100);

    const pct = progress_pct - 1; // make sure it does not go beyond 99%
    if (pct > 100) setDisplayProgress(100);
    else setDisplayProgress(prePct => (prePct > pct ? prePct : pct));
  }, [progress_pct]);

  useEffect(() => {
    if (isComplete) {
      setRemainingTime(`${t('finishing')}...`);
    } else {
      if (isZero(estimated_finished)) setRemainingTime(`${t('calculating')}...`);
      else setRemainingTime(dayjs(estimated_finished).add(1, 'm').fromNow()); // extra 1 min for clean-up
    }
  }, [isComplete, estimated_finished]); // eslint-disable-line

  useEffect(() => {
    if (isZero(timeStartedTimer)) return setTimer(null);
    const timer = setInterval(
      () => setTimer(getDurationUntilNow(timeStartedTimer)),
      1000
    );
    return () => clearInterval(timer);
  }, [timeStartedTimer]);

  return (
    <Card
      className='card-background border-line transition pe-pointer dp-01'
      onClick={() => onClick(encode)}
    >
      <Card.Body className='d-flex flex-column gap-2'>
        <div className='d-flex gap-2 me-5'>
          <h6 className='text-md text-break color-high lh-base m-0'>{name}</h6>
          <Badge.Fill className='text-xs align-self-start my-1' type={state}>
            {t(state)}
          </Badge.Fill>
        </div>
        <div
          className='d-flex justify-content-between align-items-center'
          style={{ height: '1.5rem' }}
        >
          {showTimer && (
            <>
              <OverlayTrigger
                placement='top'
                overlay={props => (
                  <Tooltip id='finish-at' {...props}>
                    Pass time since encoding
                  </Tooltip>
                )}
              >
                <div className='color-medium'>
                  <TimerIcon className='me-1' size={18} />
                  <small>{timer || '--:--:--'}</small>
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement='top'
                overlay={props => (
                  <Tooltip id='finish-at' {...props}>
                    {t('estimated_finish_time')}
                  </Tooltip>
                )}
              >
                <div className='color-medium'>
                  <FinishedIcon className='me-1' size={18} />
                  <small>{remainingTime}</small>
                </div>
              </OverlayTrigger>
            </>
          )}
        </div>
        <div className='d-flex justify-content-between align-items-center gap-2'>
          <small className='color-medium'>{`${Math.floor(displayProgress)}%`}</small>
          <ProgressBar className='w-100' now={displayProgress} />
        </div>
      </Card.Body>
    </Card>
  );
};

export const EncodeModal = ({ encode, show, onHide }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['glossary', 'validation']);
  const [showConfig, setShowConfig] = useState(false);

  const {
    groups,
    settings: { theme }
  } = useSelector(({ user }) => user);
  const { encode: config, loading } = useSelector(({ vod }) => vod);
  const { name, state, ...rest } = { ...encode };
  const uuid = encode?.contents?.encode_uuid;

  useEffect(() => {
    if (!showConfig) return;
    if (uuid) dispatch(getVodEncode(uuid));
  }, [showConfig, uuid]); // eslint-disable-line

  return (
    <Modal className={theme} size='xl' show={show} onHide={onHide} scrollable centered>
      {encode && (
        <>
          <Modal.Header className='position-relative'>
            <CloseButton
              className='position-absolute top-0 end-0 m-3'
              variant={theme === 'dark' ? 'white' : null}
              onClick={onHide}
            />
            <Modal.Title className='color-high d-flex align-items-start gap-2 me-5'>
              {name}
              <h6 className='text-capitalize my-2'>
                <Badge.Fill type={state}>{t(`glossary:${state}`)}</Badge.Fill>
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-sm pt-0'>
            <Table size='sm mb-0' borderless>
              {Object.keys(rest)?.map(type => (
                <Fragment key={type}>
                  <thead className='bg-transparent'>
                    {type !== 'contents' && (
                      <tr>
                        <th
                          scope='col'
                          colSpan='2'
                          className='h6 color-high text-capitalize align-middle'
                        >
                          <p className='pt-3 pb-2 m-0 underline'>
                            {t(`glossary:${type}`)}
                          </p>
                        </th>
                      </tr>
                    )}
                  </thead>
                  <tbody className='bg-transparent'>
                    {Object.keys(rest[type])?.map(key => {
                      const value = rest[type][key];
                      const text = hasCopy.includes(key)
                        ? renderListWithCopy(value)
                        : value;
                      return (
                        <tr key={key} className='color-medium lh-lg h-auto'>
                          <th scope='row' className='text-nowrap py-0'>
                            {t(`validation:${key}`)}
                          </th>
                          <td className='text-break py-0'>{text || '-'}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Fragment>
              ))}
            </Table>
            {checkIsTM(groups) && (
              <Accordion
                className='config-code-height mt-3'
                defaultActiveKey={showConfig}
              >
                <div className='d-flex align-item-center fade-in underline'>
                  <ToggleButton
                    eventkey='encode-config'
                    onClick={() => setShowConfig(true)}
                  >
                    <h5 className='h6 title-height color-high m-0'>
                      Encode configuration
                    </h5>
                  </ToggleButton>
                </div>
                <Accordion.Collapse eventKey='encode-config'>
                  {loading ? <Loader className='p-5' /> : <CodeSection data={config} />}
                </Accordion.Collapse>
              </Accordion>
            )}
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

const NewEncodeModal = ({ show, onHide }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { groups, blueprints, settings } = useSelector(({ user }) => user);
  const { theme } = { ...settings };

  const options = [
    {
      key: 'new',
      title: 'manual_setup',
      text: 'Configure everything manually'
    },
    {
      key: 'encode_config',
      title: 'use_config_json',
      text: 'Start with encode config json'
    }
  ];

  if (blueprints?.length > 0) {
    options.unshift({
      key: 'template',
      title: 'use_template',
      text: 'Encoder, Source and Destination locations are preconfigured'
    });
  }

  return (
    <Modal
      className={theme || 'light'}
      contentClassName='p-4 gap-4'
      show={show}
      onHide={onHide}
      size='lg'
      aria-labelledby='new-encode-modal'
      centered
    >
      <div>
        <h1 className='h4 fs-3 color-high'>{t('schedule_encode')}</h1>
        <p className='fs-6 color-medium m-0'>{t('schedule_encode_text')}</p>
      </div>
      <div>
        {options.map(({ key, title, text }) => (
          <Link key={key} className='text-decoration-none' to={`${pathname}/${key}`}>
            <ListGroup className='my-2 dp-01'>
              <ListGroup.Item variant={theme === 'dark' && theme} className='p-4' action>
                <div className='color-disabled'>
                  <div className='h4 color-high'>{t(title)}</div>
                  {text}
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Link>
        ))}
      </div>
      <EmptyButton className='ms-auto' onClick={onHide}>
        <Trans i18nKey='cancel' />
      </EmptyButton>
    </Modal>
  );
};
