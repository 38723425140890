import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, deleteModalContent } from 'store/modal/modalSlice';
import { Formik, Form, FieldArray } from 'formik';
import { LiveFormFields } from 'constants/forms';
import { initializeValues } from 'utils/utils';
import {
  setFisheyeOptions,
  showEventName,
  getConfigType,
  isPreMeshbox
} from 'utils/liveUtils';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Subtitle from 'components/VodSubtitle';
import ToggleButton from 'components/ToggleButton';
import TextField from 'components/TextField';
import { AccessUrlFields, GroupFields } from 'components/LiveFields';
import { ABRFields } from 'components/VodFields';
import Button from 'react-bootstrap/Button';
import EmptyButton from 'components/EmptyButton';
import Badge from 'components/Badge';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

const LiveConfigsForm = ({ onSubmit, onCancel, removeInactiveId }) => {
  const { t } = useTranslation(['common', 'validation']);

  const formRef = useRef();
  const target = useRef(null);
  const [applied, setApplied] = useState(false);
  const [activeEventKey, setActiveEventKey] = useState(0);
  const { schema, fields, touchedField } = LiveFormFields;
  const dispatch = useDispatch();
  const {
    groups,
    settings: { theme }
  } = useSelector(({ user }) => user);
  const modalContents = useSelector(({ modal }) => modal.content);

  const handleOpen = eventkey => {
    setActiveEventKey(eventkey);
  };

  useEffect(() => {
    if (formRef.current) {
      const touchedFields = modalContents.map(_ => touchedField);
      formRef.current.setTouched({ liveConfigs: touchedFields });
      formRef.current.validateForm();
    }
  }, []); // eslint-disable-line

  return (
    <Formik
      innerRef={formRef}
      validateOnMount
      validationSchema={schema.liveConfigs}
      initialValues={{ liveConfigs: initializeValues(modalContents) }}
      onSubmit={values => onSubmit(values.liveConfigs)}
      enableReinitialize
    >
      {({ values: { liveConfigs }, isValid }) => (
        <Form className='d-flex flex-column justify-content-between w-100'>
          <FieldArray name='liveConfigs'>
            {({ form: { setFieldValue, errors } }) => (
              <Accordion defaultActiveKey={0} className='form-scrollable px-1'>
                {liveConfigs.map((liveConfig, index) => {
                  const content = modalContents[index];
                  const isHLS = content?.type === 'hls';
                  const { static_config, dynamic_config } = { ...content };
                  const showSource = dynamic_config?.config_parameters?.source;
                  const output = static_config?.egress || [
                    static_config?.output
                  ];
                  const showEvent = showEventName(output);
                  const errorIndex = errors?.liveConfigs?.map((error, index) =>
                    error ? index : null
                  );
                  const fieldName = `liveConfigs.${index}`;
                  const bundleName =
                    'dynamic_config.runtime_parameters.bundles';

                  const {
                    dynamic_config: {
                      config_parameters: { event_name },
                      runtime_parameters: { fish_eye_params, bundles }
                    }
                  } = liveConfig;
                  const showApplyAll =
                    liveConfigs.filter(({ static_config }) => {
                      const { egress, output } = { ...static_config };
                      const o = egress || [output];
                      return o.map(i => showEventName(i));
                    })?.length > 1;
                  const disabledApplyAll =
                    !event_name?.toString()?.trim() || applied;
                  const handleApplyAll = () => {
                    liveConfigs.map((_, index) =>
                      setFieldValue(
                        `liveConfigs.${index}.${fields.event.name}`,
                        event_name
                      )
                    );
                    setApplied(true);
                    setTimeout(() => setApplied(false), 1500);
                  };

                  const handleDelete = () => {
                    removeInactiveId(liveConfig.config_uuid);
                    dispatch(deleteModalContent(index));
                    const count = liveConfigs.length;
                    if (count === 1) dispatch(closeModal());
                  };

                  const noInput =
                    isHLS &&
                    !showEvent &&
                    !showSource &&
                    !output.map(obj => obj.hasOwnProperty('access_url'))[0] &&
                    !fish_eye_params;

                  return (
                    <Card key={index} className='bg-transparent border-line'>
                      <Card.Header
                        className={`d-flex justify-content-between align-item-center ${
                          theme === 'dark' ? 'input-background' : ''
                        }`}
                      >
                        <ToggleButton
                          className='shadow-none'
                          eventkey={index}
                          onClick={handleOpen}
                          hide={noInput}
                        >
                          <h5 className='h6 color-high m-0 me-1 position-relative text-start'>
                            {static_config?.name}
                            {errorIndex?.includes(index) && (
                              <span className='position-absolute top-0 translate-middle-y rounded-circle bg-danger p-1 fade-in' />
                            )}
                            <Badge.Line className='ms-2'>
                              {getConfigType({ type: content?.type, groups })}
                            </Badge.Line>
                          </h5>
                        </ToggleButton>
                        <EmptyButton onClick={handleDelete} reversedStyles>
                          {t('remove')}
                        </EmptyButton>
                      </Card.Header>
                      {activeEventKey === index && !noInput && (
                        <Accordion.Collapse eventKey={index}>
                          <Card.Body className='pb-0'>
                            {showEvent && (
                              <div className='row'>
                                <TextField
                                  {...fields.event}
                                  className='col-xl-8 col-md-12'
                                  name={`${fieldName}.${fields.event.name}`}
                                >
                                  {showApplyAll && (
                                    <>
                                      <Button
                                        ref={target}
                                        variant='outline-primary rounded-end'
                                        onClick={handleApplyAll}
                                        disabled={disabledApplyAll}
                                      >
                                        {t('apply_all')}
                                      </Button>
                                      <Overlay
                                        target={target.current}
                                        show={applied}
                                        placement='top'
                                      >
                                        <Tooltip>Applied</Tooltip>
                                      </Overlay>
                                    </>
                                  )}
                                </TextField>
                              </div>
                            )}
                            {showSource && !isHLS && (
                              <div className='row'>
                                <TextField
                                  {...fields.source}
                                  name={`${fieldName}.${fields.source.name}`}
                                />
                              </div>
                            )}
                            <AccessUrlFields
                              output={output}
                              eventName={event_name}
                            />
                            <div className='d-flex flex-column flex-lg-row gap-4'>
                              {!isHLS && (
                                <TextField
                                  {...fields.sharpness}
                                  className='input-md'
                                  name={`${fieldName}.${fields.sharpness.name}`}
                                  autoFocus={!showEvent}
                                  helpMessage={fields.sharpness.helpMessage}
                                />
                              )}
                              {fish_eye_params && (
                                <TextField
                                  {...fields.fisheye}
                                  className='col'
                                  options={setFisheyeOptions(groups)}
                                  name={`${fieldName}.${fields.fisheye.name}`}
                                />
                              )}
                            </div>
                            {isPreMeshbox(content?.type) ? (
                              <GroupFields
                                fieldName={fieldName}
                                bundles={fields.bundles}
                              />
                            ) : (
                              <div
                                className={bundles?.length > 1 ? 'pb-2' : ''}
                              >
                                {bundles && (
                                  <FieldArray
                                    name={`${fieldName}.${bundleName}`}
                                  >
                                    <>
                                      <Subtitle
                                        className='mt-3'
                                        text={t(`validation:ABR_level`)}
                                        size='sm'
                                      />
                                      <ABRFields
                                        name={`${fieldName}.${bundleName}`}
                                        bundles={bundles}
                                      />
                                    </>
                                  </FieldArray>
                                )}
                              </div>
                            )}
                          </Card.Body>
                        </Accordion.Collapse>
                      )}
                    </Card>
                  );
                })}
              </Accordion>
            )}
          </FieldArray>
          <Modal.Footer className='d-flex justify-content-between p-0 mt-4'>
            <span className='ms-auto'>
              <EmptyButton onClick={onCancel}>{t('cancel')}</EmptyButton>
              <Button
                type='submit'
                variant='primary'
                className='border-0 ms-4'
                disabled={!isValid}
              >
                {modalContents.length > 1 ? t('launch_all') : t('launch')}
              </Button>
            </span>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

export default LiveConfigsForm;
