import { useTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import TimeRangeDropdown from 'components/TimeRangeDropdown';

const LiveChartBlock = ({
  timeRanges,
  selectedRange,
  onSelectedRange,
  title,
  titleText,
  className = '',
  children
}) => {
  const { t } = useTranslation();
  return (
    <Card className='rounded w-100 bg-transparent border-line'>
      <Card.Header className='d-flex flex-wrap justify-content-between align-items-center gap-2 underline'>
        {title || (
          <h4 className='position-relative d-inline-block h6 color-medium m-0 lh-base'>
            {t(titleText || 'live_status')}
          </h4>
        )}
        <TimeRangeDropdown
          className='d-flex'
          timeRanges={timeRanges}
          selectedRange={selectedRange}
          onSelectedRange={onSelectedRange}
        />
      </Card.Header>
      <section className='container p-2 bg-grafana h-100 rounded-bottom overflow-hidden'>
        <div className={`row g-2 ${className}`}>{children}</div>
      </section>
    </Card>
  );
};

export default LiveChartBlock;
