import { useTranslation } from 'react-i18next';
import { capitalizeString } from 'utils/utils';
import { showErrorIndicator } from 'utils/liveUtils';

import Nav from 'react-bootstrap/Nav';
import StatusIndicator from 'components/StatusIndicator';
import RefreshIcon from 'remixicon-react/RefreshLineIcon';

const LiveDetailsNavs = ({ navItems, streamStatus, activeKey, lastestUpdate }) => {
  const { t } = useTranslation(['common', 'glossary']);
  return (
    <Nav className='state-tabs underline fw-bolder'>
      {navItems.map(item => {
        const isSelected = activeKey === item;
        const info = streamStatus && streamStatus[item];
        const { status } = { ...info };
        const showError = isSelected && showErrorIndicator(status);
        return (
          <Nav.Item key={item} className='position-relative pe-pointer'>
            <Nav.Link
              eventKey={item}
              className={showError ? 'text-danger border-danger' : ''}
            >
              {capitalizeString(t('glossary:' + item))}
            </Nav.Link>
            <StatusIndicator className='my-2' status={status} />
          </Nav.Item>
        );
      })}
      <small className='text-xs ms-auto color-medium text-lowercase d-flex align-items-center'>
        {`${lastestUpdate} ${t('second_ago')}`}
        <RefreshIcon className='ms-2' size={16} />
      </small>
    </Nav>
  );
};

export default LiveDetailsNavs;
