import { useRef, useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';

import { capitalizeString } from 'utils/utils';
import {
  mapToAllBundles,
  populateTemplateValuesWithBlueprintName,
  removeNoValueFromPayload,
  parseNotification,
  setHttpAndNoOutputValues
} from 'utils/vodUtils';
import { FisheyeProjectionTypes } from 'constants/live';
import { VOD_URL_TYPES } from 'constants/vod';
import { scheduleVodEncode } from 'store/vod/vodThunk';
import {
  VodStartFormConfigs as Manual,
  VodTemplateFormConfigs as Template,
  VodEncodeConfigFormConfigs as EncodeConfig
} from 'constants/vodForm';

import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

import { FormSection } from 'components/VodFormSection';
import OverviewSection from 'components/VodOverviewSection';
import EmptyButton from 'components/EmptyButton';
import CancelModal from 'components/CancelModal';
import TextField from 'components/TextField';
import Loader from 'components/Loader';

import NextIcon from 'remixicon-react/ArrowRightLineIcon';
import BackIcon from 'remixicon-react/ArrowLeftLineIcon';

const StartEncodePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { push } = useHistory();
  const { pathname } = useLocation();
  const { lng, encodeType } = useParams();

  const [encode, setEncode] = useState(null);
  const [showOverview, setShowOverview] = useState(false);

  const isManual = encodeType === 'new';
  const isTemplate = encodeType === 'template';
  const showNav = !showOverview && isManual;

  const [errorCount, setErrorCount] = useState(null);
  const [showErrorChip, setShowErrorChip] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [timestamp] = useState(dayjs().format('YYYYMMDD_HHmmss'));

  const {
    loading,
    username,
    blueprints,
    vod_profiles: { sources, destinations }
  } = useSelector(({ user }) => user);
  const { profile } = useSelector(({ vod }) => vod);

  const hideOverview = () => setShowOverview(false);

  const handleErrorCount = errorCount => {
    if (showErrorChip) setErrorCount(errorCount);
  };

  const handleShowErrorChip = () => setShowErrorChip(true);

  const handleCancel = () => push(`/${lng}/vod`);

  const handleNext = values => {
    const encode = { ...values };
    const {
      source: { video, multi_video }
    } = { ...values };

    encode['notification'] = parseNotification(values?.notification);

    if (video) encode.source.video.src_file = video?.src_file.trim();
    if (multi_video) {
      encode.source.multi_video = multi_video?.map(({ src_file, ...rest }) => ({
        ...rest,
        src_file: src_file.trim()
      }));
    }

    setEncode(encode);
    push(pathname);
    setShowOverview(true);
  };

  const handleStart = () => {
    const payload = { ...encode };
    const { source } = { ...encode };

    if (isTemplate) {
      const { multi_video } = { ...source };
      payload.source.multi_video = multi_video?.map(video => parseVideoValues(video));
    } else {
      const {
        ingest: { uuid: source_uuid },
        video
      } = { ...source };

      const { encoding_v3, output: initialOutput } = payload;

      const ingest =
        source_uuid === 'http-pull'
          ? { type: source_uuid }
          : { ...sources.find(({ uuid }) => uuid === source_uuid) };
      delete ingest.uuid;
      delete ingest.name;
      payload.source.ingest = ingest;
      payload.source.video = parseVideoValues(video);

      const output = setHttpAndNoOutputValues(initialOutput) || {
        ...destinations.find(({ uuid }) => uuid === initialOutput.uuid)
      };
      delete output.uuid;
      delete output.name;
      delete output.force_single_part_byte_range;

      payload.egress = [output];
      delete payload.output;

      const bundles = encoding_v3[0].video.bundles;
      payload.encoding_v3[0].video.bundles = mapToAllBundles(bundles);
    }
    payload['executor'] = username;

    removeNoValueFromPayload(payload);
    dispatch(scheduleVodEncode(payload));
    handleCancel();
    hideOverview();
  };

  const parseVideoValues = video => {
    const parsedVideo = { ...video };
    if (!FisheyeProjectionTypes.includes(video?.projection_type)) {
      delete parsedVideo?.fish_eye_params;
    }
    return { ...parsedVideo, src_file: parsedVideo?.src_file?.trim() };
  };

  const selectTemplate = ({ target: select }) => {
    const name = select.value;
    const result = blueprints.find(({ blueprint_name }) => blueprint_name === name);
    const templateValues = populateTemplateValuesWithBlueprintName({
      blueprint: result,
      profile
    });
    setEncode(templateValues);
  };

  const renderEncodeForm = () => {
    if (showOverview) {
      return (
        <>
          <OverviewSection data={encode} />
          <StartButtonGroup onStart={handleStart} onHide={hideOverview} />
        </>
      );
    }
    switch (encodeType) {
      case 'template':
        if (!encode) return null;
        return (
          <EncodeTemplateForm
            data={encode}
            timestamp={timestamp}
            onNext={handleNext}
            onSelect={selectTemplate}
          />
        );
      case 'encode_config':
        return <EncodeTemplateForm onCancel={handleCancel} />;
      case 'new':
      default:
        return (
          <EncodeForm
            data={encode}
            timestamp={timestamp}
            onNext={handleNext}
            onErrorCount={handleErrorCount}
            onShowErrorChip={handleShowErrorChip}
          />
        );
    }
  };

  useEffect(() => {
    if (loading) return;

    const populateInitialValues = type => {
      if (type !== 'template') return null;
      return populateTemplateValuesWithBlueprintName({
        blueprint: blueprints?.[0],
        profile
      });
    };

    setEncode(populateInitialValues(encodeType));
  }, [loading, encodeType, blueprints, profile]);

  return (
    <main className='main-container'>
      {loading ? (
        <Loader fullscreen />
      ) : (
        <section className='row fade-in'>
          <CancelModal
            show={showModal}
            onHide={() => setShowModal(false)}
            onConfirm={handleCancel}
          />
          {showNav && <EncodeNav errors={errorCount} />}
          <div
            className={`live-container-scroll col pt-0 pt-lg-4 pb-5 ${
              !showNav ? 'pt-3' : ''
            } ${encodeType === 'encode_config' ? 'h-auto' : ''}`}
          >
            <span className='d-flex justify-content-between align-items-center mb-4'>
              <span className='d-flex justify-content-between align-items-center gap-4'>
                <h1 className='h3 color-high m-0'>
                  {t(showOverview ? 'encode_overview' : 'new_encode')}
                </h1>
              </span>
              <EmptyButton className='shadow-none' onClick={() => setShowModal(true)}>
                {t('cancel')}
              </EmptyButton>
            </span>
            {renderEncodeForm()}
          </div>
        </section>
      )}
    </main>
  );
};

export default StartEncodePage;

const EncodeForm = ({ data, timestamp, onNext, onErrorCount, onShowErrorChip }) => {
  const { profile } = useSelector(({ vod }) => vod);
  const INITIAL_VALUES = {
    name: '',
    event_name: '',
    comment: '',
    drm: { type: 'none', name: '', override_content_id: '' },
    advanced: { accept_input_failures: false },
    source: {
      ingest: { uuid: '' },
      video: {
        src_file: '', // source
        projection_type: '', // source
        fish_eye_params: { camera_lens_enum: '' } // source
      }
    },
    encoding_v3: [
      {
        packaging_format: 'tm32-manifest-v7',
        video: {
          bundles: [
            {
              constant: 'bitrate',
              preset: 'default',
              custom_bitrate: 512,
              optimized_for_decoder_level: 'hevc.5.1', // target decoders
              down_conversion_factor: 0, // encoder (adv) framerate conversion
              target_fov_in_degrees: {
                v_fov_in_deg: 0,
                h_fov_in_deg: 0
              },
              scale_factor: 0
            }
          ],
          gaussian_sharpness_filter: 0 // encoder - sharpness
        },
        auto_add_stereo_downmix: false,
        auto_audio_kbps_per_channel: 64
      }
    ],
    output: {
      uuid: '',
      folder: '',
      file_size_limit: null,
      url: ''
    },
    notification: parseNotification(profile?.notification)
  };

  const props = { onErrorCount };
  return (
    <Formik
      initialValues={data || INITIAL_VALUES}
      validationSchema={Manual.schema}
      onSubmit={onNext}
    >
      <Form>
        <section className='d-grid gap-3'>
          <FormSection type='source' {...props} />
          <FormSection type='encoder' {...props} />
          <FormSection type='destination' timestamp={timestamp} {...props} />
        </section>
        <NextButton onClick={onShowErrorChip} />
      </Form>
    </Formik>
  );
};

const EncodeTemplateForm = ({ data, timestamp, onNext, onSelect, onCancel }) => {
  const { t } = useTranslation();
  const focusRef = useRef(null);
  const dispatch = useDispatch();
  const isEncodeConfig = Boolean(onCancel);

  useEffect(() => {
    if (isEncodeConfig) focusRef.current.focus();
  }, [isEncodeConfig]);

  if (isEncodeConfig) {
    const handleStart = ({ encode_config = '' }, { setFieldError }) => {
      try {
        const payload = JSON.parse(encode_config);
        if (payload?.encoding_hls && payload?.template_hls) {
          delete payload.encoding_hls;
        }
        if (payload?.encoding_v3?.[0]) {
          const { audio, ...rest } = { ...payload?.encoding_v3?.[0] };
          payload['encoding_v3'] = [rest];
        }
        dispatch(scheduleVodEncode(payload));
        onCancel();
      } catch (error) {
        setFieldError('encode_config', error.message);
      }
    };
    return (
      <Formik onSubmit={handleStart} enableReinitialize {...EncodeConfig}>
        <Form>
          <TextField
            focusref={focusRef}
            name='encode_config'
            size='sm'
            rows={24}
            // label='Encode configuration'
            validation
            placeholder='Enter encode_config here...'
            helpMessage='Use different "event_name" to prevent overwriting encode'
          />
          <Button type='submit' variant='primary' className='shadow-none float-end'>
            {t('start')}
          </Button>
        </Form>
      </Formik>
    );
  }
  const { schemaWithName, schema } = Template;
  const requireVideoName = data?.template_hls?.find(
    ({ video_template_source_mapping: video }) => video?.mapping_type?.includes('by-name')
  );
  const isRequireVideoName = Boolean(requireVideoName);
  return (
    <Formik
      initialValues={data}
      validationSchema={isRequireVideoName ? schemaWithName : schema}
      onSubmit={onNext}
      enableReinitialize
    >
      <Form>
        <section className='d-grid gap-3'>
          <FormSection type='template' onSelect={onSelect} />
          <FormSection type='source' />
          <FormSection type='destination' timestamp={timestamp} />
        </section>
        <NextButton />
      </Form>
    </Formik>
  );
};

const EncodeNav = ({ errors }) => (
  <div className='col-12 col-lg-2 col-xxxl-3 mb-lg-0 py-3 pb-lg-0 fade-in'>
    <Nav variant='pills' className='flex-lg-column' defaultActiveKey={VOD_URL_TYPES[0]}>
      {VOD_URL_TYPES.map(eventKey => (
        <Nav.Item key={eventKey}>
          <Nav.Link
            eventKey={eventKey}
            className='color-primary d-flex justify-content-between gap-2 gap-lg-0'
            href={'#' + eventKey}
          >
            {capitalizeString(eventKey)}
            {errors?.[eventKey] > 0 && (
              <span className='badge rounded-pill bg-danger align-self-center pop-up'>
                {errors?.[eventKey]}
              </span>
            )}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  </div>
);

const NextButton = props => {
  const { t } = useTranslation();
  return (
    <Button
      type='submit'
      variant='outline-primary'
      className='shadow-none mt-4 gap-3'
      {...props}
    >
      {t('next')} <NextIcon />
    </Button>
  );
};

const StartButtonGroup = ({ onHide, onStart }) => {
  const { t } = useTranslation();
  return (
    <div className='d-flex justify-content-between'>
      <Button
        variant='outline-primary'
        className='shadow-none mt-4 gap-3'
        onClick={onHide}
      >
        <BackIcon /> {t('back')}
      </Button>
      <Button variant='primary' className='shadow-none mt-4 gap-3' onClick={onStart}>
        {t('start')}
      </Button>
    </div>
  );
};
