import { withRouter } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Header from 'components/Header';
import SideNavbar from 'components/SideNavbar';

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const isSnapshotURL = pathname?.includes('snapshot');
  const { theme = 'light' } = useSelector(({ user }) => user.settings);
  return (
    <Container className={`${theme} g-0`} fluid>
      {isSnapshotURL ? (
        children
      ) : (
        <div
          className='row flex-nowrap g-0 main-background overflow-hidden'
          style={{ minHeight: '100vh' }}
        >
          <SideNavbar />
          <section className='col d-flex flex-column'>
            <Header />
            {children}
          </section>
        </div>
      )}
    </Container>
  );
};

export default withRouter(Layout);
