import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { openModal } from 'store/modal/modalSlice';
import { setAuthenticated, setFirstLogin, clearError } from 'store/user/userSlice';
import {
  loginUser,
  setNewUserPassword,
  forgotUserPassword,
  resetUserPassword
} from 'store/user/userThunk';

import Button from 'react-bootstrap/Button';
import TextField from 'components/TextField';
import EmptyButton from 'components/EmptyButton';

const LoginForm = ({ eventKey, onCancel, className = '' }) => {
  const dispatch = useDispatch();
  const { username, isFirstLogin } = useSelector(({ user }) => user);
  const { t } = useTranslation();
  let cognitoUser;

  const requiredField = Yup.string().required('required_field');
  const schema_username = requiredField
    .test('must_username', 'must_username', value => !value?.includes('@'))
    .matches(/^[aA-zZ]+$/, 'must_not_contain_spaces');
  const confirm_password = requiredField.oneOf(
    [Yup.ref('new_password'), null],
    'match_password'
  );

  const loginSchema = isFirstLogin
    ? { new_password: requiredField, confirm_password }
    : { username: schema_username, password: requiredField };

  const validationSchema = {
    login: Yup.object().shape(loginSchema),
    forgot: Yup.object().shape({ username: schema_username }),
    reset: Yup.object().shape({
      code: Yup.number()
        .typeError('must_be_number')
        .integer()
        .positive()
        .required('required_field'),
      new_password: requiredField,
      confirm_password
    })
  };

  const initialValues = {
    login: isFirstLogin
      ? { new_password: '', confirm_password: '' }
      : { username: '', password: '' },
    forgot: { username: '' },
    reset: { code: '', new_password: '', confirm_password: '' }
  };

  const fields = {
    login: isFirstLogin
      ? [
          { name: 'new_password', type: 'password' },
          { name: 'confirm_password', type: 'password' }
        ]
      : [
          { name: 'username' },
          { name: 'password', type: 'password', autoComplete: 'new-password' }
        ],
    forgot: [
      {
        name: 'username',
        label: 'username_reset',
        placeholder: 'username_placeholder'
        // helpMessage: 'send_code',
      }
    ],
    reset: [
      { name: 'code', placeholder: 'enter_code' },
      { name: 'new_password', type: 'password', autoComplete: 'new-password' },
      { name: 'confirm_password', type: 'password' }
    ]
  };

  const login = async credentials => {
    const response = await dispatch(loginUser(credentials));
    if (response?.isFirstLogin) dispatch(setFirstLogin(response));
    if (response?.payload) dispatch(setAuthenticated());
  };

  const changePassword = ({ new_password: newPassword }) => {
    dispatch(setNewUserPassword({ cognitoUser, username, newPassword }));
  };

  const handleSubmit = async values => {
    let response;
    switch (eventKey) {
      default:
      case 'login':
        return isFirstLogin ? changePassword(values) : login(values);
      case 'forgot':
        response = await dispatch(forgotUserPassword(values));
        if (response.meta.requestStatus === 'fulfilled') {
          dispatch(openModal('reset'));
        }
        break;
      case 'reset':
        response = await dispatch(resetUserPassword(values));
        if (response.meta.requestStatus === 'fulfilled') {
          const { new_password } = values;
          const credentials = { username, password: new_password };
          toast.success('Password changed successfully. Login user...', {
            autoClose: 1800
          });
          login(credentials);
        }
        break;
    }
  };

  const handleClick = () => {
    switch (eventKey) {
      default:
      case 'login':
        dispatch(clearError());
        dispatch(openModal('forgot'));
        break;
      case 'forgot':
      case 'reset':
        return onCancel();
    }
  };

  const renderButtonText = eventKey => {
    switch (eventKey) {
      default:
      case 'login':
        return isFirstLogin ? t('change_password') : t('login');
      case 'forgot':
        return t('next');
      case 'reset':
        return t('confirm');
    }
  };

  return (
    <Formik
      initialValues={initialValues[eventKey]}
      validationSchema={validationSchema[eventKey]}
      onSubmit={handleSubmit}
    >
      {({ dirty, isValid }) => (
        <Form className={className}>
          {fields[eventKey].map(field => (
            <TextField
              key={field.name}
              label={field.label || field.name}
              validation
              {...field}
            />
          ))}
          <div className='row justify-content-between mt-4 g-0'>
            {!isFirstLogin && (
              <EmptyButton className='col-auto' onClick={handleClick}>
                {eventKey === 'login' ? t('forgot_password') + '?' : t('cancel')}
              </EmptyButton>
            )}
            <Button
              type='submit'
              variant='primary'
              className={`col-auto float-end ${isFirstLogin ? '' : 'px-5'}`}
              disabled={!dirty || !isValid}
            >
              {renderButtonText(eventKey)}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
