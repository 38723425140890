import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'services/authService';
import { clearAuthenticated } from 'store/user/userSlice';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearAuthenticated());
    logout();
  }, [dispatch]);

  return null;
};

export default Logout;
