import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
  materialLight,
  materialDark
} from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useSelector } from 'react-redux';
import CopyButton from 'components/CopyButton';

const CodeSection = ({ data }) => {
  const { theme } = useSelector(({ user }) => user.settings);

  const config = data ? JSON.stringify(data, null, 2) : 'No data';

  const syntaxHighlighterOptions = {
    language: 'json',
    style: theme === 'dark' ? materialDark : materialLight,
    className: 'rounded m-0',
    codeTagProps: { className: 'small' },
    showLineNumbers: true,
    wrapLongLines: false
  };

  return (
    <>
      <CopyButton className='position-absolute end-0 m-2 me-4' value={config} />
      <SyntaxHighlighter {...syntaxHighlighterOptions}>{config}</SyntaxHighlighter>
    </>
  );
};

export default CodeSection;
