import { useState, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
  materialLight,
  materialDark
} from 'react-syntax-highlighter/dist/esm/styles/prism';

import { capitalizeString } from 'utils/utils';
import {
  mapToSourceOverview,
  mapToEncoderOverview,
  mapToDestinationOverview,
  mapToSourceTemplate,
  mapToDestinationTemplate,
  setHttpAndNoOutputValues
} from 'utils/vodUtils';
import { hasCopy } from 'constants/vod';

import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import MultiSourceTable from 'components/Table';
import CloseButton from 'react-bootstrap/CloseButton';
import EmptyButton from 'components/EmptyButton';
import CopyButton from 'components/CopyButton';
import { Container } from 'components/VodFormSection';
import OpenModalIcon from 'remixicon-react/ShareBoxLineIcon';

//   start: onClick = null
// restart: onClick
const VodOverviewSection = ({ data, onClick }) => {
  const { t } = useTranslation(['common', 'validation', 'glossary']);

  const { vod_profiles } = useSelector(({ user }) => user);
  const [showABRlevelModal, setShowABRlevelModal] = useState(false);

  const handleClose = () => setShowABRlevelModal(false);
  const parseString = string => capitalizeString(string?.replaceAll(/_/g, ' '));
  const mapABRlevelToList = abr => {
    const { optimized_for_decoder_level, constant, preset, custom_bitrate } = {
      ...abr
    };
    const values = {
      'Target decoder': optimized_for_decoder_level,
      constant,
      preset
    };
    if (custom_bitrate) values['custom_bitrate'] = custom_bitrate;
    return values;
  };

  const { pathname } = useLocation();
  const isRestart = !pathname.endsWith('new');
  const parsedData = isRestart
    ? mapToRestartOverview(data)
    : mapToStartOverview(data, vod_profiles);

  const showABRlevelButton = key => isRestart && key === 'ABR_level';

  const getColumns = values => {
    const hasFisheyeLens = values.find(value => value['Fisheye Camera-lens']);
    const columns = [
      { path: 'Source name', label: 'Name', thClasses: 'text-sm' },
      {
        path: 'Source URL',
        label: 'Source URL',
        thClasses: 'text-sm',
        tdClasses: 'text-break'
      },
      { path: 'projection_type', label: 'Projection type', thClasses: 'text-sm ' }
    ];
    if (hasFisheyeLens) {
      columns.push({
        path: 'Fisheye Camera-lens',
        label: 'Fisheye Camera-lens',
        thClasses: 'text-sm '
      });
    }
    return columns;
  };

  return (
    <Container>
      {isRestart && parsedData?.['encoder'] && (
        <AbrConfigModal
          show={showABRlevelModal}
          onHide={handleClose}
          parsedData={parsedData}
        />
      )}
      {data ? (
        <Table className='text-sm m-0' size='sm' borderless>
          {Object.keys(parsedData).map((type, index) => {
            const value = parsedData[type];
            if (!value) return null;

            const keys = Object.keys(value);
            const titleStyles = `d-flex justify-content-between align-items-center underline ${
              index ? 'pt-2' : 'pt-0'
            }`;
            return (
              <Fragment key={type + 'overview'}>
                <thead className='bg-transparent'>
                  <tr className='h-auto'>
                    <th
                      scope='col'
                      colSpan='2'
                      className='h5 color-high text-capitalize align-middle'
                    >
                      <div className={titleStyles}>
                        <p className='m-0'>{t(`glossary:${type}`)}</p>
                        <EmptyButton
                          className={onClick && type !== 'template' ? '' : 'invisible'}
                          onClick={() => onClick(type)}
                        >
                          {t('edit')}
                        </EmptyButton>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody key={nanoid()} className='bg-transparent'>
                  {keys?.map(key => (
                    <tr key={key} className='color-medium lh-lg h-auto'>
                      <th
                        scope='row'
                        className={`text-nowrap pe-3 ${
                          Array.isArray(value[key]) ? 'py-1' : 'py-0'
                        }`}
                      >
                        {t(`validation:${key}`)}
                        {showABRlevelButton(key) && (
                          <EmptyButton
                            className='p-0 ps-2 shadow-none'
                            onClick={() => setShowABRlevelModal(true)}
                          >
                            <OpenModalIcon size={18} />
                          </EmptyButton>
                        )}
                      </th>
                      {Array.isArray(value[key]) ? (
                        key === 'Source' ? (
                          <td>
                            <div
                              className='table-responsive overflow-auto'
                              style={{ maxHeight: '45vh' }}
                            >
                              <MultiSourceTable
                                className='video-table source-table underline'
                                data={value[key]}
                                columns={getColumns(value[key])}
                                hover={false}
                                size='sm'
                              />
                            </div>
                          </td>
                        ) : (
                          <td className={`row ${key === 'location' ? '' : 'g-4'}`}>
                            {value[key].map((abr, i) => {
                              if (abr?.content) return abr.content;

                              const isSource = abr.hasOwnProperty('Source name');
                              const isABR = key === 'ABR_level';
                              const title = isSource ? 'Source' : 'Level';
                              const showTitle =
                                isSource || (isABR && value[key].length > 1);

                              let obj;
                              if (!isABR) {
                                obj = { ...abr };
                                if (isSource) delete obj['Source name'];
                              } else obj = mapABRlevelToList(abr);

                              return (
                                <div key={i} className='col-6'>
                                  {showTitle && (
                                    <strong className='h6 lh-lg d-block color-primary underline'>
                                      {abr['Source name'] || `${title} ${i + 1}`}
                                    </strong>
                                  )}
                                  {Object.keys(obj)?.map(abrKey => (
                                    <p key={i + abrKey} className='d-block m-0'>
                                      <strong className='text-break'>{`${parseString(
                                        abrKey
                                      )}: `}</strong>
                                      <span className='text-break'>
                                        {abrKey === 'Source URL'
                                          ? obj[abrKey]
                                          : parseString(obj[abrKey].toString())}
                                      </span>
                                    </p>
                                  ))}
                                </div>
                              );
                            })}
                          </td>
                        )
                      ) : (
                        <td className='text-break py-0'>
                          {typeof value[key] === 'string'
                            ? value[key] || '-'
                            : value[key]}
                          {hasCopy.includes(key) && (
                            <CopyButton
                              className={`border-0 h-100 py-0 shadow-none bg-transparent ${
                                value[key] ? 'opacity-100' : 'opacity-0'
                              }`}
                              size={18}
                              value={value[key]}
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Fragment>
            );
          })}
        </Table>
      ) : (
        <p className='color-high my-2'>No available data</p>
      )}
    </Container>
  );
};

const AbrConfigModal = ({ show, onHide, parsedData }) => {
  const { theme } = useSelector(({ user }) => user.settings);

  const syntaxHighlighterOptions = {
    language: 'json',
    style: theme === 'dark' ? materialDark : materialLight,
    className: 'rounded w-100 m-0 ps-0',
    codeTagProps: { className: 'small' },
    showLineNumbers: true,
    wrapLongLines: true
  };
  return (
    <Modal size='xl' className={theme} show={show} onHide={onHide} centered scrollable>
      <Modal.Header className='position-relative d-block'>
        <CloseButton
          className='position-absolute top-0 end-0 m-3'
          variant={theme === 'dark' ? 'white' : null}
          onClick={onHide}
        />
        <Modal.Title>
          <h2 className='h3 color-high'>ABR level configuration</h2>
          <h3 className='h5 color-primary m-0'>
            {parsedData['destination']['encode name']}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='h-100'>
        <SyntaxHighlighter {...syntaxHighlighterOptions}>
          {JSON.stringify(parsedData['encoder']['ABR_level'], null, 2)}
        </SyntaxHighlighter>
      </Modal.Body>
    </Modal>
  );
};

const mapToRestartOverview = encode => {
  if (!encode) return;

  const isTemplate = encode?.template_hls;
  return isTemplate
    ? {
        template: { temp_name: encode?.blueprint_name },
        source: mapToSourceTemplate(encode?.source),
        destination: mapToDestinationTemplate(encode)
      }
    : {
        source: mapToSourceOverview(encode?.source),
        encoder: mapToEncoderOverview(encode),
        destination: mapToDestinationOverview(encode)
      };
};

const mapToStartOverview = (encode, profiles) => {
  if (!encode) return;

  const isTemplate = encode?.template_hls;
  if (isTemplate) {
    const { blueprint_name, source } = { ...encode };
    return {
      template: { template: blueprint_name },
      source: mapToSourceTemplate(source),
      destination: mapToDestinationTemplate(encode)
    };
  }

  const { sources, destinations } = profiles;
  const parsedEncode = { ...encode };
  const {
    source: { ingest },
    output
  } = { ...encode };

  parsedEncode.source.ingest =
    ingest.uuid === 'http-pull'
      ? { uuid: ingest.uuid, type: ingest.uuid }
      : { ...sources.find(({ uuid }) => uuid === ingest.uuid) };

  parsedEncode.output = setHttpAndNoOutputValues(output) || {
    ...destinations.find(({ uuid }) => uuid === output.uuid)
  };

  return {
    source: mapToSourceOverview(parsedEncode.source),
    encoder: mapToEncoderOverview(parsedEncode),
    destination: mapToDestinationOverview(parsedEncode)
  };
};

export default VodOverviewSection;
