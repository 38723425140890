import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { removeChartConfig, setChartConfigs, setAllPanels } from 'store/live/liveSlice';
import { openModal } from 'store/modal/modalSlice';
import { actionTypes, timeRanges } from 'constants/live';
import { getCurrentStatusPanel, isCurrentStatusPanel } from 'utils/liveUtils';

import useToken from 'hooks/useToken';
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';
import EmptyButton from 'components/EmptyButton';
import LiveChartModal from 'components/LiveChartModal';
import TimeRangeDropdown from 'components/TimeRangeDropdown';
import Panel from 'components/Panel';

const LiveChartSection = ({ show, activeTranscoders, onCancel }) => {
  const [selectedRange, setSelectedRange] = useState(timeRanges[0]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { profile_config, charts } = useSelector(({ live }) => live);
  const showChart = charts?.panels?.length > 0;

  const handleOpenAddChart = () => {
    dispatch(openModal(actionTypes.addChart));
  };

  const handleSelectedRange = timeRange => {
    setSelectedRange(timeRange);
  };

  const resetCharts = useCallback(() => {
    setSelectedRange(timeRanges[0]);
    dispatch(setAllPanels([]));
    dispatch(setChartConfigs(activeTranscoders));
  }, [activeTranscoders?.length]); // eslint-disable-line

  useEffect(() => {
    resetCharts();
  }, [profile_config?.uuid, resetCharts]);

  if (!show) return null;
  return (
    <section className={`${showChart ? 'col' : 'col-lg-6 col-12'} my-4`}>
      <LiveChartModal showChart={showChart} onCancel={onCancel} />
      <div className='d-flex align-item-center mb-3'>
        <div className='d-flex'>
          <h5 className='color-high m-0 title-height'>{t('live_status')}</h5>
          {showChart && (
            <TimeRangeDropdown
              className='my-auto ms-4 fade-in'
              timeRanges={timeRanges}
              selectedRange={selectedRange}
              onSelectedRange={handleSelectedRange}
            />
          )}
        </div>
        <EmptyButton
          className='ms-auto shadow-none'
          onClick={handleOpenAddChart}
          reversedStyles
        >
          {t('add_chart')}
        </EmptyButton>
      </div>
      {showChart ? (
        <Charts selectedRange={selectedRange} resetCharts={resetCharts} />
      ) : (
        <AddChartMessage text={t('modal_addChart_text')} />
      )}
    </section>
  );
};

const Charts = ({ selectedRange, resetCharts }) => {
  const {
    charts: { panels, configs },
    live_configs
  } = useSelector(({ live }) => live);
  const theme = useSelector(({ user }) => user.settings?.theme);
  const dispatch = useDispatch();
  const token = useToken();

  const handleClose = stack_uuid => {
    const index = configs.findIndex(config => config.stack_uuid === stack_uuid);
    if (index !== -1) dispatch(removeChartConfig(index));
  };

  useEffect(() => {
    if (configs.length && panels.length) return;
    resetCharts();
  }, [configs.length, panels.length, resetCharts]);

  return (
    <div className='row g-3'>
      {configs?.map(
        ({ name, stack_uuid, deployed_at, state_since, inputcount, outputcount }) => (
          <div key={stack_uuid} className={configs.length > 1 ? 'col-lg-6' : 'col'}>
            <Card className='bg-transparent border-line dp-01 fade-in'>
              <Card.Header className='underline row g-0'>
                <h6 className='col color-high m-0 lh-base pe-2'>{name}</h6>
                <CloseButton
                  className='col-auto'
                  variant={theme === 'dark' ? 'white' : null}
                  onClick={() => handleClose(stack_uuid)}
                />
              </Card.Header>
              <Card.Body className='bg-grafana rounded p-2 d-grid gap-3'>
                {panels?.map(({ id, containerclass, maintext, ...props }) => {
                  const isHLS =
                    live_configs?.find(config => config?.stack_uuid === stack_uuid)
                      ?.type === 'hls';

                  const getStartAt = selectedRange => {
                    switch (selectedRange) {
                      case 'reset':
                        return Date.parse(state_since);
                      case 'launch':
                        return Date.parse(deployed_at);
                      default:
                        return selectedRange;
                    }
                  };

                  const config = {
                    token,
                    stack_uuid,
                    inputcount,
                    outputcount,
                    startAt: getStartAt(selectedRange),
                    panelId: getCurrentStatusPanel({ id, isHLS }),
                    ...props,
                    height: isCurrentStatusPanel(id) ? 120 : 240,
                    className: 'w-100' // overwrite props className
                  };
                  return <Panel key={config?.panelId + stack_uuid} {...config} />;
                })}
              </Card.Body>
            </Card>
          </div>
        )
      )}
    </div>
  );
};

const AddChartMessage = ({ text }) => (
  <div className='position-relative'>
    <div className='z-0 mw-100 w-100 card-background border-line rounded'>
      <div className='arrow position-absolute top-0 end-0 border-line border-bottom-0 border-end-0 card-background' />
      <div className='popover-body p-5'>
        <h6 className='my-5 lh-base base-font color-disabled fw-normal text-center'>
          {text}
        </h6>
      </div>
    </div>
  </div>
);

export default LiveChartSection;
