import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['common', 'validation', 'glossary'],
    defaultNS: 'common',
    fallbackLng: 'en',
    supportedLngs: ['en', 'zh'],
    detection: {
      order: ['path', 'localStorage', 'subdomain'],
      lookupFromPathIndex: 1
    },
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    initImmediate: false
  });

export default i18n;
