import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import ProfileDropdown from 'components/ProfileDropdown';
import ThemeButton from 'components/ThemeButton';
import LanguageSelect from 'components/LanguageSelect';
import LogoutIcon from 'remixicon-react/LogoutBoxLineIcon';

const Header = ({ className = '' }) => {
  const { pathname } = useLocation();
  const user = useSelector(({ user }) => user);
  const showHeader = pathname?.split('/').length < 5 && pathname !== '/not-found';
  const isLoggedin = user.isAuthenticated && !pathname?.includes('start');
  const headerClasses = `d-grid gap-4 d-flex align-items-center justify-content-between ${className}`;

  if (!showHeader) return null;
  if (!isLoggedin) {
    return (
      <header className={headerClasses}>
        <LanguageSelect className='ms-auto' />
      </header>
    );
  }
  return (
    <header className={`${headerClasses} underline`}>
      <div className='col col-lg-4 d-flex align-items-center px-0'>
        <Image
          className='lazy-load logo-icon align-self-start me-2'
          width={40}
          height={40}
          src={`${process.env.REACT_APP_COCKPIT_CDN}/logos/logo-icon.svg`}
          alt='Tiledmedia logo'
        />
        <ProfileDropdown />
      </div>
      <div className='d-flex col w-100 px-0 justify-content-end d-grid gap-3'>
        <LanguageSelect />
        <ThemeButton />
        <UserDropdown data={user} />
      </div>
    </header>
  );
};

const Thumbnail = ({ username }) => (
  <span className='thumbnail-container thumbnail-background rounded d-flex justify-content-center align-items-center'>
    <h5 className='m-0 text-uppercase color-primary'>
      {username ? username.slice(0, 1) : ''}
    </h5>
  </span>
);

const UserDropdown = ({ data: { username, email = null } }) => {
  const { t } = useTranslation(['common', 'validation']);
  const theme = useSelector(({ user }) => user.settings?.theme);
  const items = [
    { label: 'username', value: username },
    { label: 'email', value: email },
    { label: 'version', value: process.env.REACT_APP_GIT_VER }
  ];
  return (
    <Dropdown className='action-dropdown' align='end'>
      <Dropdown.Toggle className='p-0 d-flex align-items-center color-high'>
        <Thumbnail username={username} />
      </Dropdown.Toggle>
      <Dropdown.Menu variant={theme} className='dp-02' renderOnMount>
        {items.map(({ label, ...rest }) => (
          <Item key={label} label={t(`validation:${label}`)} {...rest} />
        ))}
        <Dropdown.Divider />
        <Dropdown.Item as={Link} to='/logout' className='color-medium'>
          <LogoutIcon className='me-2' /> {t('logout')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const Item = ({ label, value }) => {
  if (!value) return null;
  return (
    <Dropdown.Item className='d-block pe-4 pe-none'>
      <h6 className='color-high mb-1'>{label}</h6>
      <p className='base-font fw-normal color-disabled m-0'>{value}</p>
    </Dropdown.Item>
  );
};

export default Header;
