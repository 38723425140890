import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import i18n from 'config/i18next';
import Image from 'react-bootstrap/Image';

const NotFoundPage = () => {
  const { goBack, location, push } = useHistory();
  const { state } = { ...location };
  const isSnapshot = state === 'no_snapshot';

  const handleRedirect = () => {
    if (state === 'no_blueprint') push(`${i18n.language}/vod`);
    else goBack();
  };

  useEffect(() => {
    if (isSnapshot) return;
    const redirect = setTimeout(handleRedirect, 3000);
    return () => clearTimeout(redirect);
  }, []); // eslint-disable-line

  return (
    <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
      <Image
        className='w-25 lazy-load'
        src='https://i.imgur.com/A040Lxr.png'
        style={{ maxWidth: '20rem' }}
      />
      <h1 className='h4 color-high m-3'>Oops! Page does not exist</h1>
      {!isSnapshot && <h2 className='h5 color-disabled fw-normal'>Redirecting...</h2>}
    </div>
  );
};

export default NotFoundPage;
