import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken } from 'services/httpService';
import { resetProfile } from 'utils/utils';
import { getLiveConfigs, getRunningConfigs } from 'store/live/liveThunk';
import { setProfile } from 'store/live/liveSlice';
// import usePrevious from 'hooks/usePrevious';
import Live from 'components/Live';

const LivePage = () => {
  const [activeTranscoders, setActiveTranscoders] = useState([]);
  const [inactiveTranscoders, setInactiveTranscoders] = useState([]);
  // const previousInactiveCount = usePrevious(inactiveTranscoders.length);

  const dispatch = useDispatch();
  const {
    default_live_uuid: defaultProfile,
    live_profiles: liveProfiles,
    loading: userLoading
  } = useSelector(({ user }) => user);
  const {
    profile_config: profileConfig,
    live_configs: liveConfigs,
    loading: liveLoading
  } = useSelector(({ live }) => live);

  useEffect(() => {
    if (profileConfig) return;
    // if (!liveProfiles) return dispatch(setLiveLoading(false));

    // dispatch(setLiveLoading(true));
    const newProfile = resetProfile({
      profiles: liveProfiles,
      defaultProfile
    });
    dispatch(setProfile(newProfile));
  }, [liveProfiles, profileConfig?.uuid, defaultProfile]); // eslint-disable-line

  useEffect(() => {
    if (!profileConfig) return;
    dispatch(getLiveConfigs(profileConfig));

    let source;
    const getRunningLiveConfigs = () => {
      source = CancelToken.source();
      dispatch(getRunningConfigs({ source, ...profileConfig }));
    };
    const fetchRunningConfigs = setInterval(getRunningLiveConfigs, 5000);

    return () => {
      source?.cancel();
      clearInterval(fetchRunningConfigs);
    };
  }, [profileConfig?.uuid]); // eslint-disable-line

  useEffect(() => {
    const activeTemp = [];
    const inactiveTemp = [];

    if (liveConfigs) {
      liveConfigs?.filter(config =>
        config.state === 'inactive' ? inactiveTemp.push(config) : activeTemp.push(config)
      );
    }
    setActiveTranscoders(activeTemp);
    // if (previousInactiveCount !== inactiveTemp.length) {
    setInactiveTranscoders(inactiveTemp);
    // }
  }, [liveConfigs]);

  return (
    <Live
      activeTranscoders={activeTranscoders}
      inactiveTranscoders={inactiveTranscoders}
      loading={userLoading || liveLoading}
      fullscreen
    />
  );
};

export default LivePage;
