import i18n from 'config/i18next';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';

import Login from 'components/Login';

const LoginPage = () => {
  const { isAuthenticated, loading } = useSelector(({ user }) => user);

  if (isAuthenticated) return <Redirect to={`/${i18n.language}/live`} />;
  return <Login loading={loading} fullscreen />;
};

export default LoginPage;
