const languages = [
  {
    code: 'en',
    name: 'English'
  },
  {
    code: 'zh',
    name: '繁體中文'
  }
];

export default languages;
