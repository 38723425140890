import { useTranslation } from 'react-i18next';
import EmptyButton from 'components/EmptyButton';

const LiveSubTitle = ({ header, title, button, onClick, disabled, size }) => {
  const { t } = useTranslation();
  return (
    <div className='d-flex justify-content-between align-items-center underline py-1 mb-2'>
      {header || (
        <h5 className={`color-high d-inline-block m-0 ${size || ''}`}>{t(title)}</h5>
      )}
      <EmptyButton onClick={onClick} disabled={disabled}>
        {t(button)}
      </EmptyButton>
    </div>
  );
};
export default LiveSubTitle;
