import { useState } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { setLiveProfiles, setVodProfiles } from 'store/user/userSlice';
import {
  deleteLiveProfile,
  deleteVodProfile,
  importLiveProfile,
  importVodProfile,
  updateUser
} from 'store/user/userThunk';
import { openModal, closeModal } from 'store/modal/modalSlice';
import { clearProfile } from 'store/live/liveSlice';
import { downloadFile, trimmedSpace, getToday } from 'utils/utils';
import { actionTypes } from 'constants/live';
import { VOD_PLATFORMS, VOD_TYPES } from 'constants/vod';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import ProfileToolbar from 'components/ProfileToolbar';
import SettingsTable from 'components/SettingsTable';
import Modal from 'components/Modal';
import WithLoader from 'components/WithLoader';

const Settings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'glossary']);
  const { push } = useHistory();
  const { hash } = useLocation();
  const { url } = useRouteMatch();

  const params = useParams();
  const { lng, platform = 'live' } = params;
  const isLive = platform === 'live';

  const user = useSelector(({ user }) => user);
  const { live_profiles, vod_profiles } = { ...user };
  const { profile_config } = useSelector(({ live }) => live);

  const [activeProfile, setActiveProfile] = useState(null);

  const getCheckedNames = data =>
    data?.filter(({ checked }) => checked).map(({ name }) => name);

  const subtitles = Array.isArray(activeProfile)
    ? activeProfile?.map(({ name }) => name)
    : isLive
    ? getCheckedNames(live_profiles)
    : []
        .concat(...VOD_TYPES.map(key => getCheckedNames(vod_profiles[key])))
        .filter(name => name);

  const handleActiveProfile = profile => {
    setActiveProfile([profile]);
  };

  let fileReader;

  const handleFileRead = event => {
    try {
      const file = JSON.parse(fileReader.result);
      const validKeys = ['live_profiles', 'vod_profiles'];
      const hasInvalidKey =
        Object.keys(file)
          .map(key => !validKeys.includes(key))
          .filter(result => result).length > 0;
      if (hasInvalidKey) {
        const errorMessage = 'Wrong profile format';
        toast.error(errorMessage, { toastId: errorMessage, autoClose: false });
      } else setActiveProfile(file);
    } catch (error) {
      const errorMessage = 'Please select a json file';
      toast.error(errorMessage, { toastId: errorMessage, autoClose: false });
    }
  };

  const handleChange = ({ target: input }) => {
    if (!input.files?.length) return;
    const file = input.files[0];
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  const handleImport = async profile => {
    const { live_profiles, vod_profiles } = profile;
    if (live_profiles) {
      // in case user imports group profile
      const profilesWithIsEditable = live_profiles.map(
        ({ group, ...profile }) => profile
      );
      const response = await dispatch(importLiveProfile(profilesWithIsEditable));
      if (response['meta']['requestStatus'] === 'fulfilled') {
        handleHideModal();
      }
    }
    if (vod_profiles) {
      // in case user imports group profile
      const profilesWithIsEditable = {
        encoders: vod_profiles?.encoders?.map(({ group, ...encoder }) => encoder),
        sources: vod_profiles?.sources?.map(({ group, ...source }) => source),
        destinations: vod_profiles?.destinations?.map(
          ({ group, ...destination }) => destination
        )
      };
      const response = await dispatch(importVodProfile(profilesWithIsEditable));
      if (response['meta']['requestStatus'] === 'fulfilled') {
        handleHideModal();
      }
    }
  };

  const getCheckedUUID = profiles => {
    const checkedUUIDs = [];
    Object.keys(profiles).map(key =>
      profiles[key].map(({ checked, uuid }) => checked && checkedUUIDs.push(uuid))
    );
    return checkedUUIDs;
  };

  const handleDelete = async data => {
    let deleteUUIDs;
    if (data) {
      deleteUUIDs = data?.map(({ uuid }) => uuid);
    } else {
      deleteUUIDs = isLive
        ? live_profiles?.map(({ checked, uuid }) => checked && uuid)
        : getCheckedUUID(vod_profiles);
    }
    if (isLive) {
      const isDeleteLastSelectedProfile = deleteUUIDs?.includes(profile_config?.uuid);
      if (isDeleteLastSelectedProfile) dispatch(clearProfile());
    }
    const response = isLive
      ? await dispatch(deleteLiveProfile(deleteUUIDs))
      : await dispatch(deleteVodProfile(deleteUUIDs));
    if (response['meta']['requestStatus'] === 'fulfilled') {
      handleHideModal();
    }
  };

  const getProfilesWithoutChecked = data =>
    data?.map(({ checked, ...profile }) => profile);

  const handleReset = () => {
    if (isLive) {
      const payload = getProfilesWithoutChecked(live_profiles);
      dispatch(setLiveProfiles(payload));
    } else {
      const payload = {};
      Object.keys(vod_profiles).map(
        key => (payload[key] = getProfilesWithoutChecked(vod_profiles[key]))
      );
      dispatch(setVodProfiles(payload));
    }
  };

  const handleOpenModal = modalName => {
    dispatch(openModal(modalName));
  };

  const handleHideModal = () => {
    dispatch(closeModal());
  };

  const handleCancel = () => {
    handleHideModal();
    handleReset();
  };

  const handleSelect = eventKey => {
    push(`/${lng}/settings/${eventKey}`);
    handleReset();
  };

  const { edit, duplicate, export: exportProfile, delete: deleteProfile } = actionTypes;

  const populateFileName = name => {
    const fileName = name ? trimmedSpace(name) : `${platform || ''}Profile`;
    return `${getToday()}_${fileName}.json`;
  };

  const handleSelectAction = (eventKey, profile, type) => {
    // type: (encoders|sources|destinations) vod table only
    switch (eventKey) {
      case 'set_default':
        const { isAuthenticated, isFirstLogin, error, loading, ...data } = {
          ...user
        };
        const key = `default_${platform}_uuid`;
        const payload = { ...data, [key]: profile.uuid };
        return dispatch(updateUser(payload));
      case edit:
      case duplicate:
        const vodType = type ? type.slice(0, -1) + '/' : '';
        return push(`${url}/${vodType}${profile.uuid}/${eventKey}`);
      case exportProfile:
        const { checked, uuid, group, ...rest } = profile;
        const exportedData = {
          [`${platform}_profiles`]: type ? { [type]: [rest] } : [rest]
        };
        return downloadFile({
          data: JSON.stringify(exportedData),
          fileName: populateFileName(rest?.name),
          fileType: 'text/json'
        });
      case deleteProfile:
        dispatch(openModal(eventKey));
        return handleActiveProfile(profile);
      default:
        break;
    }
  };

  return (
    <main className='main-container'>
      <Modal
        name='delete'
        data={activeProfile}
        subtitles={subtitles}
        onConfirm={handleDelete}
        onCancel={handleCancel}
      />
      <Modal
        name='import'
        data={activeProfile}
        onChange={handleChange}
        onConfirm={handleImport}
        onCancel={handleCancel}
      />
      <Tab.Container defaultActiveKey={platform} onSelect={handleSelect} unmountOnExit>
        <Row>
          <Col sm={12} lg={3} className='pt-4 mb-3 mb-lg-0'>
            <Nav variant='pills' className='flex-lg-column'>
              {VOD_PLATFORMS.map(eventKey => (
                <Nav.Item key={eventKey}>
                  <Nav.Link
                    eventKey={eventKey}
                    className='color-primary pe-pointer'
                    active={!hash && platform === eventKey}
                  >
                    {`${t('glossary:' + eventKey)} ${t('profiles')}`}
                  </Nav.Link>
                </Nav.Item>
              ))}
              <Nav className='d-none d-lg-flex flex-column'>
                {VOD_TYPES.map(item => (
                  <Nav.Item key={item} className='ms-3'>
                    <Nav.Link
                      eventKey='vod'
                      href={`#${item}`}
                      active={hash === `#${item}`}
                      className='text-capitalize'
                    >
                      {t(`glossary:${item}`)}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Nav>
          </Col>
          <Col className='live-container-scroll' sm={12} lg={9}>
            <Tab.Content className='pt-0 pt-lg-4 pb-5'>
              {VOD_PLATFORMS.map(eventKey => (
                <Tab.Pane key={eventKey} eventKey={eventKey}>
                  <ProfileToolbar
                    params={params}
                    onReset={handleReset}
                    onOpenModal={handleOpenModal}
                  />
                  <SettingsTable eventKey={eventKey} onSelect={handleSelectAction} />
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </main>
  );
};

export default WithLoader(Settings);
