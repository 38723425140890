import { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser, getUser, getBlueprints } from 'store/user/userThunk';

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  const { isFirstLogin, isAuthenticated } = useSelector(({ user }) => user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isFirstLogin) return;
    dispatch(getCurrentUser());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isAuthenticated) return;
    dispatch(getUser());
    dispatch(getBlueprints());
  }, [isAuthenticated, dispatch]);

  return (
    <Route
      render={props => {
        if (!isAuthenticated) {
          return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
        }
        return Component ? <Component {...props} /> : render(props);
      }}
      {...rest}
    />
  );
};

export default ProtectedRoute;
