import { useEffect, useCallback } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal, clearModalContent } from 'store/modal/modalSlice';

import Settings from 'components/Settings';

const SettingsPage = () => {
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const { lng, platform } = useParams();

  const dispatch = useDispatch();
  const loading = useSelector(({ user }) => user.loading);
  const { name, content } = useSelector(({ modal }) => modal);

  const clearModal = useCallback(() => {
    if (name) dispatch(closeModal());
    if (content) dispatch(clearModalContent());
  }, [name, content, dispatch]);

  useEffect(() => {
    clearModal();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!platform) push(`/${lng}/settings/live`);
  }, [url]); // eslint-disable-line

  return <Settings loading={loading} fullscreen />;
};

export default SettingsPage;
