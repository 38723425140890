import axios from 'axios';
import { getJwt, logout } from 'services/authService';
import { toast } from 'react-toastify';

const setExpectedError = error => {
  const expectedError =
    error.response && error.response.status >= 400 && error.response.status < 500;

  return expectedError;
};

const setNetworkError = error =>
  error === 'Network Error' || error === 'timeout of 0ms exceeded';

/**
 * Cockpit backend API
 */
const instance = axios.create({
  baseURL: process.env.REACT_APP_COCKPIT_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(
  async config => {
    config.headers['x-api-key'] = process.env.REACT_APP_COCKPIT_X_API_KEY;

    const token = await getJwt();
    if (token) config.headers['Auth'] = token;
    return config;
  },
  error => {
    Promise.reject(error);
    console.error('INTERCEPTOR REQUEST: ', error.response);
  }
);

instance.interceptors.response.use(null, error => {
  console.error('INTERCEPTOR RESPONSE: ', error);
  // error.response = {
  //   data: { message: 'error message' },
  //   status: 401,
  //   headers: {},
  //   config: {}
  // };

  const expectedError = setExpectedError(error);

  if (!expectedError) {
    const message = 'An unexpected error occurrred';
    toast.error(message, {
      toastId: message,
      autoClose: false,
      hideProgressBar: true,
      className: 'text-break'
    });
    console.error('Unexpected Error: ', error);
  } else {
    if (error.response?.status === 401) return logout();
    console.error('Expected Error: ', error);
  }
  return Promise.reject(error.response.data.message);
});

/**
 * ClearVR Cloud Live API
 */
const liveInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

liveInstance.interceptors.request.use(
  async config => {
    config.headers['x-api-key'] = process.env.REACT_APP_LIVE_X_API_KEY;

    const token = await getJwt();
    if (token) config.headers['Authorization'] = token;
    return config;
  },
  error => {
    Promise.reject(error);
    console.error('LIVE INTERCEPTOR REQUEST: ', error.response?.data?.message);
  }
);

liveInstance.interceptors.response.use(null, error => {
  const expectedError = setExpectedError(error);
  const errorMessage = error.response?.data?.message
    ? error.response?.data?.message.m
    : expectedError && error.response.status === 404
    ? error.response.statusText
    : error?.message;

  const OnlySupportedInRunningStateError = error.response?.data?.message.c === 3201;

  const isNetworkError = setNetworkError(errorMessage);

  if (!OnlySupportedInRunningStateError && !isNetworkError) {
    toast.error(errorMessage, {
      toastId: errorMessage,
      autoClose: false,
      hideProgressBar: true,
      className: 'text-break'
    });
  }
  console.error('LIVE INTERCEPTOR RESPONSE: ', errorMessage);
  return Promise.reject(errorMessage);
});

/**
 * ClearVR Cloud VOD API
 */
const vodInstance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

vodInstance.interceptors.request.use(
  async config => {
    config.headers['x-api-key'] = process.env.REACT_APP_VOD_X_API_KEY;

    const token = await getJwt();
    if (token) config.headers['Authorization'] = token;
    return config;
  },
  error => {
    Promise.reject(error);
    console.error('VOD INTERCEPTOR REQUEST: ', error);
  }
);

vodInstance.interceptors.response.use(null, error => {
  const expectedError = setExpectedError(error);
  const errorMessage = !error?.response
    ? error
    : expectedError
    ? error.response.data?.error
    : 'An unexpected error occurrred.';

  const isNetworkError = setNetworkError(errorMessage);

  if (error?.response && !isNetworkError) {
    toast.error(errorMessage, {
      toastId: errorMessage,
      autoClose: false,
      hideProgressBar: true,
      className: 'text-break'
    });
  }

  console.error('VOD INTERCEPTOR RESPONSE: ', error);
  return Promise.reject(errorMessage);
});

export const CancelToken = axios.CancelToken;

const http = {
  get: instance.get,
  put: instance.put,
  post_live: liveInstance.post,
  post_vod: vodInstance.post,
  CancelToken
};

export default http;
