import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { actionTypes } from 'constants/live';

import VodEncoderForm from 'components/VodEncoderForm';
import VodStorageForm from 'components/VodStorageForm';
import EmptyButton from 'components/EmptyButton';
import Badge from 'components/Badge';

const VodFormPage = () => {
  const { action, type, uuid } = useParams();
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const { default_vod_uuid, loading } = useSelector(({ user }) => user);

  const isCreate = action === 'new';
  const isCopy = action === actionTypes.duplicate;
  const title = isCreate ? 'Create' : isCopy ? 'Duplicate' : 'Edit';
  const button = isCreate ? 'create' : isCopy ? 'save_as_copy' : 'save';
  const isDefault = uuid && uuid === default_vod_uuid;
  return (
    <main className='main-container mt-4 mb-5'>
      <div className='col-12 col-xl-10 col-xxl-8'>
        <span className='d-flex justify-content-between align-items-center mb-4'>
          <span className='d-flex justify-content-between align-items-center gap-2'>
            <h1 className='h3 color-high m-0'>{`${title} ${type}`}</h1>
            <p className='h5 m-0'>{isDefault && <Badge.Line isDefault />}</p>
          </span>
          <EmptyButton onClick={() => goBack()}>{t('cancel')}</EmptyButton>
        </span>
        {type === 'encoder' ? (
          <VodEncoderForm button={button} loading={loading} fullscreen />
        ) : (
          <VodStorageForm button={button} loading={loading} fullscreen />
        )}
      </div>
    </main>
  );
};

export default VodFormPage;
