import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { setNewAccessUrl } from 'utils/liveUtils';
import TextField from 'components/TextField';
import CopyButton from 'components/CopyButton';

export const AccessUrlFields = ({ output, eventName }) => {
  const { t } = useTranslation('validation');
  return (
    <>
      {output?.map((data, index) => {
        const { access_url } = { ...data };
        const value = setNewAccessUrl(access_url, eventName);
        return (
          <Fragment key={index}>
            {access_url && (
              <div className='row mb-4'>
                <div className='form-label color-medium'>
                  {t('validation:Access URL')}
                  {output?.length > 1 && <span>{` (Output ${index})`}</span>}
                </div>
                <div className='input-group'>
                  <div className='form-control'>{value}</div>
                  <CopyButton value={value} />
                </div>
              </div>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export const GroupFields = ({ bundles, fieldName }) => {
  const { t } = useTranslation('validation');
  return (
    <div className={`row ${fieldName ? 'mt-3' : 'mt-4'}`}>
      {bundles.map(({ title, fields }) => (
        <div key={title} className='col-auto'>
          <h6 className={`color-high ${fieldName ? '' : 'mb-3'}`}>
            {t(title)}
          </h6>
          <div className='d-flex flex-wrap gap-3'>
            {fields.map(({ name, ...field }) => (
              <TextField
                {...field}
                style={{ width: '5rem' }}
                name={fieldName ? `${fieldName}.${name}` : name}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
