import React from 'react';
import Layout from 'components/Layout';
import Image from 'react-bootstrap/Image';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log('error: ', error);
    console.log('error info: ', info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Layout>
          <main className='h-100 d-flex flex-column justify-content-center align-items-center'>
            <Image
              className='w-25 lazy-load'
              src='https://i.imgur.com/yW2W9SC.png'
              style={{ maxWidth: '20rem' }}
            />
            <h1 className='h4 color-high m-5'>Oops! Something went wrong.</h1>
          </main>
        </Layout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
