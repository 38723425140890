import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { openModal, setModalContent } from 'store/modal/modalSlice';
import {
  getLiveConfigs,
  stopLiveConfigs,
  quickRestartLiveConfig
} from 'store/live/liveThunk';

import { getActionableLiveConfigs, setAlertProps } from 'utils/liveUtils';
import { actionTypes, stateTypes, showDetailsStates } from 'constants/live';
import LiveAccordion from 'components/LiveAccordion';
import ActionDropdown from 'components/ActionDropdown';
import TranscoderCard from 'components/TranscoderCard';
import LiveModal from 'components/LiveModal';
import LiveSlateModal from 'components/LiveSlateModal';
import Alert from 'components/Alert';

const LiveActiveSection = ({
  data,
  selectedActiveIds,
  selectActiveIds,
  onDropdownOpen,
  onCancel,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dialog = useSelector(({ modal }) => modal);
  const { profile_config } = useSelector(({ live }) => live);
  const [activeModalName, setActiveModalName] = useState(null);
  const [resumeOutputStream, setResumeOutputStream] = useState(true);

  const dataCount = data?.length;
  const { actionableCount, actionableStackIds } = getActionableLiveConfigs(data);

  const { restart, update, stop, checkDetails, checkConfig, checkRunningLogs, live2vod } =
    actionTypes;
  const { stopping, reconfiguring, running } = stateTypes;

  const handleHideModal = () => {
    setActiveModalName(null);
    if (!dialog.name) selectActiveIds([]);
    const prevActiveId = dialog.content?.stack_uuid;
    if (prevActiveId) selectActiveIds([prevActiveId]);
  };

  const getEventKeys = ({ type, state, static_config, dynamic_config }) => {
    const inactiveStates = state !== reconfiguring && state !== running;

    const eventKeys = [
      { eventKey: live2vod, label: 'Create VoD manifest', disabled: state !== running },
      { eventKey: restart, label: 'restart_transcoder', disabled: inactiveStates },
      { eventKey: stop, label: 'stop_transcoder', danger: true }
    ];

    const showActiveSlate = static_config?.advanced?.slate_settings?.definitions?.length;
    if (showActiveSlate) {
      eventKeys.unshift({
        eventKey: 'activate_slate',
        label: 'activate_slate',
        disabled: inactiveStates
      });
    }

    const showUpdateSettings =
      type === 'hls' ? Boolean(dynamic_config?.config_parameters?.event_name) : true;
    if (showUpdateSettings) {
      eventKeys.unshift({
        eventKey: update,
        label: 'update_settings',
        disabled: inactiveStates
      });
    }
    return eventKeys;
  };

  const getDetailedContent = config => {
    const { config_uuid, stack_uuid, static_config } = { ...config };
    const content = { config_uuid, stack_uuid };
    const { source, egress } = { ...static_config };
    const { video, multi_video } = { ...source };
    if (video || multi_video) content['multi_video'] = video ? [video] : multi_video;
    if (egress) content['egress'] = egress;
    return content;
  };

  const handleClickCard = (event, props) => {
    selectActiveIds([props?.stack_uuid]);

    if (showDetailsStates.includes(props.state)) {
      dispatch(setModalContent(getDetailedContent(props))); // remember stack_uuid for highlighting card after close modal
      const isClickOnErrorAlert = event.target.className.includes('link');
      dispatch(openModal(isClickOnErrorAlert ? checkRunningLogs : checkDetails));
    } else {
      dispatch(openModal(checkConfig));
      dispatch(setModalContent(props));
    }
  };

  const handleOpenStopAll = () => {
    selectActiveIds(actionableStackIds);
    setActiveModalName('stopAll');
  };

  const handleStop = async selectedActiveIds => {
    const response = await dispatch(stopLiveConfigs(selectedActiveIds));
    if (response.meta.requestStatus === 'fulfilled') {
      dispatch(getLiveConfigs(profile_config));
    }
  };

  const handleRestart = async data => {
    const response = await dispatch(quickRestartLiveConfig(data));
    if (response?.meta.requestStatus === 'fulfilled') {
      dispatch(getLiveConfigs(profile_config));
      toast.success('Restarted successfully');
    }
  };

  const selectUpdate = data => {
    dispatch(openModal(update));
    dispatch(setModalContent(data));
  };

  const handleSelect = (eventKey, data) => {
    selectActiveIds([data.stack_uuid]);

    if (eventKey === live2vod) {
      dispatch(setModalContent(data));
      dispatch(openModal(eventKey));
    }
    if (eventKey === update) selectUpdate(data);
    else setActiveModalName(eventKey);
  };

  useEffect(() => {
    if (!selectedActiveIds.length) return;
    if (dialog.name === checkConfig) {
      const activeData = data.find(({ stack_uuid }) =>
        selectedActiveIds.includes(stack_uuid)
      );
      if (!activeData) return onCancel();
      if (showDetailsStates.includes(activeData?.state)) {
        const content = getDetailedContent(activeData);
        dispatch(setModalContent(content));
        dispatch(openModal(checkDetails));
      } else dispatch(setModalContent(activeData));
    }
  }, [data, selectedActiveIds, dialog.name]); // eslint-disable-line

  return (
    <LiveAccordion
      eventkey='ACTIVE'
      dataCount={dataCount}
      disabled={!dataCount || !actionableCount}
      onClick={handleOpenStopAll}
      showBadge={dataCount > 0}
      {...props}
    >
      <LiveModal
        name='stopAll'
        show={activeModalName === 'stopAll'}
        subtitles={data
          .filter(({ stack_uuid }) => actionableStackIds.includes(stack_uuid))
          .map(({ name }) => name)}
        count={actionableCount}
        onConfirm={() => handleStop(actionableStackIds)}
        onHide={handleHideModal}
        isDanger
      />
      {dataCount ? (
        <div className='cards-section d-grid gap-3'>
          {data.map(props => {
            const { name, stack_uuid, state } = { ...props };
            return (
              <article key={stack_uuid || nanoid()} className='position-relative'>
                <TranscoderCard.Active
                  data={props}
                  selected={selectedActiveIds.includes(stack_uuid)}
                  onClick={event => handleClickCard(event, props)}
                />
                <ActionDropdown
                  className='position-absolute top-0 end-0 m-2'
                  eventKeys={getEventKeys(props)}
                  disabled={state === stopping}
                  onSelect={eventKey => handleSelect(eventKey, props)}
                  onToggle={onDropdownOpen}
                />
                {selectedActiveIds.includes(stack_uuid) && (
                  <>
                    <LiveSlateModal
                      show={activeModalName === 'activate_slate'}
                      data={props}
                      onHide={handleHideModal}
                    />
                    <LiveModal
                      name={restart}
                      show={activeModalName === restart}
                      subtitles={[name]}
                      onHide={handleHideModal}
                      onConfirm={() =>
                        handleRestart({
                          stack_uuid,
                          dynamic_config: {
                            runtime_parameters: {
                              dont_resume_output_stream: !resumeOutputStream
                            }
                          }
                        })
                      }
                    >
                      <ResumeOutputStreamCheckbox
                        checked={resumeOutputStream}
                        setChecked={bool => setResumeOutputStream(bool)}
                      />
                      <Alert className='text-start fade-in' {...setAlertProps(restart)} />
                    </LiveModal>
                    <LiveModal
                      name={stop}
                      show={activeModalName === stop}
                      subtitles={[name]}
                      onConfirm={() => handleStop([stack_uuid])}
                      onHide={handleHideModal}
                      isDanger
                    />
                  </>
                )}
              </article>
            );
          })}
        </div>
      ) : (
        <p className='color-medium'>{t('no_active_transcoder')}</p>
      )}
    </LiveAccordion>
  );
};

const ResumeOutputStreamCheckbox = ({ checked, setChecked }) => {
  useEffect(() => {
    setChecked(true);
    return () => setChecked(true);
  }, []); // eslint-disable-line

  return (
    <div className='form-check mb-3'>
      <input
        type='checkbox'
        className='form-check-input'
        name='resume_output_stream'
        checked={checked}
        onChange={({ target: input }) => setChecked(input.checked)}
      />
      <label
        htmlFor='resume_output_stream'
        className='form-check-label color-medium text-sm user-select-none text-start'
      >
        Resume existing output stream. Deselecting this checkbox means a new output stream
        is created instead (at the same URL)
      </label>
    </div>
  );
};

export default LiveActiveSection;
