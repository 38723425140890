import { toast } from 'react-toastify';
import { createSlice } from '@reduxjs/toolkit';
import {
  authUser,
  setNewUserPassword,
  forgotUserPassword,
  resetUserPassword,
  getCurrentUser,
  getBlueprints,
  getUser,
  updateUser,
  createLiveProfile,
  importLiveProfile,
  updateLiveProfile,
  deleteLiveProfile,
  createVodProfile,
  importVodProfile,
  updateVodProfile,
  deleteVodProfile,
  updateSettings
} from 'store/user/userThunk';

export const initialState = {
  isAuthenticated: false,
  isFirstLogin: false,
  username: '',
  email: '',
  groups: [],
  blueprints: [],
  default_live_uuid: null,
  live_profiles: [],
  default_vod_uuid: null,
  vod_profiles: {
    encoders: [],
    sources: [],
    destinations: []
  },
  settings: {
    theme: 'light'
  },
  loading: false,
  error: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setFirstLogin: (state, { payload }) => {
      state.isFirstLogin = payload.isFirstLogin;
      state.username = payload.name;
      state.email = payload.email;
      state.loading = false;
    },
    setAuthenticated: state => {
      state.isAuthenticated = true;
    },
    clearAuthenticated: state => {
      state.isAuthenticated = false;
    },
    clearError: state => {
      state.error = null;
    },
    setLiveProfiles: (state, { payload }) => {
      state.live_profiles = payload;
    },
    setVodProfiles: (state, { payload }) => {
      state.vod_profiles = payload;
    }
  },
  extraReducers: {
    /**
     * Cognito API: response.payload
     */
    [authUser.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [authUser.fulfilled]: state => {
      // state.isAuthenticated = true;
      state.loading = true;
      state.error = false;
    },
    [authUser.rejected]: (state, { error }) => {
      state.isAuthenticated = false;
      state.loading = false;
      state.error = error.message || 'Error';
    },
    [setNewUserPassword.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [setNewUserPassword.fulfilled]: state => {
      // state.isFirstLogin = false;
      state.isAuthenticated = true;
      state.loading = false;
    },
    [setNewUserPassword.rejected]: (state, { error }) => {
      state.isAuthenticated = false;
      state.loading = false;
      state.error = error.message || 'Error';
    },
    [forgotUserPassword.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [forgotUserPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.username = payload;
      state.error = false;
    },
    [forgotUserPassword.rejected]: (state, { error }) => {
      state.isAuthenticated = false;
      state.loading = false;
      state.error = error.message || 'Error';
    },
    [resetUserPassword.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [resetUserPassword.fulfilled]: state => {
      state.loading = false;
      state.error = false;
    },
    [resetUserPassword.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message || 'Error';
    },
    [getCurrentUser.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [getCurrentUser.fulfilled]: state => {
      state.isAuthenticated = true;
      // state.loading = false;  TODO: to be tested
      state.error = false;
    },
    [getCurrentUser.rejected]: (state, { error }) => {
      state.isAuthenticated = false;
      state.loading = false;
      state.error = error.message || 'Error';
    },
    /**
     * Cockpit backend API: response.data
     */
    [getBlueprints.pending]: state => {
      // state.loading = true;
      state.error = null;
    },
    [getBlueprints.fulfilled]: (state, { payload }) => {
      // state.loading = false;
      state.blueprints = payload;
      state.error = false;
    },
    [getBlueprints.rejected]: (state, { error }) => {
      // state.loading = false;
      state.error = error.message || 'Error';
    },
    [getUser.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.username = payload.username;
      state.email = payload.email;
      state.groups = payload.groups;
      state.default_live_uuid = payload.default_live_uuid;
      state.live_profiles = payload.live_profiles;
      state.default_vod_uuid = payload.default_vod_uuid;
      state.vod_profiles = payload.vod_profiles;
      state.settings = payload.settings;
      state.loading = false;
      state.error = false;
    },
    [getUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message || 'Error';
      // toast.error(error.message);
    },
    [updateUser.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.isFirstLogin = false;
      state.username = payload.username;
      state.email = payload.email;
      state.groups = payload.groups;
      state.default_live_uuid = payload.default_live_uuid;
      state.live_profiles = payload.live_profiles;
      state.default_vod_uuid = payload.default_vod_uuid;
      state.vod_profiles = payload.vod_profiles;
      state.settings = payload.settings;
      state.loading = false;
      state.error = false;
    },
    [updateUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
    },
    /**
     * LIVE PROFILE
     */
    // CREATE
    [createLiveProfile.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [createLiveProfile.fulfilled]: (state, { payload }) => {
      state.live_profiles = payload.live_profiles;
      state.loading = false;
      state.error = false;
      toast.success(`Live profile: Created successfully`);
    },
    [createLiveProfile.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
    },
    // IMPORT
    [importLiveProfile.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [importLiveProfile.fulfilled]: (state, { payload }) => {
      state.isFirstLogin = false;
      state.live_profiles = payload.live_profiles;
      state.loading = false;
      state.error = false;
      toast.success('Live profile: Imported successfully');
    },
    [importLiveProfile.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
    },
    // UPDATE
    [updateLiveProfile.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [updateLiveProfile.fulfilled]: (state, { payload }) => {
      state.live_profiles = payload.live_profiles;
      state.loading = false;
      state.error = false;
      toast.success(`Live profile: Updated successfully`);
    },
    [updateLiveProfile.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
    },
    // DELETE
    [deleteLiveProfile.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [deleteLiveProfile.fulfilled]: (state, { payload }) => {
      state.default_live_uuid = payload.default_live_uuid;
      state.live_profiles = payload.live_profiles;
      state.loading = false;
      state.error = false;
      toast.success(`Live profile: Deleted successfully`);
    },
    [deleteLiveProfile.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
    },
    /**
     * VOD PROFILE
     */
    [createVodProfile.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [createVodProfile.fulfilled]: (state, { payload }) => {
      state.vod_profiles = payload.vod_profiles;
      state.loading = false;
      state.error = false;
      toast.success(`On-demand profile: Created successfully`);
    },
    [createVodProfile.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
      toast.error(error.message, { toastId: error.message, autoClose: false });
    },
    [importVodProfile.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [importVodProfile.fulfilled]: (state, { payload }) => {
      state.isFirstLogin = false;
      state.vod_profiles = payload.vod_profiles;
      state.loading = false;
      state.error = false;
      toast.success(`On-demand profile: imported successfully`);
    },
    [importVodProfile.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
      toast.error(error.message, { toastId: error.message, autoClose: false });
    },
    [updateVodProfile.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [updateVodProfile.fulfilled]: (state, { payload }) => {
      state.vod_profiles = payload.vod_profiles;
      state.loading = false;
      state.error = false;
      toast.success(`On-demand profile: Updated successfully`);
    },
    [updateVodProfile.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
      toast.error(error.message, { toastId: error.message, autoClose: false });
    },
    [deleteVodProfile.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [deleteVodProfile.fulfilled]: (state, { payload }) => {
      state.default_vod_uuid = payload.default_vod_uuid;
      state.vod_profiles = payload.vod_profiles;
      state.loading = false;
      state.error = false;
      toast.success(`On-demand profile: Deleted successfully`);
    },
    [deleteVodProfile.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
    },
    /**
     * SETTINGS
     */
    [updateSettings.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [updateSettings.fulfilled]: (state, { payload }) => {
      state.settings = payload.settings;
      state.loading = false;
      state.error = false;
    },
    [updateSettings.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message || true;
    }
  }
});

export const {
  setFirstLogin,
  setAuthenticated,
  clearAuthenticated,
  clearError,
  setLiveProfiles,
  setVodProfiles
} = userSlice.actions;

export default userSlice.reducer;
