import http from 'services/httpService';

const api = process.env.REACT_APP_COCKPIT_URL;

export function getUserBlueprints() {
  return http.get(`${api}/blueprints`);
}

export function getUserData() {
  return http.get(`${api}/user`);
}

export function updateUserData(data) {
  const parsedData = { ...data };

  // group !== "" => means it is a group profile, do not add it to user profiles
  parsedData['live_profiles'] = data?.live_profiles?.filter(({ group }) => !group);
  parsedData['vod_profiles'] = {
    encoders: data?.vod_profiles?.encoders?.filter(({ group }) => !group),
    sources: data?.vod_profiles?.sources?.filter(({ group }) => !group),
    destinations: data?.vod_profiles?.destinations?.filter(({ group }) => !group)
  };
  return http.put(`${api}/user`, parsedData);
}
