import { createAsyncThunk } from '@reduxjs/toolkit';
import { scheduleVODManifest, listVODManifests } from 'services/liveService';

export const scheduleLive2VOD = createAsyncThunk(
  'live/scheduleLive2VOD',
  async (payload, { getState }) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState().live.profile_config;
    try {
      const response = await scheduleVODManifest(customer_entrypoint, {
        customer_id,
        api_auth_token,
        ...payload
      });
      return response?.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const listLive2VODRecords = createAsyncThunk(
  'live/listLive2VODRecords',
  async (payload, { getState }) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState().live.profile_config;
    try {
      const response = await listVODManifests(customer_entrypoint, {
        customer_id,
        api_auth_token,
        ...payload
      });
      return response?.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const listActiveLive2VODRecords = createAsyncThunk(
  'live/listActiveLive2VODRecords',
  async (payload, { getState }) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState().live.profile_config;
    const { record_uuids, ...rest } = { ...payload };
    try {
      const response = await listVODManifests(customer_entrypoint, {
        customer_id,
        api_auth_token,
        ...rest
      });
      return response?.data.filter(({ record_uuid }) =>
        record_uuids.includes(record_uuid)
      );
    } catch (error) {
      throw Error(error);
    }
  }
);
