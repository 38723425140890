import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import i18n from 'config/i18next';
import Form from 'react-bootstrap/Form';
import languages from 'constants/languages';

const LanguageSelect = ({ className = '' }) => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const [, languageInPath] = pathname.split('/');

  const handleChange = ({ target: input }) => {
    const redirectUrl = pathname.replace(i18n.language, input.value);
    i18n.changeLanguage(input.value, () => push(redirectUrl));
    // if (user.isAuthenticated) {
    //   const newData = setNewLng(selectedLng);
    //   dispatch(updateUser(newData));
    // }
  };

  useEffect(() => {
    if (languages.find(({ code }) => code === languageInPath)) {
      i18n.changeLanguage(languageInPath);
    }
  }, [languageInPath]);

  return (
    <Form.Select
      className={`w-auto color-high pe-pointer border-line ${className}`}
      value={i18n.language}
      onChange={handleChange}
    >
      {languages.map(({ code, name }) => (
        <option key={code} value={code}>
          {name}
        </option>
      ))}
    </Form.Select>
  );
};

export default LanguageSelect;
