import { useTranslation } from 'react-i18next';
import { capitalizeString } from 'utils/utils';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import AddIcon from 'remixicon-react/AddFillIcon';

const VodSubtitle = ({
  type,
  text = '',
  className = '',
  onClick,
  showTooltip,
  size,
  disabledButton
}) => {
  const { t } = useTranslation();
  const hint = `Add ${type ? 'location details' : text}`;
  return (
    <Card.Title
      as={size === 'sm' ? 'h6' : 'h5'}
      className={`d-flex justify-content-between align-items-center color-high underline mb-3 ${className} ${
        size === 'sm' ? 'text-md' : ''
      }`}
      style={{ height: '2.5rem' }}
    >
      {`${type ? capitalizeString(type) : ''} ${text}`}
      {onClick && (
        <OverlayTrigger
          placement='bottom-end'
          show={showTooltip}
          overlay={<Tooltip className='fade-in'>{hint}</Tooltip>}
        >
          <Button
            variant='link'
            className='d-flex justify-content-between align-items-center text-decoration-none gap-1 hover-primary shadow-none'
            onClick={onClick}
            disabled={disabledButton}
          >
            <span>
              <AddIcon />
            </span>
            {t('add')}
          </Button>
        </OverlayTrigger>
      )}
    </Card.Title>
  );
};

export default VodSubtitle;
