import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    name: '',
    content: null,
    loading: false
  },
  reducers: {
    setModalLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setModalContent: (state, { payload }) => {
      state.content = payload;
    },
    addModalContent: (state, { payload }) => {
      state.content.unshift(payload);
    },
    deleteModalContent: (state, { payload }) => {
      state.content.splice(payload, 1);
    },
    clearModalContent: state => {
      state.content = null;
    },
    openModal: (state, { payload }) => {
      state.name = payload;
    },
    closeModal: state => {
      state.name = null;
    }
  }
});

export const {
  setModalLoading,
  setModalContent,
  addModalContent,
  deleteModalContent,
  clearModalContent,
  openModal,
  closeModal
} = modalSlice.actions;

export default modalSlice.reducer;
