import http from 'services/httpService';

// Get status of a list of encodes
export const getEncodes = (customer_entrypoint, payload, source) => {
  const token = source && { cancelToken: source.token };
  return http.post_vod(`${customer_entrypoint}/encoding/list`, payload, token);
};

// Get status of specific encode
export const getEncode = (customer_entrypoint, payload) => {
  return http.post_vod(`${customer_entrypoint}/encoding/query`, payload);
};

// Schedule new encode
export const scheduleEncode = (customer_entrypoint, payload) => {
  return http.post_vod(`${customer_entrypoint}/encoding/schedule`, payload);
};

// Cancel scheduled encode
export const cancelEncode = (customer_entrypoint, payload) => {
  return http.post_vod(`${customer_entrypoint}/encoding/cancel`, payload);
};
