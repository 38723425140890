import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';

const TimeRangeDropdown = ({
  timeRanges,
  selectedRange,
  onSelectedRange,
  className = ''
}) => {
  const { t } = useTranslation('glossary');
  const theme = useSelector(({ user }) => user.settings?.theme);
  return (
    <Dropdown className={`profile-dropdown z-5 ${className}`}>
      <Dropdown.Toggle className='color-high p-0' size='sm'>
        {t(selectedRange)}
      </Dropdown.Toggle>
      <Dropdown.Menu variant={theme} className='p-2 shadow-sm'>
        {timeRanges.map((range, index) => (
          <Fragment key={range}>
            {!!index && <Dropdown.Divider />}
            <Dropdown.Item
              className='px-2 py-1 rounded'
              disabled={selectedRange === range}
              onClick={() => onSelectedRange(range)}
            >
              <small>{t(range)}</small>
            </Dropdown.Item>
          </Fragment>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TimeRangeDropdown;
