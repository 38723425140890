import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { renderIcon } from 'utils/liveUtils';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import EmptyButton from 'components/EmptyButton';

const CancelModal = ({ name, data, show, onHide, onConfirm }) => {
  const { theme } = useSelector(({ user }) => user.settings);
  const title = name || 'discard_changes';
  const text = 'modal_cancel_text';
  return (
    <Modal
      className={theme}
      contentClassName='modal-background dp-02 px-5 py-4'
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header className='flex-column text-center'>
        <span className='nav-icon-size text-danger mb-2'>
          {renderIcon('warning')}
        </span>
        <h1 className='h3 color-high'>
          <Trans i18nKey={title}>{title}</Trans>
        </h1>
        {name && (
          <h2 className='h5 color-primary lh-base pb-3 m-0'>
            {data?.name || '-'}
          </h2>
        )}
      </Modal.Header>
      {!name && (
        <Modal.Body
          className='color-medium text-center py-4'
          style={{ whiteSpace: 'pre-line' }}
        >
          <Trans i18nKey={text}>{text}</Trans>
        </Modal.Body>
      )}
      <Modal.Footer className='d-grid gap-3 d-flex justify-content-center'>
        <EmptyButton onClick={onHide}>
          <Trans i18nKey='cancel'>cancel</Trans>
        </EmptyButton>
        <Button
          variant='danger text-white'
          onClick={() => onConfirm(data?.encode_uuid)}
        >
          <Trans i18nKey='confirm'>confirm</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelModal;
