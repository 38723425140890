import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { renderIcon } from 'utils/liveUtils';
import Dropdown from 'react-bootstrap/Dropdown';
import ActionIcon from 'remixicon-react/MoreFillIcon';

const ActionDropdown = ({ eventKeys, disabled, className = '', ...props }) => {
  const { t } = useTranslation();
  const theme = useSelector(({ user }) => user.settings);
  const disabledStyles = disabled ? 'pe-none' : 'pe-pointer';
  const textStyles = 'lh-base user-select-none my-auto mx-3';
  return (
    <Dropdown className={`action-dropdown ${className}`} {...props}>
      <Dropdown.Toggle className={disabledStyles} aria-label='action menu'>
        <ActionIcon className={disabled ? 'color-disabled' : 'color-medium'} />
      </Dropdown.Toggle>

      <Dropdown.Menu variant={theme} className='shadow-sm' renderOnMount>
        {eventKeys.map(({ disabled, danger, eventKey, label }) => {
          const icon = renderIcon(eventKey);
          return (
            <Fragment key={eventKey}>
              {danger && <Dropdown.Divider />}
              <Dropdown.Item
                eventKey={eventKey.toLowerCase()}
                className={
                  danger
                    ? 'text-danger danger'
                    : disabled
                    ? 'color-disabled pe-none'
                    : 'color-medium'
                }
                disabled={disabled}
              >
                {icon && <div className='icon-size'>{icon}</div>}
                <h6 className={textStyles}>{t(label || eventKey)}</h6>
              </Dropdown.Item>
            </Fragment>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionDropdown;
