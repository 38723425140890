import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLiveConfigs,
  startLiveConfigs,
  updateLiveConfig
} from 'store/live/liveThunk';

import { actionTypes } from 'constants/live';
import { formatValues, parseRateControl } from 'utils/utils';
import { checkRestart } from 'utils/liveUtils';

import Modal from 'react-bootstrap/Modal';
import LiveConfigForm from 'components/LiveConfigForm';
import LiveConfigsForm from 'components/LiveConfigsForm';
import Live2VodForm from 'components/Live2VodForm';

const LiveFormDialog = ({ eventKey, show, removeInactiveId, onCancel }) => {
  const dispatch = useDispatch();
  const profileConfig = useSelector(({ live }) => live.profile_config);
  const { name: modalName, content: modalContent } = useSelector(
    ({ modal }) => modal
  );

  if (!show) return null;

  const { start, startAll, update, live2vod } = actionTypes;
  const count = modalContent?.length;
  let requiredRestart;

  const renderTitle = eventKey => {
    if (count > 1) return 'modal_launch_title_plural';
    switch (eventKey) {
      default:
      case start:
      case startAll:
        return 'modal_launch_title';
      case update:
        return 'modal_update_title';
      case live2vod:
        return 'Create VoD manifest';
    }
  };

  const renderContent = eventKey => {
    switch (eventKey) {
      default:
      case start:
        return <LiveConfigForm onSubmit={handleSubmit} onCancel={onCancel} />;
      case startAll:
        const configs = { removeInactiveId, onSubmit: handleSubmit, onCancel };
        return <LiveConfigsForm {...configs} />;
      case live2vod:
        return <Live2VodForm onCancel={onCancel} />;
    }
  };

  const handleConfirm = values => {
    switch (modalName) {
      case startAll:
      case start:
        const formattedValues = formatValues(values);
        return dispatch(startLiveConfigs(formattedValues));
      case update:
        const { stack_uuid, dynamic_config } = values;
        const bundles = dynamic_config.runtime_parameters?.bundles?.map(
          bundle => parseRateControl(bundle)
        );
        const payload = { stack_uuid };
        requiredRestart = checkRestart(modalContent, values);
        if (requiredRestart) {
          const restart_payload = { ...dynamic_config };
          restart_payload.runtime_parameters.bundles = bundles;
          payload.dynamic_config = restart_payload;
        } else {
          const update_payload = { ...dynamic_config.runtime_parameters };
          update_payload.bundles = bundles;
          payload.runtime_parameters = update_payload;
        }
        return dispatch(updateLiveConfig(payload));
      default:
        return null;
    }
  };

  const handleSubmit = async values => {
    const response = await handleConfirm(values);
    if (response?.meta.requestStatus === 'fulfilled') {
      if (modalName === update) {
        const text = requiredRestart
          ? 'Restarted successfully'
          : 'Updated successfully';
        toast.success(text);
      }
      dispatch(getLiveConfigs(profileConfig));
    }
  };

  return (
    <section className='form-dialog modal-background rounded dp-02 m-0 fade-in d-flex flex-column position-absolute w-100 p-3'>
      <Modal.Header className='flex-column text-center'>
        <Modal.Title>
          <h2 className='h4 color-high'>
            <Trans i18nKey={renderTitle(eventKey)} count={count}>
              {renderTitle(eventKey)}
            </Trans>
          </h2>
          {eventKey !== startAll && (
            <h3 className='h6 color-primary m-0'>{modalContent?.name}</h3>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='form-scrollable d-flex h-100'>
        {renderContent(eventKey)}
      </Modal.Body>
    </section>
  );
};

export default LiveFormDialog;
