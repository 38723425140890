import * as Yup from 'yup';
import { nanoid } from '@reduxjs/toolkit';
import { FisheyeCameraLens_TM } from 'constants/live';
import { RATE_CONTROL } from 'constants/vod';

const schema = Yup.object().shape({
  name: Yup.string().min(2, 'too_short').max(25, 'too_long').required('required_field'),
  customer_entrypoint: Yup.string().url('must_valid_URL'),
  set_id: Yup.string(),
  customer_id: Yup.number()
    .typeError('must_be_number')
    .integer()
    .positive()
    .required('required_field')
});

const initialValues = {
  name: '',
  set_id: '',
  customer_entrypoint: '',
  customer_id: '' // integer
};

const initialKeys = ['uuid', ...Object.keys(initialValues)];

export const profileLabels = {
  name: 'profile_name',
  set_id: 'Set ID',
  customer_entrypoint: 'customer_entrypoint',
  customer_id: 'Customer ID'
};

const profile = [
  {
    uuid: nanoid(),
    className: 'row',
    cols: [
      {
        uuid: nanoid(),
        className: 'col-8',
        name: 'name',
        label: profileLabels['name'],
        autoFocus: true,
        validation: true
      },
      {
        uuid: nanoid(),
        className: 'col',
        name: 'set_id',
        label: profileLabels['set_id']
      }
    ]
  },
  {
    uuid: nanoid(),
    className: 'row',
    cols: [
      {
        uuid: nanoid(),
        className: 'col',
        name: 'customer_entrypoint',
        label: profileLabels['customer_entrypoint'],
        placeholder: 'hint_provided_only',
        validation: true
      }
    ]
  }
];

const start = [
  ...profile,
  {
    uuid: nanoid(),
    className: 'row mt-4',
    cols: [
      {
        uuid: nanoid(),
        type: 'number',
        className: 'col-4',
        name: 'customer_id',
        label: profileLabels['customer_id'],
        validation: true,
        placeholder: 'hint_provided_only'
      }
    ]
  }
];

const settings = [
  ...profile,
  {
    uuid: nanoid(),
    className: 'row',
    cols: [
      {
        uuid: nanoid(),
        type: 'number',
        className: 'col',
        name: 'customer_id',
        label: profileLabels['customer_id'],
        validation: true,
        placeholder: 'hint_provided_only'
      }
    ]
  }
];

export const ProfileFormFields = {
  schema,
  initialValues,
  initialKeys,
  start,
  settings
};

export const event_name = Yup.string()
  .required('required_field')
  .max(200, 'too_long')
  .matches(/^[a-zA-Z0-9_\-.~/]+$/, 'must_not_contain_spaces');

const source_url = Yup.string();
// .url('must_valid_URL');

// const enable_recording = Yup.boolean();

// const bitrate_in_kbps = Yup.number()
//   .required('Must have a number between 0 and 99999')
//   .typeError('must_be_number')
//   .integer()
//   .lessThan(100000, 'Must be less than 100000')
//   .min(0, 'must_greater_equal_0')
//   .nullable();

export const custom_bitrate_schema = Yup.number()
  .when(['constant', 'preset'], {
    is: (constant, preset) => constant !== 'full-custom' && preset === 'custom',
    then: Yup.number()
      .typeError('must_be_number')
      .integer()
      .min(512, 'Must starts from 512')
      .required('required_field')
  })
  .when(['constant', 'preset'], {
    is: (constant, preset) => constant === 'full-custom' && preset === 'custom',
    then: Yup.number()
      .typeError('must_be_number')
      .integer()
      .test(
        'custom_bitrate',
        'Must starts from 512 or 0 (auto)',
        value => value === 0 || value >= 512
      )
      .required('required_field')
  });

const runtime_parameters = Yup.object().shape({
  gaussian_sharpness_filter: Yup.number()
    .required('must_between_0_199')
    .typeError('must_be_number')
    .integer()
    .lessThan(200, 'must_less_200')
    .min(0, 'must_greater_equal_0'),
  fish_eye_params: Yup.object()
    .shape({
      camera_lens_enum: Yup.string()
        .oneOf(FisheyeCameraLens_TM, 'must_choose_lens')
        .required('must_choose_lens')
    })
    .default(null)
    .nullable(),
  bundles: Yup.array().of(
    Yup.object().shape({
      constant: Yup.string().oneOf(RATE_CONTROL['constant']),
      preset: Yup.string().oneOf(RATE_CONTROL['preset']),
      custom_bitrate: custom_bitrate_schema
      // groups: Yup.array().of(
      //   Yup.object().shape({
      //     representations: Yup.array().of(
      //       Yup.object().shape({ bitrate_in_kbps })
      //     )
      //   })
      // )
    })
  )
});

const liveConfig = Yup.object().shape({
  dynamic_config: Yup.object().shape({
    config_parameters: Yup.object().shape({ source: source_url }),
    runtime_parameters
  })
});

const liveConfig_Event = Yup.object().shape({
  dynamic_config: Yup.object().shape({
    config_parameters: Yup.object().shape({
      event_name,
      source: source_url
    }),
    runtime_parameters
  })
});

const liveConfigs = Yup.object().shape({
  liveConfigs: Yup.array().of(liveConfig_Event)
});

const touchedField = {
  dynamic_config: {
    config_parameters: {
      event_name: true
    },
    runtime_parameters: {
      fish_eye_params: {
        camera_lens_enum: true
      }
    }
  }
};

export const defaultRateControl = {
  constant: 'bitrate',
  preset: 'default',
  custom_bitrate: 512
};

const liveInitialValues = {
  dynamic_config: {
    config_parameters: {
      event_name: '',
      source: '',
      enable_recording: true
    },
    runtime_parameters: {
      gaussian_sharpness_filter: 0,
      fish_eye_params: {
        camera_lens_enum: ''
      },
      bundles: [
        {
          ...defaultRateControl,
          groups: [
            {
              representations: [{ bitrate_in_kbps: 0 }, { bitrate_in_kbps: 0 }]
            },
            {
              representations: [
                { bitrate_in_kbps: 0 },
                { bitrate_in_kbps: 0 },
                { bitrate_in_kbps: 0 }
              ]
            }
          ]
        }
      ]
    }
  }
};

const CONFIG_PARAMS = 'dynamic_config.config_parameters';

const event = {
  name: `${CONFIG_PARAMS}.event_name`,
  label: 'event_name',
  validation: true
};

const source = {
  name: `${CONFIG_PARAMS}.source`,
  label: 'Source URL',
  // validation: true,
  copy: true
};

const RUNTIME_PARAMS = 'dynamic_config.runtime_parameters';

const sharpness = {
  type: 'number',
  name: `${RUNTIME_PARAMS}.gaussian_sharpness_filter`,
  label: 'Sharpness',
  helpMessage: 'hint_sharpness',
  validation: true
};

const fisheye = {
  name: `${RUNTIME_PARAMS}.fish_eye_params.camera_lens_enum`,
  label: 'Fisheye Camera-lens',
  validation: true
};

const group1 = [0, 1].map(index => ({
  key: nanoid(),
  type: 'number',
  name: `${RUNTIME_PARAMS}.bundles[0].groups[0].representations[${index}].bitrate_in_kbps`,
  label: 'Bitrate',
  unit: 'kbps'
  // validation: true
}));

const group2 = [0, 1, 2].map(index => ({
  key: nanoid(),
  type: 'number',
  name: `${RUNTIME_PARAMS}.bundles[0].groups[1].representations[${index}].bitrate_in_kbps`,
  label: 'Bitrate',
  unit: 'kbps'
  // validation: true
}));

const bundles = [
  { title: 'group 1', fields: group1 },
  { title: 'group 2', fields: group2 }
];

export const LiveFormFields = {
  schema: { liveConfig, liveConfig_Event, liveConfigs },
  initialValues: liveInitialValues,
  touchedField,
  fields: {
    event,
    source,
    sharpness,
    fisheye,
    bundles
  }
};
