import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { clearModalContent, closeModal } from 'store/modal/modalSlice';
import { clearError } from 'store/user/userSlice';

import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import LoginForm from 'components/LoginForm';
import WithLoader from 'components/WithLoader';
import Alert from 'components/Alert';

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useSelector(({ modal }) => modal);
  const user = useSelector(({ user }) => user);
  const theme = user.settings?.theme;
  const error = user.error;

  const src = `${process.env.REACT_APP_COCKPIT_CDN}/logos/logo-${
    theme || 'light'
  }.svg`;

  const handleCancel = () => {
    dispatch(closeModal());
    dispatch(clearModalContent());
    dispatch(clearError());
  };

  return (
    <main className='row flex-fill align-items-center offset-2 g-0'>
      <span>
        <Image
          className='mt-5 lazy-load'
          src={src}
          width={129}
          height={53}
          alt='Tiledmedia logo'
        />
      </span>
      <LoginForm eventKey='login' className='col-9 col-xxl-6 col-xxxl-5' />
      <div className='d-flex align-items-center'>
        <p className='color-medium m-0 me-2'>{t('dont_have_an_account')}</p>
        <a
          className='link h6 color-primary my-auto'
          href='mailto:hi@tiledmedia.com'
        >
          {t('contact_tiledmedia')}
        </a>
      </div>

      {['forgot', 'reset'].map((name, index) => (
        <Modal
          key={name}
          show={modal.name === name}
          className={theme}
          contentClassName='p-4'
          onHide={handleCancel}
          centered
        >
          <Modal.Header>
            <Modal.Title className='w-100 h3 text-center text-capitalize color-high'>
              {!index ? t('forgot_password') : t('reset_password')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && <Alert className='mb-4' type='danger' text={error} />}
            <LoginForm eventKey={name} onCancel={handleCancel} />
          </Modal.Body>
        </Modal>
      ))}
    </main>
  );
};

export default WithLoader(Login);
