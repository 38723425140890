import { createAsyncThunk } from '@reduxjs/toolkit';
import { JOB_STATES, HISTORY_STATES } from 'constants/vod';
import { toast } from 'react-toastify';
import { getEncodes, getEncode, scheduleEncode, cancelEncode } from 'services/vodService';

// Get status of a list of ongoing encodes
export const getJobList = createAsyncThunk(
  'vod/getJobList',
  async ({ customer_id, api_auth_token, customer_entrypoint }) => {
    try {
      const response = await getEncodes(customer_entrypoint, {
        customer_id,
        api_auth_token,
        encode_states: JOB_STATES
      });
      return response?.data;
    } catch (error) {
      throw Error(error);
    }
  }
);
export const getRunningJob = createAsyncThunk(
  'vod/getRunningJob',
  async (source, { getState }) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState()['vod']['profile'];
    try {
      const response = await getEncodes(
        customer_entrypoint,
        { customer_id, api_auth_token, encode_states: JOB_STATES },
        source
      );
      return response?.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

// Get status of a list of finished encodes
export const getHistoryList = createAsyncThunk(
  'vod/getHistoryList',
  async (
    {
      encode_states = HISTORY_STATES, // array
      previous_page_token,
      next_page_token,
      page_size = 10,
      source
    },
    { getState }
  ) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState()['vod']['profile'];
    try {
      const response = await getEncodes(
        customer_entrypoint,
        {
          customer_id,
          api_auth_token,
          encode_states,
          previous_page_token,
          next_page_token,
          page_size
        },
        source
      );
      return response?.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

// Get status of specific encode
export const getVodEncode = createAsyncThunk(
  'vod/getVodEncode',
  async (encode_uuid, { getState }) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState()['vod']['profile'];
    try {
      const response = await getEncode(customer_entrypoint, {
        customer_id,
        api_auth_token,
        encode_uuid
      });
      return response.data.encode_config;
    } catch (error) {
      throw Error(error);
    }
  }
);

// Get original status of specific encode
export const getVodOriginalEncode = createAsyncThunk(
  'vod/getVodOriginalEncode',
  async (encode_uuid, { getState }) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState()['vod']['profile'];
    try {
      const response = await getEncode(customer_entrypoint, {
        customer_id,
        api_auth_token,
        encode_uuid
      });
      return response.data.original_encode_config || response.data.encode_config;
    } catch (error) {
      throw Error(error);
    }
  }
);

// Schedule new encode
export const scheduleVodEncode = createAsyncThunk(
  'vod/scheduleVodEncode',
  async (encodeConfig, { getState }) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState()['vod']['profile'];

    toast.success(`Start encoding ${encodeConfig?.name || 'Video'}...`, {
      autoClose: 2500
    });

    try {
      const response = await scheduleEncode(customer_entrypoint, {
        customer_id,
        api_auth_token,
        ...encodeConfig
      });
      return response.data;
    } catch (error) {
      throw Error(error);
    }
  }
);

// Cancel scheduled encode
export const cancelVodEncode = createAsyncThunk(
  'vod/cancelVodEncode',
  async (encode_uuid, { getState }) => {
    const { customer_id, api_auth_token, customer_entrypoint } =
      getState()['vod']['profile'];
    try {
      await cancelEncode(customer_entrypoint, {
        customer_id,
        api_auth_token,
        encode_uuid
      });
      return encode_uuid;
    } catch (error) {
      throw Error(error);
    }
  }
);
