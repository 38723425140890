import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { actionTypes } from 'constants/live';
import { renderIcon } from 'utils/liveUtils';
import Modal from 'react-bootstrap/Modal';

const LiveGetStartedDialog = ({ className = '' }) => {
  const live_profiles = useSelector(({ user }) => user.live_profiles);
  const content = live_profiles?.length
    ? {
        type: actionTypes.getStarted,
        title: 'modal_start_title',
        text: 'modal_start_text'
      }
    : {
        type: actionTypes.addProfile,
        title: 'modal_add_profile_title',
        text: 'modal_add_profile_text'
      };
  const { type, title, text } = content;
  return (
    <section
      className={`card-background dp-01 rounded fade-in px-4 py-5 ${className}`}
    >
      <Modal.Header className='flex-column text-center'>
        <span className='nav-icon-size color-high'>{renderIcon(type)}</span>
        <Modal.Title className='mt-2'>
          <h2 className='h3 color-high'>
            <Trans i18nKey={title}>{title}</Trans>
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='color-medium w-50 text-center mx-auto'>
        <Trans i18nKey={text}>{text}</Trans>
      </Modal.Body>
    </section>
  );
};

export default LiveGetStartedDialog;
