import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { capitalizeString } from 'utils/utils';

const LiveDetailsBlock = ({ title, subtitle, data, isOverview, className = '' }) => {
  const theme = useSelector(({ user }) => user.settings?.theme);
  const borderColor = theme === 'dark' ? 'border-light' : 'border-dark';
  const titleStyles = `d-flex flex-wrap justify-content-between gap-2 border-bottom ${borderColor} pb-2 h6`;
  const bodyStyles = isOverview ? 'col' : 'd-lg-flex justify-content-lg-between';
  return (
    <Card.Body className={`bg-transparent ${className}`}>
      <Card.Title className={titleStyles}>
        <span className='color-high m-0'>{capitalizeString(title)}</span>
        <small className='color-disabled float-end'>{subtitle}</small>
      </Card.Title>
      <Card.Text className='row'>
        {data?.map(item => {
          const isObject = typeof item === 'object';
          const key = isObject && Object.keys(item);
          const value = isObject && item[key];
          return (
            <span key={key} className={bodyStyles}>
              {isObject && <small className='color-medium'>{key}</small>}
              <small className='color-high d-block ms-auto'>
                {(isObject ? value : item) || 0}
              </small>
            </span>
          );
        })}
      </Card.Text>
    </Card.Body>
  );
};

export default LiveDetailsBlock;
