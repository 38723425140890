import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { stateTypes } from 'constants/live';

const classes = 'badge badge-padding rounded-pill';

const Fill = ({ type, className = '', children }) => {
  const { theme } = useSelector(({ user }) => user.settings);

  const getStyles = type => {
    const { preparing, deploying, deployed, reconfiguring, stopping, running, error } = stateTypes;

    switch (type) {
      default:
      case 'pending':
      case 'starting':
      case preparing:
      case deploying:
      case deployed:
      case reconfiguring:
      case stopping:
        return 'staging-badge';
      case running:
      case 'ingesting':
      case 'encoding':
      case 'finishing':
      case 'finished':
        return 'hover-background color-primary';
      case error:
      case 'failed':
        return 'error-background text-danger';
      case 'slate':
      case 'cancelled':
        return 'warning-background';
      case 'source':
      case 'sources':
        const themeClasses = theme === 'dark' ? 'bg-light text-black' : 'bg-dark';
        return `bg-opacity-60 ${themeClasses}`
      case 'destination':
      case 'destinations':
        return 'primary-background color-primary-high';
    }
  };
  return (
    <span className={`${classes} ${getStyles(type)} base-font ${className}`}>
      {children}
    </span>
  );
};

const Round = ({ children, className = '' }) => (
  <span
    className={`badge-size rounded-circle color-primary thumbnail-background d-flex justify-content-center align-items-center pop-up base-font ${className}`}
  >
    {children}
  </span>
);

const Line = ({ isDefault, children, className = '' }) => {
  const { t } = useTranslation('glossary');
  return (
    <span className={`${classes} color-primary border-focus fade-in ${className}`}>
      {isDefault ? t('Default') : children}
    </span>
  );
};

const Badge = {
  Fill,
  Round,
  Line
};

export default Badge;
