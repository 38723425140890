import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import Badge from 'components/Badge';
import ToggleButton from 'components/ToggleButton';
import EmptyButton from 'components/EmptyButton';

const LiveAccordion = ({
  defaultActiveKey = null,
  eventkey,
  disabled,
  onClick,
  children,
  className = '',
  ...props
}) => {
  const { t } = useTranslation();
  const { showBadge, dataCount } = { ...props };
  return (
    <Accordion
      className={className}
      defaultActiveKey={defaultActiveKey || eventkey}
    >
      <div className='d-flex align-item-center'>
        <ToggleButton eventkey={eventkey} {...props}>
          <h5 className='title-height color-high m-0'>
            {t(eventkey.toLowerCase())}
          </h5>
          {showBadge && (
            <Badge.Round>
              <h6 className='m-0'>{dataCount}</h6>
            </Badge.Round>
          )}
        </ToggleButton>
        {onClick && (
          <EmptyButton
            className='ms-auto shadow-none'
            onClick={onClick}
            disabled={disabled}
            reversedStyles
          >
            {eventkey === 'ACTIVE' ? t('stop_all') : t('start_all')}
          </EmptyButton>
        )}
      </div>
      {children && (
        <Accordion.Collapse eventKey={eventkey}>
          <section className='d-grid gap-3 py-3'>{children}</section>
        </Accordion.Collapse>
      )}
    </Accordion>
  );
};

export default LiveAccordion;
