import { useTranslation } from 'react-i18next';
import { getTrafficLightColorFromStatus } from 'utils/liveUtils';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const StateIndicator = ({ data, stage }) => {
  const { t } = useTranslation('glossary');
  const { status = '??', message = '??' } = { ...data[stage] };
  const bgColor = getTrafficLightColorFromStatus(status);
  return (
    <OverlayTrigger overlay={<Tooltip>{`${status} - ${message}`}</Tooltip>}>
      <div className='d-flex align-items-center'>
        <span className={`p-1 rounded-circle bg-${bgColor}`} />
        <p className='m-0 ms-2 color-high fw-normal text-capitalize text-sm'>
          {t(stage)}
        </p>
      </div>
    </OverlayTrigger>
  );
};

export default StateIndicator;
