import { createSlice } from '@reduxjs/toolkit';
import {
  scheduleLive2VOD,
  listLive2VODRecords,
  listActiveLive2VODRecords
} from 'store/live2vod/live2vodThunk';

export const initialState = {
  showRecordList: false,
  active: {
    loading: false,
    stack_uuid: null,
    record_uuids: [],
    records: []
  },
  history: {
    loading: false,
    records: []
  },
  error: null
};

const live2vodSlice = createSlice({
  name: 'live2vod',
  initialState,
  reducers: {
    setHideRecordList: state => {
      state.showRecordList = false;
      state.active.stack_uuid = null;
      state.active.record_uuids = [];
      state.active.records = [];
    },
    clearHistoryRecords: state => {
      state.history.records = [];
    }
  },
  extraReducers: {
    [scheduleLive2VOD.pending]: state => {
      state.active.loading = true;
      state.error = null;
    },
    [scheduleLive2VOD.fulfilled]: (state, { payload }) => {
      state.active.loading = false;
      state.active.stack_uuid = payload.stack_uuid;
      state.active.record_uuids.unshift(payload.record_uuid);
      state.active.records.unshift(payload);
      state.showRecordList = true;
      state.error = null;
    },
    [scheduleLive2VOD.rejected]: (state, { error }) => {
      state.active.loading = false;
      state.error = error.message;
    },
    [listLive2VODRecords.pending]: state => {
      state.history.loading = true;
    },
    [listLive2VODRecords.fulfilled]: (state, { payload }) => {
      state.history.loading = false;
      state.history.records = payload;
      state.error = null;
    },
    [listLive2VODRecords.rejected]: (state, { error }) => {
      state.history.loading = false;
      state.error = error.message;
    },
    [listActiveLive2VODRecords.fulfilled]: (state, { payload }) => {
      const completeRecords = payload || [];
      state.active.records = filterDuplicateRecords([
        ...state.active.records,
        ...completeRecords
      ]);
      state.error = null;
    },
    [listActiveLive2VODRecords.rejected]: (state, { error }) => {
      state.error = error.message;
    }
  }
});

const filterDuplicateRecords = records => {
  const uniqueNames = [];
  return records
    .reverse()
    .filter(({ record_uuid }) => {
      const isDuplicate = uniqueNames.includes(record_uuid);
      if (!isDuplicate) {
        uniqueNames.push(record_uuid);
        return true;
      }
      return false;
    })
    .reverse();
};

export const { setHideRecordList, clearHistoryRecords } = live2vodSlice.actions;

export default live2vodSlice.reducer;
