import { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSnapshotHD } from 'store/live/liveThunk';
import { formatLocaleTime } from 'utils/utils';
import Loader from 'components/Loader';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import FileDamageIcon from 'remixicon-react/FileDamageFillIcon';
import FullIcon from 'remixicon-react/FullscreenLineIcon';
import ExitFullIcon from 'remixicon-react/FullscreenExitLineIcon';
import DownloadIcon from 'remixicon-react/Download2LineIcon';

const SnapshotPage = ({ match: { params }, history }) => {
  const { t } = useTranslation(['common', 'glossary']);

  const [showFull, setShowFull] = useState(false);
  const [imgHeight, setImgHeight] = useState(0);
  const navBar = useRef(null);
  const height = `calc(100vh - ${imgHeight}px)`;

  const dispatch = useDispatch();
  const { live_profiles } = useSelector(({ user }) => user);
  const {
    snapshot: { loading, img_hd: snapshot },
    error
  } = useSelector(({ live }) => live);
  const profile = live_profiles?.find(({ uuid }) => uuid === params?.uuid);
  const input_projection = JSON.parse(localStorage.getItem('input_projection'));

  useEffect(() => {
    if (!profile) return;
    const { customer_id, api_auth_token, customer_entrypoint } = { ...profile };
    const payload = {
      customer_id,
      api_auth_token,
      customer_entrypoint,
      ...params
    };
    dispatch(fetchSnapshotHD(payload));
  }, [dispatch, params, profile]); // eslint-disable-line

  useEffect(() => {
    if (snapshot?.img) setImgHeight(navBar?.current?.clientHeight);
  }, [snapshot?.img]);

  if (!params?.uuid) history.push({ pathname: '/not-found', state: 'no_snapshot' });
  return (
    <main className='bg-dark vh-100'>
      {loading ? (
        <Loader fullscreen />
      ) : error || !snapshot?.img ? (
        <div className='h-100 d-flex flex-column justify-content-center align-items-center gap-4 text-center text-white opacity-75'>
          <FileDamageIcon size={48} />
          <h1 className='h5 fw-normal m-0'>{error || 'Unable to fetch the snapshot'}</h1>
        </div>
      ) : (
        <>
          <Button
            className='position-fixed top-0 end-0 m-3 border-0 shadow-none bg-transparent'
            variant='dark'
            aria-label='toggle nav'
            onClick={() => setShowFull(preState => !preState)}
          >
            {showFull ? <ExitFullIcon /> : <FullIcon />}
          </Button>

          {showFull ? (
            <div className='overflow-auto' style={{ height }}>
              <img
                width='auto'
                src={`data:image/gif;base64,${snapshot?.img}`}
                alt={`Snapshot of Input ${params?.index}`}
              />
            </div>
          ) : (
            <div
              style={{
                backgroundImage: `url(data:image/gif;base64,${snapshot?.img})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height
              }}
            />
          )}

          <Navbar ref={navBar} fixed='bottom'>
            <Container>
              <Navbar.Brand className='color-high'>
                {`${t('common:input')} ${params?.index}`}
              </Navbar.Brand>
              <Nav className='me-auto color-medium text-sm gap-2'>
                {snapshot?.time && (
                  <Navbar.Text>
                    {`Captured at ${formatLocaleTime(snapshot.time)} (${t(
                      'glossary:local_time'
                    )})`}
                  </Navbar.Text>
                )}
                {input_projection && (
                  <Navbar.Text>
                    {`with ${input_projection?.projection_type}, ${input_projection?.fish_eye_params?.camera_lens_enum}`}
                  </Navbar.Text>
                )}
              </Nav>

              <a
                className='ms-auto'
                download={`input${params.index}_snapshot`}
                href={`data:image/jpeg;base64,${snapshot?.img}`}
              >
                <DownloadIcon
                  className={`color-high m-2 ${
                    snapshot?.img ? '' : 'pe-none opacity-38'
                  }`}
                />
              </a>
            </Container>
          </Navbar>
        </>
      )}
    </main>
  );
};

export default withRouter(SnapshotPage);
