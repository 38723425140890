import { useState } from 'react';
import { useSelector } from 'react-redux';
import { URL, URL_CN } from 'constants/live';
import { getOverviewStatusHeight } from 'utils/liveUtils';

import Loader from 'components/Loader';

const Panel = ({ containerclass, ...props }) => (
  <>
    {containerclass ? (
      <div className={containerclass}>
        <Graph {...props} />
      </div>
    ) : (
      <Graph {...props} />
    )}
  </>
);

const Graph = ({
  token,
  panelId,
  outputIndex = null,
  stack_uuid,
  title,
  startAt,
  width = '100%',
  height = 196,
  className = '',
  ...props
}) => {
  const [loading, setLoading] = useState(true);

  const theme = useSelector(({ user }) => user.settings?.theme);
  const { profile_config, snapshot } = useSelector(({ live }) => live);
  const { content } = useSelector(({ modal }) => modal);

  const {
    index: { current: index }
  } = { ...snapshot };

  const getBaseURL = () => {
    const { customer_entrypoint } = { ...profile_config };
    const isChina = customer_entrypoint?.includes('shenwavideo');
    return isChina ? URL_CN : URL;
  };

  const varInput = index !== null ? `&var-Input=${('0' + index).slice(-2)}` : '';
  const varOutput = outputIndex !== null ? `&var-Output=${outputIndex}` : '';

  const handleLoading = () => {
    setLoading(false);
  };

  const grafanaTitleStyle = {
    fontWeight: 'bold',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '13px',
    color: theme === 'dark' ? 'rgb(204, 204, 220)' : 'rgb(36, 41, 46)'
  };

  // props for Live status panels
  // content for panels in Live Details Dialog
  const parsedHeight = getOverviewStatusHeight(panelId, props, content) || height;

  return (
    <>
      {loading ? (
        <div
          className={`${props.maintext ? 'h-100' : className} d-flex align-items-center`}
          style={{ height: parsedHeight }}
        >
          <Loader zIndex={1} />
        </div>
      ) : null}
      {token && (
        <>
          {props.maintext && !loading && (
            <div className='lh-lg text-center'>
              <small style={grafanaTitleStyle}>{props.maintext}</small>
            </div>
          )}
          <iframe
            className={`p-2 ${loading ? 'd-none' : ''} ${className}`}
            title={title}
            width={width}
            height={parsedHeight}
            onLoad={handleLoading}
            src={`${getBaseURL()}?orgId=1&auth_token=${token}&refresh=5s&var-StackUUID=${stack_uuid}&from=${startAt}&to=now&theme=${theme}&panelId=${panelId}${varInput}${varOutput}`}
            {...props}
          />
        </>
      )}
    </>
  );
};

export default Panel;
