import BaseTable from 'react-bootstrap/Table';
import TableHeader from 'components/TableHeader';
import TableBody from 'components/TableBody';

const Table = ({
  columns,
  sortColumn,
  onSort,
  data,
  noDataMsg,
  hover = true,
  ...props
}) => (
  <BaseTable className='m-0 overflow-visible' borderless hover={hover} {...props}>
    <TableHeader data={data} columns={columns} sortColumn={sortColumn} onSort={onSort} />
    <TableBody columns={columns} data={data} noDataMsg={noDataMsg} />
  </BaseTable>
);

export default Table;
