import dayjs from 'dayjs';

const TableBody = ({ data, columns, noDataMsg }) => {
  const createKey = (item, column) => item?.uuid + (column.path || column.key);

  const renderCell = (item, column, index) => {
    if (column.input) return column.input(index, item);
    if (column.content) return column.content(item);
    if (column.path === 'Source name' && column.label === 'Name') {
      return item[column.path] || `Video ${index + 1}`;
    }
    if (column.path === 'last_updated') {
      return dayjs(item[column.path]).format('DD-MM-YYYY, HH:mm:ss');
    }
    return Array.isArray(column.path)
      ? item?.[column.path?.[0]][column.path?.[1]] || '-'
      : item?.[column.path] || '-';
  };

  const getTdStyles = key => {
    switch (key) {
      case 'checkbox':
        return 'text-center';
      case 'action':
        return 'pe-2 py-0';
      case 'btn-sm':
        return 'p-0';
      default:
        return '';
    }
  };

  return (
    <tbody className='color-medium'>
      {data?.length ? (
        data?.map((item, index) => (
          <tr
            key={item?.uuid || item?.encode_uuid || index}
            className={index + 1 === data?.length ? '' : 'underline'}
          >
            {columns.map(column => (
              <td
                key={createKey(item, column)}
                className={`align-middle ${getTdStyles(column.key)} ${column.tdClasses}`}
              >
                {renderCell(item, column, index)}
              </td>
            ))}
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={columns.length} className='p-4 pe-none'>
            {noDataMsg || 'No available data'}
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
