import Loader from 'components/Loader';

const WithLoader =
  WrappedComponent =>
  ({ loading, fullscreen, ...otherProps }) =>
    loading ? (
      <Loader fullscreen={fullscreen} />
    ) : (
      <WrappedComponent {...otherProps} />
    );

export default WithLoader;
