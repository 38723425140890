import i18n from 'config/i18next';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { actionTypes } from 'constants/live';
import BaseModal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import EmptyButton from 'components/EmptyButton';
import WarningIcon from 'remixicon-react/ErrorWarningLineIcon';

const Modal = ({
  name,
  data,
  subtitles = null,
  onChange = null,
  onConfirm,
  onCancel
}) => {
  const { t } = useTranslation(['common', 'glossary']);
  const {
    loading,
    default_live_uuid,
    settings: { theme }
  } = useSelector(({ user }) => user);
  const { name: activeModalName, content: activeModalContent } = useSelector(
    ({ modal }) => modal
  );
  const { import: importProfile, delete: deleteProfile } = actionTypes;

  const renderTitle = name => {
    switch (name) {
      case importProfile:
        return t('modal_import_config_title');
      case deleteProfile:
        const count = subtitles?.length;
        const isDeleteDefault =
          default_live_uuid && data?.[0]?.uuid === default_live_uuid;
        const d = count > 1 ? count : `${isDeleteDefault ? t('glossary:default') : ''}`;
        const plural = count > 1 ? 's' : '';
        const isZh = i18n.language === 'zh';
        const title = isZh
          ? `${t(name)}${d}${t('profile')}`
          : `${t(name)} ${d} ${t('profile')}${plural}`;
        return title;
      default:
        return null;
    }
  };

  const renderSubtitles = name => {
    switch (name) {
      case importProfile:
        return (
          <BaseModal.Body className='mx-4'>
            <input
              className='form-control'
              type='file'
              id='formFile'
              onChange={event => onChange(event)}
            />
          </BaseModal.Body>
        );
      case deleteProfile:
        const subtitle = subtitles?.map((text, index) => (index ? ', ' : '') + text);
        return (
          <BaseModal.Body className='text-center w-75 mx-auto p-0'>
            <p className='color-medium'>{subtitle}</p>
          </BaseModal.Body>
        );
      default:
        return null;
    }
  };

  if (loading) return null;
  return (
    <BaseModal
      className={theme}
      show={name === activeModalName}
      onHide={onCancel}
      aria-labelledby={`${name}-modal`}
      centered
    >
      <BaseModal.Header className={`${name === deleteProfile ? 'mb-0' : ''} m-4`}>
        <BaseModal.Title
          id={`${name}-modal`}
          className='text-center color-high w-100 fs-3'
        >
          {name === deleteProfile && (
            <span className='text-danger d-block mb-3'>
              <WarningIcon size={48} />
            </span>
          )}
          {renderTitle(name)}
        </BaseModal.Title>
      </BaseModal.Header>
      {renderSubtitles(name)}
      <BaseModal.Footer className='m-4 mx-auto'>
        <EmptyButton onClick={onCancel}>{t('cancel')}</EmptyButton>
        <Button
          variant={name === deleteProfile ? 'danger' : 'primary'}
          className={'ms-4' + (name === deleteProfile ? ' text-white' : '')}
          onClick={() => onConfirm(data || activeModalContent)}
        >
          {t(name)}
        </Button>
      </BaseModal.Footer>
    </BaseModal>
  );
};

export default Modal;
